<template>
    <div class="flex-col justify-between" :style="{width: fullWidth+'px',height: fullHeight+'px','margin-top':'-10px'}">
        <div style="width: 100%;height: 1250px">
            <Header :initLoad="loadHeader" @toLoadHeader="toLoadHeader"/>
            <div :style="{width: fullWidth+'px',height:'auto'}">
                <!--      内容区域-->
                <router-view class="flex-col" style="height: 100%;width: 100%" @toLoadHeader="toLoadHeader"/>
            </div>
        </div>
        <div style="width:100%;height:200px;">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
    name: "Layout",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            fullWidth: document.documentElement.clientWidth - 30,
            fullHeight: document.documentElement.clientHeight,
            loadHeader: 0,
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.fullWidth = document.documentElement.clientWidth - 30
            this.fullHeight = document.documentElement.clientHeight
        },
        toLoadHeader() {
            this.loadHeader = this.loadHeader + 1;
        },
    }
}
</script>

<style scoped>
</style>
