<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 74px;" class="flex-row justify-between">
                <div style="width: 75%;" class="flex-row">
                    <div class="search-input search-first" style="width: 47%">
                        <Tools_SelectChapter
                            :initPlateOptions="this.plateOptions"
                            @getChapter="(value)=>this.chapterId=value"
                            :isEdit="true"
                        />
                    </div>
                    <el-select v-model="state" placeholder="状态"
                               class="search-input search-first" clearable>
                        <el-option
                            v-for="item in stateList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </div>
                <div style="width: 25%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    全局功能-->
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      v-if="tableData"
                      :data="tableData"
                      border
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="id" label="任务编号"/>
                <el-table-column prop="chapterName" label="名称">
                    <template #default="scope">
                        {{ scope.row && scope.row.chapterVo ? scope.row.chapterVo.chapterName : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="plateId" label="栏目">
                    <template #default="scope">
                        {{ scope.row && scope.row.chapterVo ? this.pinjiePlateName(scope.row.chapterVo.plateId) : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="任务状态">
                    <template #default="scope">
                        {{ scope.row ? this.stateMap.get(scope.row.state) : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="startTime" label="任务启动时间">
                    <template #default="scope">
                        {{ scope.row ? $TdDate.df(scope.row.startTime, 'YYYY-MM-DD HH:mm:ss') : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="finshTime" label="任务完成时间">
                    <template #default="scope">
                        {{ scope.row ? $TdDate.df(scope.row.finshTime, 'YYYY-MM-DD HH:mm:ss') : '' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="240px" label="操作">
                    <template #default="scope">
                        <el-button @click="query(scope.row.id)" link size="small" type="primary">查看进度
                        </el-button>
                        <el-button v-if="scope.row.chapterId" @click="againSyn(scope.row.chapterId)" link size="small"
                                   type="primary">再次采集
                        </el-button>
                        <el-popconfirm @confirm="cancelSyn(scope.row.id)" title="确定取消任务吗？"
                                       v-if="scope.row&&scope.row.id">
                            <template #reference>
                                <el-button :disabled="scope.row.state!==1"
                                           link size="small" type="danger">
                                    取消
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="查看进度" v-model="synDialogVisible" width="70%">
                <el-form :model="detailForm">
                    <div class="flex-row" style="margin-left: 24px;width: 90%">
                        <div style="width: 49%" class="flex-col">
                            <el-form-item label="栏目:">
                                {{ detailForm.chapterVo ? this.pinjiePlateName(detailForm.chapterVo.plateId) : '' }}
                            </el-form-item>
                            <el-form-item label="章节:">
                                {{ detailForm.chapterVo ? detailForm.chapterVo.chapterName : '' }}
                            </el-form-item>
                            <el-form-item label="合并结果:">
                                {{ detailForm.synCommitResultDto ? detailForm.synCommitResultDto.msg : '暂无结果' }}
                            </el-form-item>
                        </div>
                        <div style="width: 49%" class="flex-col">
                            <el-form-item label="状态:">
                                {{ this.stateMap.get(detailForm.state) }}
                            </el-form-item>
                            <el-form-item label="发起时间:">
                                {{ $TdDate.df(detailForm.startTime, 'YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                            <el-form-item label="完成时间:">
                                {{ $TdDate.df(detailForm.finshTime, 'YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <el-divider/>
                <el-button
                    :loading="synLoading"
                    :disabled="!(detailForm.state===1&&(this.synCommitInsertList.length>0||this.synCommitDeleteList.length>0))"
                    type="primary" style="margin-top: 10px;margin-left: 24px;"
                    @click="batchCommitSyn(detailForm.id)">发起合并
                </el-button>
                <div style="height: 620px;margin-left: 24px;" class="flex-row">
                    <div class="flex-col" style="width: 48%;">
                        <p>数据对比结果，如下题目需要新增，请勾选：</p>
                        <el-table v-if="detailForm.synResultDto&&detailForm.synResultDto.inserts"
                                  :data="detailForm.synResultDto.inserts" height="600"
                                  border
                                  ref="insertTable"
                                  @selection-change="insertSelectionChange">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column type="index" label="序" width="50"/>
                            <el-table-column prop="synId" label="源ID" width="60"/>
                            <el-table-column prop="title" label="题目">
                                <template #default="scope">
                                    {{
                                        '【' + this.questionTypeMap.get(scope.row.questionType) + '】' + scope.row.title + ' '
                                        + (scope.row.optionA + '.' + scope.row.optionAContent + ' ')
                                        + (scope.row.optionB + '.' + scope.row.optionBContent + ' ')
                                        + (scope.row.optionC && scope.row.optionCContent ? scope.row.optionC + '.' + scope.row.optionCContent + ' ' : '')
                                        + (scope.row.optionD && scope.row.optionDContent ? scope.row.optionD + '.' + scope.row.optionDContent : '')
                                    }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex-col" style="width: 48%;">
                        <p>数据对比结果，如下题目需要删除，请勾选：</p>
                        <el-table v-if="detailForm.synResultDto&&detailForm.synResultDto.deletes"
                                  :data="detailForm.synResultDto.deletes" height="600"
                                  border
                                  ref="deleteTable"
                                  @selection-change="deleteSelectionChange">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column type="index" label="序" width="50"/>
                            <el-table-column prop="synId" label="源ID" width="60"/>
                            <el-table-column prop="title" label="题目">
                                <template #default="scope">
                                    {{
                                        '【' + this.questionTypeMap.get(scope.row.questionType) + '】' + scope.row.title + ' '
                                        + (scope.row.optionA + '.' + scope.row.optionAContent + ' ')
                                        + (scope.row.optionB + '.' + scope.row.optionBContent + ' ')
                                        + (scope.row.optionC && scope.row.optionCContent ? scope.row.optionC + '.' + scope.row.optionCContent + ' ' : '')
                                        + (scope.row.optionD && scope.row.optionDContent ? scope.row.optionD + '.' + scope.row.optionDContent : '')
                                    }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="synDialogVisible = false">关   闭</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: 'SynJobMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            chapterId: '',
            state: '',
            stateList: [//-1-已取消，0-数据采集中，1-采集完成，2-数据同步中，3-同步完成
                {
                    value: -1,
                    label: '已取消',
                },
                {
                    value: 0,
                    label: '数据采集中',
                },
                {
                    value: 1,
                    label: '采集完成',
                },
                {
                    value: 2,
                    label: '数据合并中',
                },
                {
                    value: 3,
                    label: '合并完成',
                }
            ],
            stateMap: new Map(),
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            plateOptions: [],
            plateMap: new Map(),
            loading: true,

            questionTypeList: [//0-判断题,1-单选题,2-多选题
                {
                    value: 0,
                    label: '判断题',
                },
                {
                    value: 1,
                    label: '单选题',
                },
                {
                    value: 2,
                    label: '多选题',
                }
            ],
            questionTypeMap: new Map(),
            synDialogVisible: false,
            detailForm: {},
            synCommitInsertList: [],
            synCommitDeleteList: [],
            synLoading: false,
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        this.plateTree();
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 138; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        load() {
            this.loading = true;
            postData("/api/mgt/synJob/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                chapterId: this.chapterId,
                state: this.state
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        plateTree() {
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.plateOptions = res.data;
                    res.data.map(item => {
                        this.plateMap.set(item.id, item);
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                this.plateMap.set(child.id, child);
                            });
                        }
                    });
                }
            });
            this.questionTypeList.map(type => {
                this.questionTypeMap.set(type.value, type.label);
            });
            this.stateList.map(type => {
                this.stateMap.set(type.value, type.label);
            });
        },
        pinjiePlateName(plateId) {
            var child = this.plateMap.get(plateId);
            if (!child) return "";
            if (child.parentId === 0) return child.plateName;
            var parentName = this.pinjiePlateName(child.parentId);
            return parentName + '>>' + child.plateName;
        },
        againSyn(chapterId) {
            postData("/api/mgt/synJob/start", {
                chapterId: chapterId
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        cancelSyn(id) {
            postData("/api/mgt/synJob/cancel", {
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        query(id) {
            this.synDialogVisible = true;
            this.synCommitInsertList = [];
            this.synCommitDeleteList = [];
            postData("/api/mgt/synJob/detail", {
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.detailForm = res.data;
                    if (this.detailForm && this.detailForm.synCommitResultDto) {
                        if (this.detailForm.synCommitResultDto.inserts
                            && this.detailForm.synCommitResultDto.inserts.length > 0) {
                            var insertMap = new Map();
                            this.detailForm.synResultDto.inserts.map(item => {
                                insertMap.set(item.synId, item);
                            });
                            this.detailForm.synCommitResultDto.inserts.map(synId => {
                                var item = insertMap.get(synId);
                                if (item) this.synCommitInsertList.push(item);
                            });
                            this.insertSelection(this.synCommitInsertList);
                        }
                        if (this.detailForm.synCommitResultDto.deletes
                            && this.detailForm.synCommitResultDto.deletes.length > 0) {
                            var deleteMap = new Map();
                            this.detailForm.synResultDto.deletes.map(item => {
                                deleteMap.set(item.id, item);
                            });
                            this.detailForm.synCommitResultDto.deletes.map(id => {
                                var item = deleteMap.get(id);
                                if (item) this.synCommitDeleteList.push(item);
                            });
                            this.deleteSelection(this.synCommitDeleteList);
                        }
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            });
        },
        insertSelection(rows) {
            this.$nextTick(() => {
                if (rows && rows.length > 0) {
                    rows.forEach(row => {
                        this.$refs.insertTable.toggleRowSelection(this.detailForm.synResultDto.inserts.find(item => {
                            return row.synId == item.synId
                        }))
                    });
                }
            })
        },
        deleteSelection(rows) {
            this.$nextTick(() => {
                if (rows && rows.length > 0) {
                    rows.forEach(row => {
                        this.$refs.deleteTable.toggleRowSelection(this.detailForm.synResultDto.deletes.find(item => {
                            return row.synId == item.synId
                        }))
                    });
                }
            })
        },
        batchCommitSyn(id) {
            this.synLoading = true;
            var insertData = "";
            var deleteData = "";
            if (this.synCommitInsertList && this.synCommitInsertList.length > 0) {
                insertData = JSON.stringify(this.synCommitInsertList);
            }
            if (this.synCommitDeleteList && this.synCommitDeleteList.length > 0) {
                deleteData = this.synCommitDeleteList.map(item => item.id).join(",");
            }
            if (insertData === "" && deleteData === "") {
                this.$message({
                    type: "error",
                    message: "无新增和删除数据，无法提交！"
                })
                return;
            }
            postData("/api/mgt/synJob/synCommit", {
                insertData: insertData,
                deleteData: deleteData,
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: "合并任务已建立，稍后请在此查看进度！"
                    })
                    this.synLoading = false;
                    this.synDialogVisible = false;//关闭弹窗
                    this.load();
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        insertSelectionChange(val) {
            this.synCommitInsertList = val;
        },
        deleteSelectionChange(val) {
            this.synCommitDeleteList = val;
        }
    }
}
</script>
<style scoped>
::v-deep(.el-dialog__body) {
    padding: 20px 20px;
}

::v-deep(.el-form-item--small) {
    height: 30px;
}

::v-deep(.el-form-item__label) {
    height: 30px !important;
}
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
