<template>
    <div class="flex-col">
        <div class="flex-col search">
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      ref="table"
                      height="724"
                      v-if="tableData"
                      :data="tableData"
                      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}" row-key="id">
                <el-table-column label="ID" prop="id" type=""/>
                <el-table-column :show-overflow-tooltip="true" label="菜单标题" width="160px" prop="menuName"/>
                <el-table-column prop="menuIcon" label="图标" align="center">
                    <template #default="scope">
                        <img v-if="scope.row" class="menu_icon" :src="getImgUrl(scope.row.menuIcon)"/>
                    </template>
                </el-table-column>
                <el-table-column prop="menuSort" align="center" label="排序">
                    <template #default="scope">
                        {{ scope.row ? scope.row.menuSort : '' }}
                    </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true" prop="menuTag" label="菜单标识"/>
                <el-table-column prop="menuType" label="菜单类型">
                    <template #default="scope">
                        <span v-if="scope.row&&scope.row.menuType===1">菜单</span>
                        <span v-else-if="scope.row&&scope.row.menuType===2">按钮</span>
                        <span v-else>功能</span>
                    </template>
                </el-table-column>
                <el-table-column prop="menuStatus" label="生效">
                    <template #default="scope">
                        <el-button v-if="scope.row&&scope.row.menuStatus===1" link size="small"
                                   @click="changeStatus(scope.row.id,0)">是
                        </el-button>
                        <el-button v-else link size="small" @click="changeStatus(scope.row.id,1)">否
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="200px" label="操作">
                    <template #default="scope">
                        <el-button @click="edit(scope.row)" link size="small" type="primary">编辑</el-button>
                        <el-popconfirm
                            title="确定删除吗,如果存在下级节点则一并删除，此操作不能撤销！"
                            @confirm="handleDelete(scope.row.id,scope.row.parentId)">
                            <template #reference>
                                <el-button type="danger" link size="small">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!--弹窗-->
            <el-dialog title="提示" v-model="dialogVisible" width="30%">
                <el-form ref="formMenu" :model="form" label-width="22%" :rules="rules">
                    <el-form-item label="菜单类型" prop="menuType">
                        <el-radio-group v-model="form.menuType" size="small">
                            <el-radio-button label="1">菜单</el-radio-button>
                            <el-radio-button label="2">按钮</el-radio-button>
                            <el-radio-button label="3">功能</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-show="form.menuType.toString()==='1'" label="菜单图标" prop="menuIcon">
                        <el-select v-model="form.menuIcon" placeholder="请选择" clearable>
                            <el-option v-for="item in iconList" :key="item.menu" :label="item.name" :value="item.menu">
                                <img class="menu_icon" :src="getImgUrl(item.menu)"/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="菜单名称" prop="menuName">
                        <el-input v-model="form.menuName" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="菜单标识" prop="menuTag">
                        <el-input v-model="form.menuTag" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="菜单排序" prop="menuSort">
                        <el-input v-model="form.menuSort" clearable/>
                    </el-form-item>
                    <el-form-item label="上级类目" prop="parentId">
                        <Tools_Tree :options="this.typeOptions"
                                    :props="defaultProps"
                                    :multiple="false"
                                    :value="form.parentMenus"
                                    :clearable="true"
                                    @getValue="(value)=>this.form.parentId = value.id"/>
                    </el-form-item>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";
import Config from '@/config';
import {validDecimal82, validName} from "@/utils/validate";

export default {
    name: 'Menu',
    components: {},
    props: ['params'],
    data() {
        return {
            tableData: [],
            iconList: Config.icon_list,
            form: {menuType: 1, parentId: 0},
            dialogVisible: false,
            typeOptions: [],
            defaultProps: {
                children: 'children',
                label: 'menuName'
            },
            rules: {
                menuName: [
                    {required: true, message: '请输入菜单名称', trigger: 'blur'},
                    {validator: validName, trigger: 'blur'},
                ],
                menuTag: [
                    {required: true, message: '请输入菜单标识', trigger: 'blur'},
                ],
                menuSort: [
                    {validator: validDecimal82, trigger: 'blur'},
                ]
            },
            loading: true,
            menusMap: new Map().set(0, "根菜单"),
        }
    },
    created() {
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        load() {
            postData("/api/mgt/menu/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data;
                    this.loadMenuMap(res.data)
                    this.loading = false;
                    this.typeOptions = [];
                    this.typeOptions.push({id: 0, menuName: '根菜单'});
                    res.data.map(item => {
                        var data = {};
                        Object.assign(data, item);
                        data.children = null;//暂时只支持到二级菜单，所以将二级菜单置为不可选
                        this.typeOptions.push(data);
                    });
                }
            });
        },
        loadMenuMap(menus) {
            menus.map(menu => {
                this.menusMap.set(menu.id, menu.menuName);
                if (menu.children) {
                    this.loadMenuMap(menu.children);
                }
            });
        },
        edit(row) {
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
            this.form.parentMenus = {
                id: this.form.parentId,
                menuName: this.menusMap.get(this.form.parentId)
            };
        },
        add() {
            this.form = {menuType: 1, parentId: 0, menuName: null, menuTag: null};
            this.dialogVisible = true;
            this.form.parentMenus = {
                id: 0,
                menuName: this.menusMap.get(0)
            };
        },
        changeStatus(id, status) {
            postData("/api/mgt/menu/status", {id: id, status: status}).then(res => {
                if (res.code === "000000") {
                    this.load();//重新加载
                }
            });
        },
        handleDelete(id) {
            postData("/api/mgt/menu/delete", {
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        save() {
            this.form.parentId = this.form.parentId || 0;
            this.$refs['formMenu'].validate((valid) => {
                if (valid) {
                    if (this.form.id && this.form.parentId === this.form.id) {
                        this.$message({
                            type: "error",
                            message: "上级目录不可与当前目录一样！"
                        })
                        return;
                    }
                    postData("/api/mgt/menu/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                        this.load();//刷新表格数据
                        this.dialogVisible = false;//关闭弹窗
                    });
                }
            });
        },
        getImgUrl(icon) {
            if (icon === '' || icon === null || icon === undefined) {
                icon = "system.png";
            }
            return require("@/assets/icon2/" + icon);
        }
    }
}
</script>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
