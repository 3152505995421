<template>
    <div style="margin-bottom: 12px">
        <el-divider/>
        <el-pagination
            class="justify-end"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 10, 20,100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>

export default {
    name: "Tools_Page",
    emits: ["getSize", "getPage"],
    props: {
        initCurrentPage4: {
            type: Number,
            default: 1
        },
        initPageSize: {
            type: Number,
            default: 10
        },
        initTotal: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            currentPage4: 1,
            pageSize: 10,
            total: 0,
        }
    },
    watch: {
        initCurrentPage4: {
            handler(val, oldval) {
                this.currentPage4 = this.initCurrentPage4;   // 初始值
            },
        },
        initPageSize: {
            handler(val, oldval) {
                this.pageSize = this.initPageSize;   // 初始值
            },
        },
        initTotal: {
            handler(val, oldval) {
                this.total = this.initTotal;   // 初始值
            },
        },
    },
    mounted() {
        this.currentPage4 = this.initCurrentPage4;   // 初始值
        this.pageSize = this.initPageSize;   // 初始值
        this.total = this.initTotal;   // 初始值
    },
    methods: {
        handleSizeChange(pageSize) {//改变当前页面个数
            this.pageSize = pageSize;
            this.$emit('getSize', pageSize);
        },
        handleCurrentChange(pageNum) {//改变当前页码
            this.currentPage4 = pageNum;
            this.$emit('getPage', pageNum);
        },
    }
}
</script>

<style scoped>
/**分页栏样式**/
::v-deep(.el-pagination__sizes) {
    width: 7%;
    height: 34px;
}
</style>
