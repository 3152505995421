<template>
    <div style="width: 100%;" class="flex-row justify-center align-center" @contextmenu.prevent="disableRightClick">
        <div class="index" style="width: 100%;z-index: -1;margin-top: -68px">
            <el-carousel height="540px" :interval="5000">
                <el-carousel-item v-for="item in images" :key="item.id">
                    <div style="width: 100%;height: 100%" class="flex-col">
                        <img :src="item.idView" style="width: 100%;height: 100%"/>
                        <span v-show="item.id===0" style="margin-top: -375px;margin-left: 12.5%;" class="flex-col">
                            <p class="banner-text">帮助更多的人成功创业</p>
                            <p class="banner-text" style="margin-top: -14px;margin-left: 8%">成为中国标杆咨询科技公司</p>
                        </span>
                        <span v-show="item.id===1" style="margin-top: -375px;margin-left: 12.5%;width: 50%"
                              class="flex-col">
                            <p class="banner-text">加入然校长,成功又成长</p>
                            <p class="banner-text-1" style="margin-top: -14px;">诚信做人,认真做事</p>
                        </span>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
    <div class="flex-row justify-center"
         style="height: 160px;width: 100%;background: linear-gradient(to bottom, #bcebfa, #b7b3b3);">
        <div class="flex-row about-home justify-center" style="margin-top: -77px;width: 75%;height: 100%">
            <div style="width: 23%;height: 100%" class="flex-row justify-center">
                <span class="about-home-text2">关于我们</span>
            </div>
            <div class="flex-col" style="width: 77%;height: 100%;">
                <span class="about-home-text" style="margin-top: 2vw;margin-right: 1vw">然校长交规是驾考理论网络服务平台之一。以丰富的培训资源背景为强大的依托，以“一切为了求知者”为经营理念，致力于为广大的求知者提供全面、有效、便捷的服务系统，力图为
    求知者排除求知路上的点滴困难，明确求知方向。</span>
                <el-button @click="$router.push('/about')" class="about-home-button"><p
                    class="about-home-button-text align-center">了解更多</p>
                </el-button>
            </div>
        </div>
    </div>
    <div class="server-project flex-row justify-center"
         style="margin-top: -40px;background: linear-gradient(to bottom, #cccccc, #769ed9);">
        <div class="flex-col" style="width: 75%">
            <span style="height: 20%;width: 100%" class="flex-col justify-center align-center">
                <p class="server-project-text-1">服务项目</p>
            </span>
            <div style="height: 80%;width: 100%">
                <div class="flex-row">
                    <ul class="InproUl clearfix InUlsty">
                        <li>
                            <div @click="toPlate(1)" style="width: 17vw;height: 32vh"
                                 class="align-center justify-center text-b">
                                <img src="../assets/img2/pic_sub1exercise.png" style="width: 100%;height: 100%"/>
                                <div class="InUlstyCon">
                                    <p class="InUlstyCon_h">科目一练习</p>
                                    <p class="InUlstyCon_p">进入练习</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div @click="toPlate(2)" style="width: 17vw;height: 32vh"
                                 class="align-center justify-center text-b">
                                <img src="../assets/img2/pic_sub4exercise.png" style="width: 100%;height: 100%"/>
                                <div class="InUlstyCon">
                                    <p class="InUlstyCon_h">科目四练习</p>
                                    <p class="InUlstyCon_p">进入练习</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div @click="toExam(1,1)" style="width: 17vw;height: 32vh"
                                 class="align-center justify-center text-b">
                                <img src="../assets/img2/pic_sub1simulation.png" style="width: 100%;height: 100%"/>
                                <div class="InUlstyCon">
                                    <h3 class="InUlstyCon_h">科一模拟考</h3>
                                    <p class="InUlstyCon_p">进入模考</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div @click="toExam(2,4)" style="width: 17vw;height: 32vh"
                                 class="align-center justify-center text-b">
                                <img src="../assets/img2/pic_sub4simulation.png" style="width: 100%;height: 100%"/>
                                <div class="InUlstyCon">
                                    <h3 class="InUlstyCon_h">科四模拟考</h3>
                                    <p class="InUlstyCon_p">进入模考</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div style="height: 150px"/>
</template>

<script>

export default {
    name: "Home",
    data() {
        return {
            images: [
                {id: 0, idView: require("../assets/img2/pic_banner1.png")},
                {id: 1, idView: require("../assets/img2/pic_banner2.png")},
            ],
        }
    },
    created() {
    },
    methods: {
        toPlate(plateId) {
            this.$router.push("/plate/" + plateId);
        },
        toExam(plateId, temId) {
            this.$router.push("/exam/" + plateId + "/" + temId);
        },
        disableRightClick(e) {
            e.preventDefault();
        }
    }
}
</script>

<style scoped>
.banner-text {
    height: 36px;
    font-size: 36px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #082F80;
    line-height: 36px;
}

.banner-text-1 {
    height: 24px;
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #082F80;
    line-height: 24px;
}

.about-home {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(219, 235, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
    box-shadow: 0px 2px 4px 0px #ECEFF4;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(32px);
}

.about-home-text2 {
    height: 7.5vh;
    font-size: 3.5vh;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: 350;
    color: #111112;
    line-height: 3.5vh;
    margin-top: 6vh;
}

.about-home-text {
    width: 55vw;
    height: 4vh;
    font-size: 1.9vh;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 350;
    color: #111112;
    line-height: 2vh;
}

.about-home-button {
    width: 5vw;
    height: 3vh;
    background: linear-gradient(270deg, #2DB6FA 0%, #4682FA 100%);
    border-radius: 2px;
    margin-top: 1vh;
}

.about-home-button-text {
    width: 56px;
    height: 14px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FEFFFF;
    line-height: 21px;
}

.server-project {
    width: 100%;
    height: 538px;
    background: #FFFFFF;
}

.server-project-text-1 {
    width: 10vw;
    height: 1.5vh;
    font-size: 3vh;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #111112;
    line-height: 3vh;
}

.text-b {
    width: 342px;
    height: 342px;
    background: linear-gradient(180deg, #F7FAFF 0%, #E5EBF9 100%);
    box-shadow: 0px 4px 8px 0px rgba(205, 215, 232, 0.2);
    border-radius: 12px;
}

.InproUl {
    margin: 40px auto 0;
    width: 100%
}

.InproUl li {
    overflow: hidden;
    float: left;
    width: 24%;
    height: auto;
    position: relative;
    margin-right: 1.3%
}

.InproUl li:last-child {
    float: right;
    margin-right: 0
}

.InproUl li img {
    display: block;
    width: 100%;
    height: auto
}

.InUlsty li .InUlstyCon {
    color: #fff;
    /*background: url(../assets/img2/pic_sub1exercise.png) repeat left top;*/
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    overflow: hidden;
    box-shadow: 0px 4px 8px 0px rgba(205, 215, 232, 0.2);
    border-radius: 12px;
}

.InUlsty li .InUlstyCon::before, .InUlsty li .InUlstyCon::after {
    position: absolute;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.4s, -webkit-transform .4s;
    -moz-transition: opacity 0.4s, -moz-transform .4s;
    -o-transition: opacity 0.4s, -o-transform .4s;
    transition: opacity 0.4s, transform .4s
}

.InUlsty li .InUlstyCon::before {
    top: 14%;
    right: 5%;
    bottom: 14%;
    left: 5%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0
}

.InUlsty li .InUlstyCon::after {
    top: 8%;
    right: 10%;
    bottom: 8%;
    left: 10%;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -o-transform-origin: 100% 0;
    transform-origin: 100% 0
}

.InUlsty li:hover .InUlstyCon::before, .InUlsty li:hover .InUlstyCon::after {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.InUlsty li .InUlstyCon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* -webkit-transition:all .4s ease-in-out; */
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    /* transition:all .4s ease-in-out; */
    /* overflow:hidden; */
    z-index: 99
}

.InUlsty li .InUlstyCon_h {
    text-align: center;
    width: 80%;
    position: absolute;
    top: 75%;
    left: 10%;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    overflow: hidden;
    opacity: 1;
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #111112;
    line-height: 36px;
}

.InUlsty li .InUlstyCon_p {
    text-align: center;
    width: 70%;
    left: 15%;
    height: 70px;
    position: absolute;
    top: 60%;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    overflow: hidden;
    opacity: 0;
}

.InUlsty li:hover .InUlstyCon_p {
    top: 55%;
    opacity: 1
}

.InUlsty li:hover .InUlstyCon_h {
    top: 35%;
    opacity: 1;
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
}

.InUlsty li:hover .InUlstyCon {
    background: url(../assets/img/BlueBj.png) repeat left top
}
</style>
