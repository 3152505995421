<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 74px;" class="flex-row justify-between">
                <div style="width: 75%;" class="flex-row">
                    <Tools_Tree :title="'请选择栏目'"
                                class="search-input search-first"
                                :options="this.plateOptions"
                                :props="defaultProps"
                                :multiple="false"
                                :clearable="true"
                                @getValue="(value)=>value?this.changeTem(value.id):''"/>
                    <el-select v-model="temId" placeholder="考试模板"
                               class="search-input search-first" clearable>
                        <el-option
                            v-if="this.templateOptions"
                            v-for="item in templateOptions"
                            :key="item.id"
                            :label="item.examName"
                            :value="item.id"
                        />
                    </el-select>
                    <el-select v-model="state" placeholder="状态"
                               class="search-input search-first" clearable>
                        <el-option
                            v-for="item in stateList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </div>
                <div style="width: 25%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
                <el-button class="add-button" @click="batchAdd">
                    <span class="add-button-text">批量创建</span>
                </el-button>
                <el-popconfirm title="确定删除吗？删除后试卷将从考试库中撤出！" @confirm="batchDelete">
                    <template #reference>
                        <el-button :loading="this.batchLoading" class="add-button">
                            <span class="add-button-text">批量删除</span>
                        </el-button>
                    </template>
                </el-popconfirm>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      :data="tableData"
                      v-if="this.tableData"
                      border
                      stripe table-layout="auto"
                      @selection-change="selectionChange"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column type="selection" width="55px"/>
                <el-table-column prop="id" label="试卷ID"/>
                <el-table-column prop="temId" label="考试名称">
                    <template #default="scope">
                        {{ scope.row && scope.row.templateVo ? scope.row.templateVo.examName : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="plateId" label="栏目">
                    <template #default="scope">
                        {{
                            scope.row && scope.row.templateVo ? this.pinjiePlateName(scope.row.templateVo.plateId) : ''
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="vehicle" label="车型">
                    <template #default="scope">
                        {{ scope.row && scope.row.templateVo ? scope.row.templateVo.vehicle : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="subjectName" label="科目">
                    <template #default="scope">
                        {{ scope.row && scope.row.templateVo ? scope.row.templateVo.subjectName : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="totalCount" label="题数">
                    <template #default="scope">
                        {{
                            scope.row && scope.row.templateVo ? '总-' + scope.row.templateVo.totalCount +
                                '; 判-' + scope.row.templateVo.truefalseCount +
                                '; 单-' + scope.row.templateVo.singleChoiceCount +
                                '; 多-' + scope.row.templateVo.multipleChoiceCount : ''
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="时长(分)">
                    <template #default="scope">
                        {{ scope.row && scope.row.templateVo ? scope.row.templateVo.duration : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-popconfirm :title="scope.row.state===1?'您确定启用吗？':'您确定要暂停吗？'"
                                       @confirm="handlerState(scope.row.id,scope.row.state===1?0:1)">
                            <template #reference>
                                <el-button class="link_text_no" v-if="scope.row.state===1" link size="small">已暂停
                                </el-button>
                                <el-button class="link_text" v-else link size="small">已启用
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable>
                    <template #default="scope">
                        {{ scope.row ? $TdDate.df(scope.row.createTime, 'YYYY-MM-DD HH:mm:ss') : '' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="150px" label="操作">
                    <template #default="scope">
                        <el-button @click="queryExam(scope.row.id)" link size="small" type="primary">查看</el-button>
                        <el-popconfirm title="确定删除吗？删除后试卷将从考试库中撤出！" @confirm="handleDelete(scope.row.id)">
                            <template #reference>
                                <el-button link size="small" type="danger">
                                    删除
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="创建试卷" v-model="dialogVisible" width="70%">
                <el-form ref="form" :model="form">
                    <div class="flex-col">
                        <div class="flex-col" style="height: 10%">
                            <div class="flex-row">
                                <div class="flex-col" style="width: 80%">
                                    <el-form-item label="栏目" prop="plateId" label-width="11%">
                                        <Tools_Tree :options="this.plateOptions"
                                                    :props="defaultProps"
                                                    :multiple="false"
                                                    :value="form.plate"
                                                    :clearable="true"
                                                    @getValue="(value)=>value?this.changeTemForm(value.id):''"/>
                                    </el-form-item>
                                    <el-form-item label="考试模板" prop="temId" label-width="11%">
                                        <el-select v-model="form.temId" placeholder="请选择考试模板" clearable
                                                   @change="templateChange">
                                            <el-option
                                                v-if="form.templateOptions"
                                                v-for="item in form.templateOptions"
                                                :key="item.id"
                                                :label="item.examName+' 总-'+item.totalCount+' 判-'+item.truefalseCount+' 单-'+item.singleChoiceCount+' 多-'+item.multipleChoiceCount"
                                                :value="item.id"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="flex-col justify-end align-start" style="width: 20%">
                                    <el-button type="primary" :disabled="!form.temId"
                                               style="margin-bottom: 24px;margin-left: -10px"
                                               @click="autoExam(form.temId)">自动生成
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row" style="height: 90%;margin-left: 10px">
                            <div class="flex-col" v-if="form.template&&form.template.truefalseCount>0"
                                 style="width: 33%">
                                <p>判断题:</p>
                                <div class="flex-row" style="width: 80%">
                                    <el-select v-model="form.truefalseQId"
                                               filterable
                                               clearable
                                               remote
                                               reserve-keyword
                                               :remote-method="(query)=>{searchQuestion(form.temId,0,query)}"
                                               :loading="questionLoading">
                                        <el-option
                                            v-if="questionOptions"
                                            v-for="item in questionOptions"
                                            :key="item.id"
                                            :label="'ID:'+item.id+'【'+this.questionTypeMap.get(item.questionType)+'】'+item.title"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-button type="primary" style="margin-bottom: 14px;margin-left: 5px;height: 30px"
                                               :disabled="!form.truefalseQId"
                                               @click="addQuestion(0,this.form.qMap.get(form.truefalseQId))">
                                        +
                                    </el-button>
                                </div>
                                <el-table v-if="form.truefalseList" :data="form.truefalseList" height="450" border>
                                    <el-table-column label="序号" type="index" width="50"/>
                                    <el-table-column label="题目ID" prop="id" width="55"/>
                                    <el-table-column prop="title" label="题目" show-overflow-tooltip>
                                        <template #default="scope">
                                            {{
                                                '【' + this.questionTypeMap.get(scope.row.questionType) + '】' + scope.row.title
                                            }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" width="50" label="操作">
                                        <template #default="scope">
                                            <el-button @click="handlerDelete(0,scope.$index)" size="small"
                                                       type="danger">-
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="flex-col" v-show="form.template&&form.template.singleChoiceCount>0"
                                 style="width: 33%">
                                <p>单选题:</p>
                                <div class="flex-row" style="width: 80%">
                                    <el-select v-model="form.singleChoiceQId"
                                               filterable
                                               clearable
                                               remote
                                               reserve-keyword
                                               :remote-method="(query)=>{searchQuestion(form.temId,1,query)}"
                                               :loading="questionLoading">
                                        <el-option
                                            v-if="questionOptions"
                                            v-for="item in questionOptions"
                                            :key="item.id"
                                            :label="'ID:'+item.id+'【'+this.questionTypeMap.get(item.questionType)+'】'+item.title"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-button type="primary" style="margin-bottom: 14px;margin-left: 5px;height: 30px"
                                               :disabled="!form.singleChoiceQId"
                                               @click="addQuestion(1,this.form.qMap.get(form.singleChoiceQId))">
                                        +
                                    </el-button>
                                </div>
                                <el-table v-if="form.singleChoiceList" :data="form.singleChoiceList" height="450"
                                          border>
                                    <el-table-column label="序号" type="index" width="50"/>
                                    <el-table-column label="题目ID" prop="id" width="55"/>
                                    <el-table-column prop="title" label="题目" show-overflow-tooltip>
                                        <template #default="scope">
                                            {{
                                                '【' + this.questionTypeMap.get(scope.row.questionType) + '】' + scope.row.title
                                            }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" width="50" label="操作">
                                        <template #default="scope">
                                            <el-button @click="handlerDelete(1,scope.$index)" size="small"
                                                       type="danger">-
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="flex-col" v-show="form.template&&form.template.multipleChoiceCount>0"
                                 style="width: 33%">
                                <p>多选题:</p>
                                <div class="flex-row" style="width: 80%">
                                    <el-select v-model="form.multipleChoiceQId"
                                               filterable
                                               clearable
                                               remote
                                               reserve-keyword
                                               :remote-method="(query)=>{searchQuestion(form.temId,2,query)}"
                                               :loading="questionLoading">
                                        <el-option
                                            v-if="questionOptions"
                                            v-for="item in questionOptions"
                                            :key="item.id"
                                            :label="'ID:'+item.id+'【'+this.questionTypeMap.get(item.questionType)+'】'+item.title"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-button type="primary" style="margin-bottom: 14px;margin-left: 5px;height: 30px"
                                               :disabled="!form.multipleChoiceQId"
                                               @click="addQuestion(2,this.form.qMap.get(form.multipleChoiceQId))">
                                        +
                                    </el-button>
                                </div>
                                <el-table v-if="form.multipleChoiceList" :data="form.multipleChoiceList" height="450"
                                          border>
                                    <el-table-column label="序号" type="index" width="50"/>
                                    <el-table-column label="题目ID" prop="id" width="55"/>
                                    <el-table-column prop="title" label="题目" show-overflow-tooltip>
                                        <template #default="scope">
                                            {{
                                                '【' + this.questionTypeMap.get(scope.row.questionType) + '】' + scope.row.title
                                            }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" width="50" label="操作">
                                        <template #default="scope">
                                            <el-button @click="handlerDelete(2,scope.$index)" size="small"
                                                       type="danger">-
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
            <el-dialog title="查看试卷" v-model="detailDialogVisible" width="70%">
                <el-form :model="detailForm">
                    <div class="flex-row">
                        <div class="flex-col" style="width: 47%">
                            <h3 style="margin-left: 10px;font-size: 16px;border: #232323">
                                {{
                                    detailForm.templateVo ?
                                        detailForm.templateVo.examName +
                                        ' 总-' + detailForm.templateVo.totalCount +
                                        ' 判-' + detailForm.templateVo.truefalseCount +
                                        ' 单-' + detailForm.templateVo.singleChoiceCount +
                                        ' 多-' + detailForm.templateVo.multipleChoiceCount : ''
                                }}
                            </h3>
                            <div v-if="detailForm.questionList1" v-for="(question,index) in detailForm.questionList1"
                                 class="flex-col">
                                <div class="flex-col" style="margin-bottom: 8px">
                                    <span>
                                        {{
                                            (index + 1) + '、【' + this.questionTypeMap.get(question.questionType) + '】' + question.title
                                        }}
                                    </span>
                                    <span
                                        v-show="question.optionA">{{
                                            question.optionA + '.' + question.optionAContent
                                        }}</span>
                                    <span
                                        v-show="question.optionB">{{
                                            question.optionB + '.' + question.optionBContent
                                        }}</span>
                                    <span
                                        v-show="question.optionC&&question.optionCContent">{{
                                            question.optionC + '.' + question.optionCContent
                                        }}</span>
                                    <span
                                        v-show="question.optionD&&question.optionDContent">{{
                                            question.optionD + '.' + question.optionDContent
                                        }}</span>
                                    <div v-show="question.annex" class="flex-row justify-center">
                                        <img style="width: 90px;height: 90px;" :src="question.annex"/>
                                    </div>
                                    <span>{{ '【答案】' + (question.answer ? question.answer : '无') }}</span>
                                    <span>
                                        {{
                                            '【答案分析】' + (question.answerAnalysis ? question.answerAnalysis : '无')
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-col" style="width: 6%"></div>
                        <div class="flex-col" style="width: 47%">
                            <div v-if="detailForm.questionList2" v-for="(question,index) in detailForm.questionList2"
                                 class="flex-col">
                                <div class="flex-col" style="margin-bottom: 8px">
                                    <span>
                                        {{
                                            (index + 1 + detailForm.questionList1.length) + '、【' + this.questionTypeMap.get(question.questionType) + '】' + question.title
                                        }}
                                    </span>
                                    <span
                                        v-show="question.optionA">{{
                                            question.optionA + '.' + question.optionAContent
                                        }}</span>
                                    <span
                                        v-show="question.optionB">{{
                                            question.optionB + '.' + question.optionBContent
                                        }}</span>
                                    <span
                                        v-show="question.optionC&&question.optionCContent">{{
                                            question.optionC + '.' + question.optionCContent
                                        }}</span>
                                    <span
                                        v-show="question.optionD&&question.optionDContent">{{
                                            question.optionD + '.' + question.optionDContent
                                        }}</span>
                                    <div v-show="question.annex" class="flex-row justify-center">
                                        <img style="width: 90px;height: 90px;" :src="question.annex"/>
                                    </div>
                                    <span>{{ '【答案】' + (question.answer ? question.answer : '无') }}</span>
                                    <span>
                                        {{
                                            '【答案分析】' + (question.answerAnalysis ? question.answerAnalysis : '无')
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="detailDialogVisible = false">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
            <el-dialog title="批量创建试卷" v-model="batchDialogVisible" width="40%">
                <el-form ref="batchForm" :model="batchForm">
                    <el-form-item label="栏目" prop="plateId" label-width="11%">
                        <Tools_Tree :options="this.plateOptions"
                                    :props="defaultProps"
                                    :multiple="false"
                                    :value="batchForm.plate"
                                    :clearable="true"
                                    @getValue="(value)=>value?this.changeTemBatchForm(value.id):''"/>
                    </el-form-item>
                    <el-form-item label="考试模板" prop="temId" label-width="11%">
                        <el-select v-model="batchForm.temId" placeholder="请选择考试模板" clearable>
                            <el-option
                                v-if="batchForm.templateOptions"
                                v-for="item in batchForm.templateOptions"
                                :key="item.id"
                                :label="item.examName+' 总-'+item.totalCount+' 判-'+item.truefalseCount+' 单-'+item.singleChoiceCount+' 多-'+item.multipleChoiceCount"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="试卷份数" prop="num" label-width="11%">
                        <el-input-number v-model="batchForm.num" :min="1" :max="100"></el-input-number>
                    </el-form-item>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button :loading="batchLoading" @click="batchDialogVisible = false">取 消</el-button>
                        <el-button :loading="batchLoading" type="primary" @click="batchSave">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: 'ExamMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            temId: '',
            templateOptions: [],
            plateId: '',
            state: '',
            stateList: [
                {
                    value: 0,
                    label: '启用',
                },
                {
                    value: 1,
                    label: '暂停',
                }
            ],
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
            loading: true,
            plateOptions: [],
            plateMap: new Map(),
            chapterOptions: [],
            questionLoading: false,
            questionOptions: [],
            questionTypeList: [//0-判断题,1-单选题,2-多选题
                {
                    value: 0,
                    label: '判断题',
                },
                {
                    value: 1,
                    label: '单选题',
                },
                {
                    value: 2,
                    label: '多选题',
                }
            ],
            questionTypeMap: new Map(),
            detailForm: {},
            detailDialogVisible: false,
            batchForm: {},
            batchDialogVisible: false,
            batchLoading: false,
            batchSelects: [],
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        this.plateTree();
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 138; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        changeTem(id) {
            this.templateOptions = [];
            postData("/api/mgt/template/findByPlateId", {
                plateId: id,
            }).then(res => {
                this.templateOptions = res.data;
            })
        },
        changeTemForm(id) {
            this.form.templateOptions = [];
            postData("/api/mgt/template/findByPlateId", {
                plateId: id,
            }).then(res => {
                this.form.templateOptions = res.data;
                this.form.templateOptions.map(tem => {
                    this.form.tempMap.set(tem.id, tem);
                });
            })
        },
        changeTemBatchForm(id) {
            this.batchForm.templateOptions = [];
            postData("/api/mgt/template/findByPlateId", {
                plateId: id,
            }).then(res => {
                this.batchForm.templateOptions = res.data;
            })
        },
        templateChange(value) {
            this.form.template = this.form.tempMap.get(value);
        },
        load() {
            postData("/api/mgt/exam/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                temId: this.temId,
                plateId: this.plateId,
                state: this.state
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        plateTree() {
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.plateOptions = res.data;
                    res.data.map(item => {
                        this.plateMap.set(item.id, item);
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                this.plateMap.set(child.id, child);
                            });
                        }
                    });
                }
            });
            this.questionTypeList.map(type => {
                this.questionTypeMap.set(type.value, type.label);
            });
        },
        pinjiePlateName(plateId) {
            var child = this.plateMap.get(plateId);
            if (!child) return "";
            if (child.parentId === 0) return child.plateName;
            var parentName = this.pinjiePlateName(child.parentId);
            return parentName + '>>' + child.plateName;
        },
        add() {
            this.dialogVisible = true;
            this.form = {};
            this.form.plate = {};
            this.form.template = {};
            this.form.tempMap = new Map();
            this.form.truefalseList = [];
            this.form.singleChoiceList = [];
            this.form.multipleChoiceList = [];
            this.form.templateOptions = [];
            this.form.truefalseQId = '';
            this.form.singleChoiceQId = '';
            this.form.multipleChoiceQId = '';
            this.form.qMap = new Map();
        },
        batchAdd() {
            this.batchDialogVisible = true;
            this.batchForm = {num: 10};
            this.batchForm.plate = {};
        },
        handleDelete(id) {
            postData("/api/mgt/exam/delete", {
                id: id,
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        handlerState(id, state) {
            postData("/api/mgt/exam/state", {
                id: id,
                state: state
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    var truefalse = "";
                    var singleChoice = "";
                    var multipleChoice = "";
                    var totalCount = 0;
                    if (this.form.truefalseList && this.form.truefalseList.length > 0) {
                        truefalse = this.form.truefalseList.map(o => o.id + '|' + o.answer).join(",");
                        totalCount = totalCount + this.form.truefalseList.length;
                    }
                    if (this.form.singleChoiceList && this.form.singleChoiceList.length > 0) {
                        singleChoice = this.form.singleChoiceList.map(o => o.id + '|' + o.answer).join(",");
                        totalCount = totalCount + this.form.singleChoiceList.length;
                    }
                    if (this.form.multipleChoiceList && this.form.multipleChoiceList.length > 0) {
                        multipleChoice = this.form.multipleChoiceList.map(o => o.id + '|' + o.answer).join(",");
                        totalCount = totalCount + this.form.multipleChoiceList.length;
                    }
                    if (this.form.template.totalCount != totalCount) {
                        this.$message({
                            type: "error",
                            message: "试卷题不足，无法保存！"
                        })
                        return;
                    }
                    postData("/api/mgt/exam/save", {
                        temId: this.form.template.id,
                        truefalse: truefalse,
                        singleChoice: singleChoice,
                        multipleChoice: multipleChoice,
                    }).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                            this.load();//刷新表格数据
                            this.dialogVisible = false;//关闭弹窗
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                    });
                }
            });
        },
        searchQuestion(templateId, questionType, query) {
            if (query !== '') {
                this.questionLoading = true;
                postData("/api/mgt/exam/fastSearch", {
                    templateId: templateId,
                    questionType: questionType,
                    search: query
                }).then(res => {
                    if (res.code === "000000") {
                        this.questionOptions = res.data;
                        res.data.map(q => {
                            this.form.qMap.set(q.id, q);
                        });
                        this.questionLoading = false;
                    }
                })
            } else {
                this.questionOptions = [];
            }
        },
        addQuestion(questionType, question) {
            if (questionType === 0) {
                if (this.form.truefalseList.findIndex(o => o.id === question.id) < 0) {
                    this.form.truefalseList.push(question);
                }
            } else if (questionType === 1) {
                if (this.form.singleChoiceList.findIndex(o => o.id === question.id) < 0) {
                    this.form.singleChoiceList.push(question);
                }
            } else if (questionType === 2) {
                if (this.form.multipleChoiceList.findIndex(o => o.id === question.id) < 0) {
                    this.form.multipleChoiceList.push(question);
                }
            }
        },
        handlerDelete(questionType, index) {
            if (questionType === 0) {
                this.form.truefalseList.splice(index, 1);
            } else if (questionType === 1) {
                this.form.singleChoiceList.splice(index, 1);
            } else if (questionType === 2) {
                this.form.multipleChoiceList.splice(index, 1);
            }
        },
        selectionChange(val) {
            this.batchSelects = [];
            if (val) {
                val.map(item => {
                    this.batchSelects.push(item.id);
                })
            }
        },
        batchDelete() {
            this.batchLoading = true;
            if (this.batchSelects && this.batchSelects.length > 0) {
                var ids = this.batchSelects.join(",");
                postData("/api/mgt/exam/batchDelete", {
                    ids: ids,
                }).then(res => {
                    if (res.code === "000000") {
                        this.$message({
                            type: "success",
                            message: res.message
                        })
                        this.load()//重新加载
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message
                        })
                    }
                    this.batchLoading = false;
                });
            }
        },
        autoExam(temId) {
            postData("/api/mgt/exam/createExam", {
                templateId: temId
            }).then(res => {
                if (res.code === "000000") {
                    this.form.truefalseList = res.data.truefalseList;
                    this.form.singleChoiceList = res.data.singleChoiceList;
                    this.form.multipleChoiceList = res.data.multipleChoiceList;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        batchSave() {
            this.batchLoading = true;
            postData("/api/mgt/exam/batchCreateExam", {
                templateId: this.batchForm.temId,
                num: this.batchForm.num
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: "批量创建试卷" + res.data + "成功！失败" + (this.batchForm.num - res.data)
                    })
                    this.load();//刷新表格数据
                    this.batchDialogVisible = false;//关闭弹窗
                    this.batchLoading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        queryExam(examId) {
            this.detailDialogVisible = false;
            postData("/api/mgt/exam/detail", {
                id: examId
            }).then(res => {
                if (res.code === "000000") {
                    this.detailForm = res.data;
                    this.detailDialogVisible = true;
                    if (this.detailForm.questionList
                        && this.detailForm.questionList.length > 0
                        && this.detailForm.questionList.length > this.detailForm.halfCount) {
                        this.detailForm.questionList1 = [];
                        this.detailForm.questionList2 = [];
                        this.detailForm.questionList.forEach((item, index) => {
                            if (index < this.detailForm.halfCount) {
                                this.detailForm.questionList1.push(item);
                            } else {
                                this.detailForm.questionList2.push(item);
                            }
                        })
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
