<template>
    <div style="width: 100%; background: linear-gradient(to bottom, #caeaf5, #dcdbdb);"
         @contextmenu.prevent="disableRightClick">
        <!-- 外部容器 -->
        <div class="container">
            <!-- 视频播放区容器，初始隐藏 -->
            <div v-if="isVideoShown" class="video-container">
                <VideoPlayer v-if="isVideoShown" :videoSource="currentVideoUrl" ref="videoPlayer"
                             @ended="onVideoEnded"/>
            </div>

            <div class="flex-row" v-show="!isVideoShown">
                <button v-for="(video, index) in videoUrls" :key="index" @click="toggleVideo(index)"
                        class="pause-button">{{ video.title }}
                </button>
            </div>

            <!-- 当前播放视频的关闭按钮 -->
            <button v-if="isPlaying && isVideoShown" @click="hideVideo" class="close-button">×</button>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/VideoPlayer.vue';

export default {
    name: 'Video2',
    components: {VideoPlayer},
    data() {
        return {
            videoUrls: [
                {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%89%201-1.mp4',
                    title: '科目四精选1-1(1-27题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%89%201-2%201-43%E9%A2%98.mp4',
                    title: '科目四精选1-2(1-43题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%89%201-2%2044-81%E9%A2%98.mp4',
                    title: '科目四精选1-2(44-81题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-3%201-48%E9%A2%98.mp4',
                    title: '科目四精选1-3(1-48题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-3%2049-95%E9%A2%98.mp4',
                    title: '科目四精选1-3(49-95题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-4%201-38%E9%A2%98.mp4',
                    title: '科目四精选1-4(1-38题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-4%2039-80%E9%A2%98.mp4',
                    title: '科目四精选1-4(39-80题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-5%201-30%E9%A2%98.mp4',
                    title: '科目四精选1-5(1-30题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-5%2031-51%E9%A2%98%EF%BC%88%E6%8A%80%E5%B7%A7%EF%BC%89.mp4',
                    title: '科目四精选1-5(31-51题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-6%201-40%E9%A2%98.mp4',
                    title: '科目四精选1-6(1-40题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-6%2041-81%E9%A2%98.mp4',
                    title: '科目四精选1-6(41-81题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-6%2082-105%E9%A2%98.mp4',
                    title: '科目四精选1-6(82-105题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%891-6%20106-137%E9%A2%98.mp4',
                    title: '科目四精选1-6(106-137题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%892-1%201-43%E9%A2%98.mp4',
                    title: '科目四精选2-1(1-43题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%89%202-1%2044-82%E9%A2%98.mp4',
                    title: '科目四精选2-1(44-82题)视频讲解'
                }, {
                    url: 'https://driving-exam-local.oss-cn-shanghai.aliyuncs.com/CDN/2024-1/%E7%A7%91%E7%9B%AE%E5%9B%9B%E7%B2%BE%E9%80%89%202-2.mp4',
                    title: '科目四精选2-2(1-34题)视频讲解'
                },
            ],
            currentVideoIndex: -1,
            isVideoShown: false,
            isPlaying: false,
        };
    },
    computed: {
        currentVideoUrl() {
            return this.videoUrls[this.currentVideoIndex].url;
        }
    },
    methods: {
        toggleVideo(index) {
            this.currentVideoIndex = index;
            this.isVideoShown = true;
            this.isPlaying = true;

            this.$nextTick(() => {
                this.$refs.videoPlayer.play();
            });
        },
        hideVideo() {
            this.isVideoShown = false;
            this.isPlaying = false;
            this.$refs.videoPlayer.pause(); // 停止播放视频
        },
        onVideoEnded() {
            this.isPlaying = false; // 视频播放结束后恢复初始状态
        },
        disableRightClick(e) {
            e.preventDefault();
        }
    }
}
</script>
<style scoped>
.flex-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 创建三列 */
    grid-gap: 50px; /* 设置列与列之间的间距 */
    align-items: center;
    justify-content: center; /* 添加此行以实现按钮在行内的居中对齐 */
    width: 80%;
    margin-left: 180px;
    height: auto; /* 或者根据实际情况设置高度 */
    background: linear-gradient(to bottom, #caeaf5, #dcdbdb);
    padding: 10px 0; /* 可选：设置上下的内边距 */
}

/* 确保按钮在小屏幕设备上也能自适应堆叠 */
@media (max-width: 768px) {
    .flex-row {
        grid-template-columns: 1fr; /* 当屏幕宽度小于768px时，改为单列布局 */
    }
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px; /* 添加宽度和高度以确保按钮为正方形 */
    height: 50px;
    line-height: 40px; /* 确保文字垂直居中 */
    text-align: center;
    padding: 0;
    cursor: pointer;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%; /* 保持不变，已设置为圆形 */
    font-size: 40px;
    border: none;
}

.pause-button {
    margin-top: 10px;
    font-size: 22px;
    display: inline-block;
    padding: 15px 30px; /* 增加左右内边距以增大点击区域 */
    cursor: pointer;
    color: #131313; /* 文字颜色改为白色 */
    background-color: #07be54;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
    transition: all 0.3s ease-in-out; /* 添加过渡动画 */
    position: relative; /* 设置为相对定位，以便添加hover时的效果 */
    overflow: hidden;
    text-align: center;
    line-height: 36px; /* 调整行高以适应padding */
}

.pause-button:hover {
    background-color: #059f42; /* 鼠标悬停时背景色变深 */
    transform: translateY(-2px); /* 给按钮添加微小的上浮效果 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 鼠标悬停时增加阴影大小 */
}

.pause-button::before { /* 添加一个小图标，例如播放/暂停图标 */
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    /*background-image: url(your_icon_path);*/
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.video-container {
    /* 根据实际情况添加CSS样式，使视频组件适应按钮原来的位置和大小 */
    position: relative;
    width: 100%; /* 或者设置为固定宽度，匹配按钮行宽度 */
    height: auto; /* 根据视频比例动态调整高度或设置固定高度 */
    overflow: hidden;
    /* 可能需要其他样式来定位和显示，比如 flex 等布局方式 */
}
</style>
