<template>
    <div style="width: 100%;background: linear-gradient(to bottom, #b7b3b3, #bed5fa); padding: 200px;"
         @contextmenu.prevent="disableRightClick">
        <div class="video-button-container">
            <el-button @click="$router.push({path:'/video1'})" type="text" class="video-button">
                <span class="button-text">科目一视频讲解</span>
                <i class="right-icon el-icon-video-play"></i>
            </el-button>

            <el-button @click="$router.push({path:'/video2'})" type="text" class="video-button">
                <span class="button-text">科目四视频讲解</span>
                <i class="right-icon el-icon-video-play"></i>
            </el-button>
            <!--            <el-button @click="$router.push({path:''})" type="text" class="video-button">-->
            <!--                <span class="button-text">满分学习视频讲解</span>-->
            <!--                <i class="right-icon el-icon-video-play"></i>-->
            <!--            </el-button>-->
        </div>
    </div>
</template>


<script>
export default {
    methods: {
        disableRightClick(e) {
            e.preventDefault(); // 阻止默认的右键菜单显示
        }
    }
};
</script>

<style scoped>
.video-button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.video-button {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    color: #333; /* 文字颜色 */
    background-color: transparent;
    border: 2px solid #494949; /* 边框颜色 */
    border-radius: 8px;
    cursor: pointer;
    transition: none; /* 取消悬浮变色 */
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0 30px;
    height: 60px;
    line-height: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.video-button:hover .button-text {
    color: #131313;
}


.button-text {
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.right-icon {
    font-size: 22px;
    margin-right: 10px;
}
</style>
