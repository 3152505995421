import dayjs from 'dayjs'

export function df(date, format) {
    return date ? dayjs(date).format(format) : "";
}

//日期格式固定输出YYYY-MM-DD HH:mm:ss
export function df_sub(date, day) {
    if (!date) return "";
    var dateTem = dayjs(date).subtract(day, "day");
    return df(dateTem, "YYYY-MM-DD HH:mm:ss");
}

//日期格式固定输出YYYY-MM-DD HH:mm:ss
export function df_add(date, day, format) {
    if (!date) return "";
    var dateTem = dayjs(date).add(day, "day");
    return df(dateTem, format);
}

export function df_subMin(date, min) {
    if (!date) return "";
    var dateTem = dayjs(date).subtract(min, "minute");
    return df(dateTem, "YYYY-MM-DD HH:mm:ss");
}

export function simple_df(date) {
    return date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null;
}

export function simple_df_YMD(date) {
    return date ? dayjs(date).format("YYYY-MM-DD") : null;
}

export function simple_df_HMS(date) {
    return date ? dayjs(date).format("HH:mm:ss") : null;
}

//当前时间是不是比date小
export function isBefore(source, target) {
    if (!target) return false;
    return dayjs(source).isBefore(target);
}
