<template>
    <div class="flex-col align-center home" style="background: linear-gradient(to bottom, #cedbe0, #dcdbdb);">
        <img src="../assets/img2/pic_logo.png" style="margin-top:300px;width:250px;height: 70px;"/>
        <span class="welcome">欢迎进入{{ title }}管理中心</span>
    </div>
</template>

<script>
import Config from '@/config';

export default {
    name: "HomeMgt",
    data() {
        return {
            title: Config.title,
        }
    }
}
</script>

<style scoped>

.welcome {
    margin-top: 20px;
    font-size: 35px;
    font-family: 楷体, KaiTi;
    font-weight: 400;
    color: #212121;
    line-height: 24px;
}

.home {
    width: calc(100% - 38px);
    background-color: #FFFFFF;
    margin-left: 20px;
    margin-top: 12px;
    height: 100%;
}
</style>
