import request from "@/utils/request";
import qs from 'qs';

export function postData(url, data) {
    return request.post(url, qs.stringify(data), config => {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return config;
    });
}

export function postDataFormData(url, data) {
    let formData = new FormData();
    for (let key in data) {
        var value = data[key];
        if (null !== value) {
            formData.append(key, data[key]);
        }
    }
    return request.post(url, formData, config => {
        config.headers['Content-Type'] = 'multipart/form-data';
        return config;
    })
}

export function postJson(url, data) {
    return request.post(url, data, config => {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
        return config;
    })
}
