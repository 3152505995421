<template>
    <div class="footer flex-row justify-between" style="background: linear-gradient(to bottom, #5d84be, #dcdbdb);">
        <div class="bothSides"/>
        <div class="middleMain flex-col">
            <img src="../assets/img2/pic_logo.png" style="width:171px;height: 64px;margin-top: 10px"/>
            <el-divider style="margin-top: 10px;margin-bottom: 8px;width:26%"/>
            <span class="flex-row align-center" style="height: 15px;margin-top: 0px">
                <img src="../assets/img2/icon_contact.png" style="width: 14px;height: 14px;margin-top: 10px"/>
                <p style="margin-left: 10px;margin-top: 28px;font-size: 20px">然 校 长</p>
                <img src="../assets/img2/contact_information.png" style="width:150px;height: 140px;margin-left: 330px;"/>
            </span>
            <span class="flex-row align-center" style="margin-top: 8px;height: 16px">
                <img src="../assets/img2/icon_tel.png" style="width: 14px;height: 14px;margin-top: 35px"/>
                <p style="margin-left: 10px;margin-top: 50px;font-size: 20px">19965433320</p>
            </span>
            <span class="flex-row align-center" style="margin-top: 8px;height:16px">
                <img src="../assets/img2/icon_address.png" style="width: 14px;height: 14px;margin-top: 47px"/>
                <p style="margin-left: 10px;margin-top: 65px;font-size: 20px">合肥市瑶海区凤阳路恒丰大厦A座2810室</p>
            </span>
            <span class="flex-row align-center" style="margin-top: 15px;height: 16px;margin-left:416px ">
<!--                <img src="../assets/img2/icon_copyright.png" style="width: 14px;height: 14px;margin-left:200px"/>-->
                <p style="margin-left: 35px;margin-bottom: 5px">然 校 长 交 规</p>
            </span>
        </div>
        <div class="bothSides"/>
    </div>
</template>

<script>

export default {
    name: "Footer",
    data() {
        return {}
    },
    created() {
    },
    methods: {}
}
</script>

<style scoped>
.footer {
    width: 100%;
    height: 100%;
    background: #F5F7FA;
}

.bothSides {
    width: 12.5%;
    height: 100%
}

.middleMain {
    width: 75%;
    height: 100%
}
</style>
