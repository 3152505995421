<template>
    <div>
        <!--        {{ days }}天{{ hours }}小时{{ minutes }}分{{ seconds }}秒-->
        {{ hours }}小时{{ minutes }}分{{ seconds }}秒
    </div>
</template>

<script>
export default {
    name: 'Tools_Countdown',
    props: {
        duration: {
            type: Number, // 倒计时时长
            required: true
        },
        startTime: {
            type: Number,
            default: Date.now()
        }
    },
    data() {
        return {
            // days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            timer: null
        }
    },
    methods: {
        updateTime() {
            let restTime = this.duration - (Date.now() - this.startTime);
            if (restTime < 0) {
                clearInterval(this.timer)
                this.$emit('toEnd', true);
                return
            }
            // this.days = Math.floor(restTime / 1000 / 60 / 60 / 24)
            // this.hours = Math.floor(restTime / 1000 / 60 / 60 % 24)
            this.hours = Math.floor(restTime / 1000 / 60 / 60)
            this.minutes = Math.floor(restTime / 1000 / 60 % 60)
            this.seconds = Math.floor(restTime / 1000 % 60)
        }
    },
    mounted() {
        this.updateTime()
        this.timer = setInterval(() => {
            this.updateTime()
        }, 1000)
    }
}
</script>
