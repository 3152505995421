<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 120px;" class="flex-row justify-between">
                <div style="width: 85%;" class="flex-col">
                    <div style="height: 50%" class="flex-row">
                        <Tools_Tree :title="'请选择栏目'"
                                    class="search-input search-first"
                                    :options="this.plateOptions"
                                    :props="defaultProps"
                                    :multiple="false"
                                    :clearable="true"
                                    @getValue="(value)=>this.changeTem(value.id)"/>
                        <el-select v-model="temId" placeholder="考试模板"
                                   class="search-input search-first" clearable>
                            <el-option
                                v-for="item in templateOptions"
                                :key="item.id"
                                :label="item.examName"
                                :value="item.id"
                            />
                        </el-select>
                        <Tools_SelectMember :initMemberNo="memberNo"
                                            :initMemberOptions="initMemberOptions"
                                            @getValue="(value)=>memberNo=value"
                                            class="search-input search-first"
                        />
                    </div>
                    <div style="height: 50%" class="flex-row">
                        <el-input v-model="startPoint" placeholder="成绩下限"
                                  clearable
                                  type="number"
                                  class="search-small-input search-second"></el-input>
                        <el-input v-model="endPoint" placeholder="成绩上限"
                                  clearable
                                  type="number"
                                  class="search-small-input search-second"></el-input>
                        <Tools_SelectTime :init-start-time="startTime" :title="'开考时间'"
                                          @getValue="(value)=>startTime=value"
                                          class="search-input search-second"
                        />
                    </div>
                </div>
                <div style="width: 15%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      :data="tableData"
                      v-if="tableData"
                      border
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="id" label="成绩单ID"/>
                <el-table-column prop="temId" label="考试名称">
                    <template #default="scope">
                        {{ scope.row.templateVo ? scope.row.templateVo.examName : "" }}
                    </template>
                </el-table-column>
                <el-table-column prop="plateId" label="栏目">
                    <template #default="scope">
                        {{ scope.row.templateVo ? this.pinjiePlateName(scope.row.templateVo.plateId) : "" }}
                    </template>
                </el-table-column>
                <el-table-column prop="vehicle" label="车型">
                    <template #default="scope">
                        {{ scope.row.templateVo ? scope.row.templateVo.vehicle : "" }}
                    </template>
                </el-table-column>
                <el-table-column prop="subjectName" label="科目">
                    <template #default="scope">
                        {{ scope.row.templateVo ? scope.row.templateVo.subjectName : "" }}
                    </template>
                </el-table-column>
                <el-table-column prop="examId" label="试卷编号"/>
                <el-table-column prop="examResult" label="成绩"/>
                <el-table-column prop="startTime" label="开始时间">
                    <template #default="scope">
                        {{ $TdDate.df(scope.row.startTime, 'YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="结束时间">
                    <template #default="scope">
                        {{ $TdDate.df(scope.row.endTime, 'YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="200px" label="操作">
                    <template #default="scope">
                        <el-button @click="queryTranscript(scope.row.id)" link size="small" type="primary">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="查看试卷" v-model="dialogVisible" width="60%">
                <el-form :model="detailForm">
                    <div class="flex-row">
                        <div class="flex-col" style="width: 50%">
                            <h3 style="margin-left:15px;font-size: 16px;border: #232323;"
                                class="flex-row justify-center">
                                {{
                                    detailForm.templateVo ? detailForm.templateVo.examName +
                                        ' 总-' + detailForm.templateVo.totalCount +
                                        ' 判-' + detailForm.templateVo.truefalseCount +
                                        ' 单-' + detailForm.templateVo.singleChoiceCount +
                                        ' 多-' + detailForm.templateVo.multipleChoiceCount : ''
                                }}
                            </h3>
                            <el-divider style="margin-bottom: 10px;">{{
                                    '答题人:' + detailForm.mobile + '  总得分:' + detailForm.examResult + '分'
                                }}
                            </el-divider>
                            <div v-if="detailForm.questionList1"
                                 v-for="(question,index) in detailForm.questionList1" class="flex-col">
                                <div class="flex-col" style="margin-bottom: 8px">
                                    <span>
                                        {{
                                            (index + 1) + '、【' + this.questionTypeMap.get(question.questionType) + '】' + question.title
                                        }}
                                    </span>
                                    <div v-show="question.annex" style="height: 100px;" class="flex-row justify-center">
                                        <img style="height: 100%" :src="question.annex"/>
                                    </div>
                                    <span v-if="this.contentMap.get(question.id)" class="flex-row">
                                        <span style="margin-right: 5px">{{
                                                '【回答】' + this.contentMap.get(question.id).a
                                            }}</span>
                                        <span v-if="this.contentMap.get(question.id).r===0"
                                              style="color: #14a235">正确</span>
                                        <span v-else style="color: #ee2846">错误</span>
                                    </span>
                                    <span v-else class="flex-row">
                                        <span style="margin-right: 5px">未答</span>
                                        <span style="color: #ee2846">错误</span>
                                    </span>
                                    <span>{{ '【答案】' + (question.answer ? question.answer : '无') }}</span>
                                    <span>
                                        {{
                                            '【答案分析】' + (question.answerAnalysis ? question.answerAnalysis : '无')
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex-col" style="width: 50%">
                            <div v-if="detailForm.questionList2"
                                 v-for="(question,index) in detailForm.questionList2" class="flex-col">
                                <div class="flex-col" style="margin-bottom: 8px">
                                    <span>
                                        {{
                                            (index + 1 + detailForm.questionList1.length) + '、【' + this.questionTypeMap.get(question.questionType) + '】' + question.title
                                        }}
                                    </span>
                                    <div v-show="question.annex" style="height: 100px;" class="flex-row justify-center">
                                        <img style="height: 100%" :src="question.annex"/>
                                    </div>
                                    <span v-if="this.contentMap.get(question.id)" class="flex-row">
                                        <span style="margin-right: 5px">{{
                                                '【回答】' + this.contentMap.get(question.id).a
                                            }}</span>
                                        <span v-if="this.contentMap.get(question.id).r===0"
                                              style="color: #14a235">正确</span>
                                        <span v-else style="color: #ee2846">错误</span>
                                    </span>
                                    <span v-else class="flex-row">
                                        <span style="margin-right: 5px">未作答</span>
                                        <span style="color: #ee2846">错误</span>
                                    </span>
                                    <span>{{ '【答案】' + (question.answer ? question.answer : '无') }}</span>
                                    <span>
                                        {{
                                            '【答案分析】' + (question.answerAnalysis ? question.answerAnalysis : '无')
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: 'TranscriptMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            temId: '',
            memberNo: '',
            initMemberOptions: [],
            templateOptions: [],
            plateId: '',
            startPoint: '',
            endPoint: '',
            startTime: [],
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
            loading: true,
            plateOptions: [],
            plateMap: new Map(),
            questionTypeList: [//0-判断题,1-单选题,2-多选题
                {
                    value: 0,
                    label: '判断题',
                },
                {
                    value: 1,
                    label: '单选题',
                },
                {
                    value: 2,
                    label: '多选题',
                }
            ],
            questionTypeMap: new Map(),
            detailForm: {},
            contentMap: new Map(),
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        this.plateTree();
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 138; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        changeTem(id) {
            this.templateOptions = [];
            postData("/api/mgt/template/findByPlateId", {
                plateId: id,
            }).then(res => {
                this.templateOptions = res.data;
            })
        },
        load() {
            var start = '';
            var end = '';
            if (this.startTime && this.startTime.length > 1) {
                start = this.startTime[0];
                end = this.startTime[1];
            }
            postData("/api/mgt/transcript/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                temId: this.temId,
                memberNo: this.memberNo,
                startPoint: this.startPoint,
                endPoint: this.endPoint,
                start: start,
                end: end
            }).then(res => {
                if (res && res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        plateTree() {
            this.plateOptions = [];
            this.plateMap = new Map();
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res && res.code === "000000") {
                    res.data.map(item => {
                        this.plateMap.set(item.id, item);
                        this.plateOptions.push(item);
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                this.plateMap.set(child.id, child);
                                this.plateOptions.push(item);
                            });
                        }
                    });
                }
            });
            this.questionTypeMap = new Map();
            this.questionTypeList.map(type => {
                this.questionTypeMap.set(type.value, type.label);
            });
        },
        pinjiePlateName(plateId) {
            if (!this.plateMap) return "";
            var child = this.plateMap.get(plateId);
            if (!child) return "";
            if (child.parentId === 0) return child.plateName;
            var parentName = this.pinjiePlateName(child.parentId);
            return parentName + '>>' + child.plateName;
        },
        queryTranscript(examId) {
            this.dialogVisible = false;
            postData("/api/mgt/transcript/detail", {
                id: examId
            }).then(res => {
                if (res.code === "000000") {
                    this.detailForm = res.data;
                    this.dialogVisible = true;
                    this.detailForm.questionList1 = [];
                    this.detailForm.questionList2 = [];
                    if (this.detailForm.examDetailVo &&
                        this.detailForm.examDetailVo.questionList
                        && this.detailForm.examDetailVo.questionList.length > 0
                        && this.detailForm.examDetailVo.questionList.length > this.detailForm.examDetailVo.halfCount) {
                        this.detailForm.examDetailVo.questionList.forEach((item, index) => {
                            if (index < this.detailForm.examDetailVo.halfCount) {
                                this.detailForm.questionList1.push(item);
                            } else {
                                this.detailForm.questionList2.push(item);
                            }
                        })
                    }
                    this.contentMap = new Map();
                    if (this.detailForm.content) {
                        this.detailForm.content.map(answer => {
                            if (answer.a && answer.a !== "") {
                                this.contentMap.set(answer.q, answer);
                            }
                        })
                    }
                }
            })
        },
    }
}
</script>
<style scoped>
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
