<template>
    <div class="flex-col" style="height: 100%">
        <el-tabs v-model="editableTabsValue"
                 type="card"
                 @tab-remove="removeTab">
            <el-tab-pane
                v-for="(item, index) in editableTabs"
                :key="item.name"
                :label="item.title"
                :name="item.name" :closable="item.name!=='home'">
                <component :is="this.$TabNameMap.get(item.name)" :params="this.params" @addTabs="addTabs"
                           @removeTab="removeTab" @removeTabAndAdd="removeTabAndAdd"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import {getUser, isNoUser} from "@/utils/userLocal";

export default {
    name: "Tools_Tabs",
    data() {
        return {
            user: {},
            params: '',
            editableTabsValue: 'home',
            editableTabs: [
                {
                    title: "首页",
                    name: "home"
                }
            ],
            zvisible: false, // 右键菜单是否显示
            left: 0, // 右键菜单的位置
            top: 0, // 右键菜单的位置
            currentTab: "", // 当前选中的tab
        }
    },
    created() {
        this.user = getUser() || {};
        if (!isNoUser(this.user)) {
            this.$router.push("/login");
        }
    },
    methods: {
        // tabs右键菜单
        openRightMenu(e) {
            this.zvisible = false;
            var target = e.srcElement ? e.srcElement : e.target;
            if (target.id) {
                // 右键点击的是tab
                this.currentTab = target.innerText;
                this.left = e.clientX;
                this.top = e.clientY;
                this.zvisible = true;
            }
        },
        removeAll() {
            this.editableTabs = [
                {
                    title: "首页",
                    name: "home"
                }
            ]
            this.currentTab = "";
            this.editableTabsValue = 'home';
        },
        closeOther() {
            let obj = {}; // 当前标签
            this.editableTabs.map((el) => {
                if (this.currentTab === el.title) {
                    obj = el;
                }
            });
            this.editableTabs = [
                {
                    title: "首页",
                    name: "home"
                }
            ]
            if (obj) {
                this.editableTabs.push(obj);
                this.editableTabsValue = obj.name;
            } else {
                this.editableTabsValue = 'home';
            }
        },
        addTabs(title, name, params) {
            if (this.editableTabs.findIndex(tag => tag.name === name) !== -1) {
                this.asyncRemoveTab(name).then(() => {
                    this.addNewTab(title, name, params);
                });
            } else {
                this.addNewTab(title, name, params);
            }
        },
        addNewTab(title, name, params) {
            this.editableTabs.push({
                title: title,
                name: name,
            });
            this.editableTabsValue = name;
            this.params = params;
        },
        async asyncRemoveTab(name) {
            await this.removeTab(name);
        },
        removeTab(name) {
            this.editableTabs = this.editableTabs.filter(tab => tab.name !== name);
            if (this.editableTabs && this.editableTabs.length > 0) {
                this.editableTabsValue = this.editableTabs[this.editableTabs.length - 1].name;
            }
        },
        removeTabAndAdd(reName, lastTab, params) {
            this.asyncRemoveTab(reName).then(() => {
                this.addTabs(lastTab.title, lastTab.name, params);
            });
        }
    }
}
</script>
<style scoped>
::v-deep(.el-tabs__header) {
    margin: 0 0 0 20px;
}

::v-deep(.el-tabs) {
    --el-tabs-header-height: 48px;
}

.zdiv {
    margin: 0;
    border: 1px solid rgb(187, 186, 186);
    background: rgb(255, 255, 255);
    z-index: 1000;
    position: absolute;
    list-style-type: none;
    padding: 5px;
    border-radius: 7px;
    font-size: 12px;
}

.zdiv li {
    margin: 0;
    padding: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.zdiv li:hover {
    background: #e1e1e1;
    cursor: pointer;
}
</style>
<style scoped lang="css" src="../assets/css/header.css"/>
