<template>
    <el-upload
        ref="upload"
        :auto-upload="false"
        :disabled="!isEdit"
        :on-change="onchange"
        :show-file-list="false"
        action="#"
        class="avatar-uploader flex-col">
        <div v-if="fileUrl||thumbnail" class="flex-col">
            <div class="demo-image__preview">
                <el-image style="width: 100px; height: 100px"
                          :src="getImageUrl(fileUrl)"
                          :preview-src-list="isEdit?null:[fileUrl]">
                </el-image>
            </div>
            <el-button v-show="isEdit" link size="small" type="primary">重新上传
            </el-button>
        </div>
        <el-icon v-else class="avatar-uploader-icon">
            <Plus/>
        </el-icon>
    </el-upload>
    <!--    <el-button v-show="fileUrl" link @click="this.showViewer=true">预览</el-button>-->
    <!--    <el-image-viewer-->
    <!--        v-if="this.showViewer"-->
    <!--        :on-close="()=>{this.showViewer=false}"-->
    <!--        :url-list="[fileUrl]"/>-->
</template>

<script>

export default {
    name: "Tools_UploadFile",
    emits: ["getFile"],
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        initFileUrl: {
            type: String,
            default: ''
        },
        fileSizeLimit: {
            type: String,
            default: '2M'
        },
        initIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            thumbnail: '',
            fileUrl: '',
            showViewer: false,
        }
    },
    watch: {
        initIndex: {
            handler(val, oldval) {
                this.thumbnail = ''
            },
        },
        initFileUrl: {
            handler(val, oldval) {
                this.fileUrl = this.initFileUrl;    // 初始值
                this.thumbnail = ''
            },
        },
    },
    mounted() {
        this.fileUrl = this.initFileUrl;    // 初始值
        this.thumbnail = ''
    },
    created() {
    },
    methods: {
        onchange(file, list) {
            this.$refs.upload.clearFiles();
            var result;
            if (this.fileSizeLimit === '2M') {
                result = this.$TUtil.validFile2M(file);
            } else if (this.fileSizeLimit === 'Max') {
                result = this.$TUtil.validFileMax(file);
            }
            if (result.success) {
                list.forEach(item => {//循环数组进行base64转换
                    this.$TUtil.getBase64(item.raw).then(res => {
                        this.thumbnail = res	//在原数组中每个数据添加属性url和转换后的base64编码值（这里我放在原字段中方便删除文件列表使用,也可以自定义数组存放根据个人喜好）
                    })
                })
                var resultFile;
                if (list.length > 0) {
                    resultFile = list[list.length - 1].raw;
                }
                this.$emit('getFile', resultFile);
            } else {
                this.$message.error(result.msg);
            }
        },
        getImageUrl(imgUrl) {
            if (this.thumbnail) return this.thumbnail;
            if (imgUrl === undefined) return "";
            return imgUrl;
        },
    }
}
</script>

<style scoped>
</style>
<style src="../assets/css/avatar-uploader.css" scoped></style>
