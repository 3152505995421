import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import '@/assets/css/global.css'; //统一样式
import Cookie from 'vue-cookies';
import * as tdDate from '@/utils/tdDate'
import * as tUtil from '@/utils/tUtil'
import * as UserLocal from '@/utils/userLocal'
import * as ElIcons from '@element-plus/icons-vue'
import {components} from "@/views/index";
import {tools} from "@/tools/index";

const app = createApp(App);
app.use(store);
app.use(router);
tools(app);
const tabNameMap = components(app);
app.config.globalProperties.$TabNameMap = tabNameMap;
for (const [key, component] of Object.entries(ElIcons)) {
    app.component(key, component)
}

app.use(ElementPlus, {locale, size: 'small'});
app.mount('#app');
app.config.globalProperties.$Cookie = Cookie;
app.config.globalProperties.$TdDate = tdDate;
app.config.globalProperties.$TUtil = tUtil;
app.config.globalProperties.$icon = ElIcons;
app.config.globalProperties.$UserLocal = UserLocal;
