<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 74px;" class="flex-row justify-between">
                <div style="width: 80%;" class="flex-row">
                    <el-input v-model="search" placeholder="请输入登录名"
                              class="search-input search-first"
                              clearable></el-input>
                    <el-input v-show="this.$UserLocal.isRole('allMemberMgt')" v-model="source" placeholder="请输入会员来源"
                              class="search-input search-first"
                              clearable></el-input>
                </div>
                <div style="width: 20%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      :data="tableData"
                      v-if="tableData"
                      border
                      stripe table-layout="auto"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="memberNo" label="会员编号"/>
                <el-table-column prop="mobile" label="登录名"/>
                <el-table-column prop="roleName" label="权限"/>
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-popconfirm :title="scope.row.state===1?'您确定启用吗？':'您确定要冻结吗？'"
                                       @confirm="handlerState(scope.row.memberNo,scope.row.state===1?0:1)">
                            <template #reference>
                                <el-button class="link_text_no" v-if="scope.row.state===1" link size="small">已冻结
                                </el-button>
                                <el-button class="link_text" v-else link size="small">已启用
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="注册时间">
                    <template #default="scope">
                        {{ scope.row ? $TdDate.df(scope.row.createTime, 'YYYY-MM-DD HH:mm:ss') : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="source" label="会员来源">
                    <template #default="scope">
                        {{ scope.row ? scope.row.sourceMobile : '' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="200px" label="操作">
                    <template #default="scope">
                        <el-button @click="handleEdit(scope.row)" link size="small" type="danger">编辑</el-button>
                        <el-button @click="getPwd(scope.row.memberNo)" link size="small" type="danger">重置密码</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="提示" v-model="dialogVisible" width="30%">
                <el-form ref="form" :model="form" label-width="22%" :rules="rules">
                    <el-form-item label="登录名" prop="mobile">
                        <el-input :disabled="!isAdd" v-model="form.mobile" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="form.sex">
                            <el-radio label="男" size="large">男</el-radio>
                            <el-radio label="女" size="large">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-show="this.$UserLocal.isRole('role')" label="角色" prop="roleId">
                        <el-select v-model="form.roleId"
                                   filterable
                                   clearable
                                   reserve-keyword
                                   placeholder="请选择角色">
                            <el-option
                                v-for="item in roleOptions"
                                :key="item.id"
                                :label="item.roleName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="会员来源" prop="source">
                        <Tools_SelectMember :initMemberNo="form.source"
                                            :initMemberOptions="initMemberOptions"
                                            @getValue="(value)=>form.source=value"
                        />
                    </el-form-item>
                    <span v-show="isAdd" style="color: brown;font-size: 14px;margin-left: 68px">*默认密码是登录名后六位</span>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
            <!--弹窗-->
            <el-dialog title="重置密码" v-model="pwdDialogVisible" width="30%">
                <el-form ref="pwdForm" :model="pwdForm" label-width="22%" :rules="pwdRules">
                    <el-form-item label="密码" prop="pwd">
                        <div class="flex-row">
                            <el-input v-model="pwdForm.pwd"></el-input>
                            <el-button link size="small" type="primary" @click="copyArea(pwdForm.pwd)"
                                       style="margin-left: 14px;">
                                <el-tooltip content="复制新密码" placement="top-start">
                                    <el-icon :size="20">
                                        <CopyDocument/>
                                    </el-icon>
                                </el-tooltip>
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <span style="color: brown;font-size: 14px;margin-left: 24px">*点击确定前务必复制好新密码，确定后消失</span>
                        <div>
                            <el-button @click="pwdDialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="resetPwd">确 定</el-button>
                        </div>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>

</template>

<script>
import {postData} from "@/utils/httpReq";
import {validName3} from "@/utils/validate";

export default {
    name: 'MemberMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            search: '',
            source: '',
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            roleOptions: [],
            isAdd: false,
            rules: {
                mobile: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    {validator: validName3, trigger: 'blur'},
                ],
                sex: [
                    {required: true, message: '请选择性别', trigger: 'blur'},
                ]
            },
            user: {},
            loading: true,
            pwdForm: {},
            pwdDialogVisible: false,
            pwdRules: {
                pwd: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    {validator: validName3, trigger: 'blur'},
                ],
            },
            initMemberOptions: [],
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        if (this.params && this.params.load) {
            this.load();
        }
        this.user = this.$UserLocal.getUser() || {};
    },
    methods: {
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 140; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        load() {
            postData("/api/mgt/user/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                search: this.search,
                source: this.source
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            });
            this.roleList();
        },
        roleList() {
            postData("/api/mgt/role/findAll", {}).then(res => {
                if (res.code === "000000") {
                    this.roleOptions = res.data;
                }
            })
        },
        add() {
            this.dialogVisible = true;
            this.form = {sex: '男'};
            this.isAdd = true;
        },
        getPwd(memberNo) {
            this.pwdForm = {memberNo: memberNo};
            postData("/api/mgt/user/getPwdRandom", {}).then(res => {
                if (res.code === "000000") {
                    this.pwdForm.pwd = res.data;
                    this.pwdDialogVisible = true;
                }
            });
        },
        copyArea(e) {
            var input = document.createElement("input"); // 直接构建input
            input.value = e; // 设置内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message({message: '复制成功', type: 'success'}) //复制成功后提示
        },
        resetPwd() {
            this.$refs['pwdForm'].validate((valid) => {
                if (valid) {
                    postData("/api/mgt/user/reset", this.pwdForm).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                        this.pwdDialogVisible = false;
                    });
                }
            });
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    postData("/api/mgt/user/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                            this.load();//刷新表格数据
                            this.dialogVisible = false;//关闭弹窗
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                    });
                }
            });
        },
        handleEdit(row) {
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
            this.isAdd = false;
            if (this.form.source) {
                this.initMemberOptions = [{
                    memberNo: this.form.source,
                    mobile: this.form.sourceMobile
                }];
            }
        },
        handlerState(memberNo, state) {
            postData("/api/mgt/user/state", {
                memberNo: memberNo,
                state: state
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
    }
}
</script>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
