<template>
    <div class="flex-col" style="width: 100%;">
        <div class="headerTitle flex-row justify-between"
             style="background: linear-gradient(to bottom, #b7b3b3, #bed5fa);">
            <div class="bothSides"/>
            <div class="middleMain flex-row justify-between align-center">
                <div style="width: 18%;height: 54px" class="flex-row align-center">
                    <img src="../assets/img2/pic_logo.png" style="width:171px;height: 54px;"/>
                </div>
                <div style="width: 82%;" class="flex-row justify-end align-center">
                    <p class="hotTep-txt">24小时服务热线:</p>
                    <p class="hotTep">19965433320</p>
                    <el-button link @click="$router.push({path:'/home'})" class="returnHome">返回首页</el-button>
                    <el-button link @click="$router.push('/about')" class="returnHome">联系我们</el-button>
                    <el-button @click="$router.push({path:'/login'})" class="loginButton"><p
                        class="loginButton-text align-center">账号登录</p>
                    </el-button>
                </div>
            </div>
            <div class="bothSides"/>
        </div>
        <div class="headerMenu flex-row justify-between">
            <div class="bothSides"/>
            <div class="middleMain flex-row justify-between align-center">
                <el-menu :default-active="$route.path"
                         class="flex-row " style="width: 75%"
                         @select="handleSelect">
                    <template v-for="plate in plates" :key="plate.path">
                        <el-menu-item :index="plate.path"
                                      :class="plate.children&&plate.children.length>0?'hasChild':'noChild'">
                            <el-dropdown v-if="plate.children&&plate.children.length>0" trigger="click" @click.stop>
                                <span class="el-dropdown-link flex-col" style="margin-top: 5px;font-size: 19px;color: #2b2b2c">
                                <p>{{ plate.plateName }}</p>
                                <el-icon class="el-icon--right" style="margin-top: -15px;margin-left: 10px">
                                    <arrow-down/>
                                </el-icon>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item v-for="cPlate in plate.children" :key="cPlate.path"
                                                          style="height:40px;width: 130px;font-size: 17px;color: #2b2b2c"
                                                          @click="selectPlate(cPlate.path)">
                                            {{ cPlate.plateName }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <span v-else style="font-size: 19px;color: #2b2b2c">{{ plate.plateName }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
                <div class="flex-row menu_right justify-end align-center" style="width: 25%">
                    <span v-if="user.mobile" class="flex-row">
                        <p style="margin-right: 10px;color: #494848;font-size: 19px"> {{ user.mobile }}</p>
                        <el-button @click="exit" link style="margin-right: 2px;font-size: 20px">退出 </el-button>
                        <p style="margin-right: 2px;font-size: 20px">|</p><el-button @click="$router.push('/about')"
                                                                                     link
                                                                                     style="font-size: 20px">关于我们</el-button>
                    </span>
                    <span v-else class="flex-row justify-end align-center">
                        <el-button @click="$router.push({path:'/login'})" link
                                   style="margin-right: 2px;font-size: 20px">登录</el-button>
                        <p style="margin-right: 2px;">|</p><el-button @click="$router.push('/about')"
                                                                      link style="font-size: 20px">关于我们</el-button>
                    </span>
                </div>
            </div>
            <div class="bothSides"/>
        </div>
    </div>
</template>

<script>

import {postData} from "@/utils/httpReq";

export default {
    name: "Header",
    emits: ["toLoadHeader"],
    props: {
        initLoad: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            user: {},
            load: 0,
            plates: [
                {
                    id: "0",
                    plateName: '首页',
                    path: "/home"
                }
            ],
            defaultActive: "0",
            plateMap: new Map(),
        }
    },
    watch: {
        initLoad: {
            handler(val, oldval) {
                this.load = this.initLoad;   // 初始值
                this.user = this.$UserLocal.getUser() || {};
                this.initPlate();
            },
        },
    },
    created() {
        this.user = this.$UserLocal.getUser() || {};
        this.initPlate();
    },
    methods: {
        initPlate() {
            var home = {id: "0", plateName: '首页', path: "/home"}
            this.plates = [home];
            this.plateMap.set('/home', home);
            postData("/api/home/plate/list", {}).then(res => {
                if (res.code === "000000") {
                    res.data.map(item => {
                        var data = {
                            id: item.id + '',
                            plateName: item.plateName,
                            path: "/plate/" + item.id,
                            children: []
                        }
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                var dataChild = {
                                    id: child.id + '',
                                    plateName: child.plateName,
                                    path: "/plate/" + child.id,
                                }
                                data.children.push(dataChild);
                            });
                        }
                        this.plateMap.set(data.path, data);
                        this.plates.push(data)
                    });
                    if (this.user && this.user.roleId) {
                        var data = {id: '-1', plateName: '管理中心', path: "/management"};
                        this.plates.push(data)
                        this.plateMap.set('/management', data);
                    }
                }
            });
        },
        handleSelect(key, path) {
            var plate = this.plateMap.get(key);
            if (!plate || !plate.children || plate.children.length === 0) {
                if (plate.id === "0") {
                    this.$router.push("/home");
                } else if (plate.id === "-1") {
                    this.$router.push("/management");
                } else {
                    this.selectPlate(key);
                }
            }
        },
        selectPlate(key) {
            this.$router.push(key);
        },
        login() {
            this.$router.push("/login");
        },
        exit() {
            this.$UserLocal.delUser();
            this.$emit("toLoadHeader");
            this.$router.push("/");
        },
    }
}
</script>

<style scoped>
::v-deep(.el-menu-item) {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #111112;
    line-height: 24px;
}

::v-deep(.el-menu) {
    border-right: solid 0;
    background-color: transparent;
}

.noChild.is-active {
    border-bottom: 2px solid #409eff;
}

::v-deep(.el-dropdown) {
    color: #303133;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    color: #111112;
    line-height: 24px;
}

</style>
<style scoped lang="css" src="../assets/css/header.css"/>
