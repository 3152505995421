<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 74px;" class="flex-row justify-between">
                <div style="width: 75%;" class="flex-row">
                    <el-input v-model="search" placeholder="搜索章节名称"
                              class="search-input search-first"
                              clearable></el-input>
                    <Tools_Tree :title="'请选择栏目'"
                                class="search-input search-first"
                                :options="this.plateOptions"
                                :props="defaultProps"
                                :multiple="false"
                                :clearable="true"
                                @getValue="(value)=>value?this.plateId = value.id:''"/>
                    <el-select v-model="state" placeholder="状态"
                               class="search-input search-first" clearable>
                        <el-option
                            v-for="item in stateList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </div>
                <div style="width: 25%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      v-if="tableData"
                      :data="tableData"
                      border
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="id" label="编号"/>
                <el-table-column prop="chapterName" label="名称"/>
                <el-table-column prop="plateId" label="栏目">
                    <template #default="scope">
                        {{ scope.row ? this.pinjiePlateName(scope.row.plateId) : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="questionCount" label="题数" width="180px">
                    <template #default="scope">
                        {{
                            scope.row && scope.row.questionCount ? '总-' + scope.row.questionCount +
                                '; 判-' + scope.row.truefalseCount +
                                '; 单-' + scope.row.singleChoiceCount +
                                '; 多-' + scope.row.multipleChoiceCount : ''
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序值"/>
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-popconfirm :title="scope.row.state===1?'您确定启用吗？':'您确定要暂停吗？'"
                                       @confirm="handlerState(scope.row.id,scope.row.plateId,scope.row.state===1?0:1)">
                            <template #reference>
                                <el-button class="link_text_no" v-if="scope.row.state===1" link size="small">已暂停
                                </el-button>
                                <el-button class="link_text" v-else link size="small">已启用
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column prop="synTime" label="同步时间">
                    <template #default="scope">
                        {{ scope.row ? $TdDate.df(scope.row.synTime, 'YYYY-MM-DD HH:mm:ss') : '' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="240px" label="操作">
                    <template #default="scope">
                        <el-button @click="handleEdit(scope.row)" link size="small" type="primary">编辑</el-button>
                        <el-button @click="startSyn(scope.row)" link size="small" type="primary">发起同步</el-button>
                        <el-button @click="handleQuestion(scope.row)" link size="small" type="primary">题目顺序</el-button>
                        <el-popconfirm v-if="scope.row&&scope.row.id" title="确定删除吗？"
                                       @confirm="handleDelete(scope.row.id,scope.row.plateId)">
                            <template #reference>
                                <el-button link size="small" type="danger">
                                    删除
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="提示" v-model="dialogVisible" width="30%">
                <el-form ref="form" :model="form" label-width="22%" :rules="rules">
                    <el-form-item label="栏目" prop="plateId">
                        <Tools_Tree :options="this.plateOptions"
                                    :props="defaultProps"
                                    :multiple="false"
                                    :value="form.plate"
                                    :clearable="true"
                                    @getValue="(value)=>this.form.plateId = value.id"/>
                    </el-form-item>
                    <el-form-item label="章节名称" prop="chapterName">
                        <el-input v-model="form.chapterName" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="排序值" prop="sort">
                        <el-input-number v-model="form.sort" :min="0"></el-input-number>
                    </el-form-item>
                    <el-form-item label="同步ID" prop="synId">
                        <el-input v-model="form.synId" clearable></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
            <el-dialog title="题目排序" v-model="sortDialogVisible" width="50%">
                <el-button type="primary" style="margin-bottom: 20px" @click="questionSort">按题型排序</el-button>
                <el-table :data="sortList"
                          v-if="sortList"
                          :header-cell-style="{background: 'rgba(248, 249, 252, 1)','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}" border stripe table-layout="auto">
                    <el-table-column label="ID" prop="id" width="55"/>
                    <el-table-column prop="title" label="题目">
                        <template #default="scope">
                            {{
                                scope.row ? '【' + this.questionTypeMap.get(scope.row.questionType) + '】' + scope.row.title + ' '
                                    + (scope.row.optionA + '.' + scope.row.optionAContent + ' ')
                                    + (scope.row.optionB + '.' + scope.row.optionBContent + ' ')
                                    + (scope.row.optionC && scope.row.optionCContent ? scope.row.optionC + '.' + scope.row.optionCContent + ' ' : '')
                                    + (scope.row.optionD && scope.row.optionDContent ? scope.row.optionD + '.' + scope.row.optionDContent : '') : ''
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200px">
                        <template #default="scope">
                            <el-button :disabled="scope.$index === 0" link size="small" type="primary"
                                       @click="handleUp(scope.$index)">上移
                            </el-button>
                            <el-button :disabled="scope.$index === 0" link size="small"
                                       type="primary"
                                       @click="handleUpHeader(scope.$index)">置顶
                            </el-button>
                            <el-button :disabled="scope.$index === sortList.length - 1"
                                       link size="small"
                                       type="primary" @click="handleDown(scope.$index)">下移
                            </el-button>
                            <el-button :disabled="scope.$index === sortList.length - 1"
                                       link size="small"
                                       type="primary" @click="handleDownFooter(scope.$index)">置底
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <template #footer>
                    <span class="dialog-footer">
                      <!--可编辑或者不可编辑且审核通过已上链状态-->
                        <el-button @click="sortDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="setSort">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";
import {validDecimal82, validName} from "@/utils/validate";

export default {
    name: 'ChapterMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            search: '',
            plateId: '',
            state: '',
            stateList: [
                {
                    value: 0,
                    label: '启用',
                },
                {
                    value: 1,
                    label: '暂停',
                }
            ],
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
            rules: {
                chapterName: [
                    {required: true, message: '请输入栏目名称', trigger: 'blur'},
                    {validator: validName, trigger: 'blur'},
                ],
                plateId: [
                    {required: true, message: '请选择所属栏目', trigger: 'blur'},
                ],
                sort: [
                    {required: true, message: '排序值不能为空', trigger: 'blur'},
                    {validator: validDecimal82, trigger: 'blur'},
                ]
            },
            loading: true,
            plateOptions: [],
            plateMap: new Map(),

            sortDialogVisible: false,
            sortList: [],
            sortChapterId: '',
            questionTypeList: [//0-判断题,1-单选题,2-多选题
                {
                    value: 0,
                    label: '判断题',
                },
                {
                    value: 1,
                    label: '单选题',
                },
                {
                    value: 2,
                    label: '多选题',
                }
            ],
            questionTypeMap: new Map(),
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        this.plateTree();
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 138; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        load() {
            postData("/api/mgt/chapter/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                search: this.search,
                plateId: this.plateId,
                state: this.state
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        plateTree() {
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.plateOptions = res.data;
                    res.data.map(item => {
                        this.plateMap.set(item.id, item);
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                this.plateMap.set(child.id, child);
                            });
                        }
                    });
                }
            });
            this.questionTypeList.map(type => {
                this.questionTypeMap.set(type.value, type.label);
            });
        },
        pinjiePlateName(plateId) {
            var child = this.plateMap.get(plateId);
            if (!child) return "";
            if (child.parentId === 0) return child.plateName;
            var parentName = this.pinjiePlateName(child.parentId);
            return parentName + '>>' + child.plateName;
        },
        add() {
            this.dialogVisible = true;
            this.form = {sort: 0};
            this.form.plate = {};
        },
        handleEdit(row) {
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
            this.form.plate = this.plateMap.get(row.plateId);
        },
        handleDelete(id, plateId) {
            postData("/api/mgt/chapter/delete", {
                id: id,
                plateId: plateId
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        handlerState(id, plateId, state) {
            postData("/api/mgt/chapter/state", {
                id: id,
                plateId: plateId,
                state: state
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    postData("/api/mgt/chapter/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                        this.load();//刷新表格数据
                        this.dialogVisible = false;//关闭弹窗
                    });
                }
            });
        },
        handleQuestion(row) {
            postData("/api/mgt/question/findByChapterId", {
                chapterId: row.id
            }).then(res => {
                this.sortDialogVisible = true;
                this.sortList = res.data;
                this.sortChapterId = row.id;
            })
        },
        questionSort() {
            var that = this;
            this.sortList = that.sortList.sort((a, b) => a.questionType - b.questionType);
        },
        handleUp(index) {
            var that = this;
            let upDate = that.sortList[index - 1];
            that.sortList.splice(index - 1, 1);
            that.sortList.splice(index, 0, upDate);
        },
        handleUpHeader(index) {
            var that = this;
            let upDate = that.sortList[index];
            that.sortList.splice(index, 1);
            that.sortList.unshift(upDate);
        },
        handleDown(index) {
            var that = this;
            let downDate = that.sortList[index + 1];
            that.sortList.splice(index + 1, 1);
            that.sortList.splice(index, 0, downDate);
        },
        handleDownFooter(index) {
            var that = this;
            let downDate = that.sortList[index];
            that.sortList.splice(index, 1);
            that.sortList.push(downDate);
        },
        setSort() {
            var qIds = this.sortList.map(c => c.id).join(",");
            postData("/api/mgt/question/setSort", {
                questionIds: qIds,
                chapterId: this.sortChapterId
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                    this.load();
                    this.sortDialogVisible = false;//关闭弹窗
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        startSyn(row) {
            postData("/api/mgt/synJob/start", {
                chapterId: row.id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
    }
}
</script>
<style scoped>
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
