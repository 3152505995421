module.exports = {
    user_expire: '1200MIN',
    icon_list: [
        {name: '默认', menu: 'system.png'},
        {name: '', menu: 'home.png'},
        {name: '考试', menu: 'exercise.png'},
        {name: '栏目', menu: 'column.png'},
        {name: '章节', menu: 'chapter.png'},
    ],
    /**
     * 是否显示设置的底部信息
     */
    showFooter: true,
    /**
     * 底部文字，支持html语法
     */
    footerTxt: 'Copyright©2022@ Principal Ran Infomation Consultation Center',
    /**
     * 备案号
     */
    caseNumber: 'ICP备***号',
    /**
     * 标题
     */
    title: '然校长交规',
}
