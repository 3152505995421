import {createRouter, createWebHistory} from 'vue-router'
import Layout from '../layout/Layout.vue'
import Video from '@/views/Video' // 引入Video组件

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import("@/views/Home"),
            }, {
                path: '/management',
                name: 'Management',
                component: () => import("@/views/Management"),
            }, {
                path: '/plate/:plateId',
                name: 'Plate',
                component: () => import("@/views/Plate"),
            }, {
                path: '/chapterExercise/:plateId/:chapterId/:queryType',
                name: 'ChapterExercise',
                component: () => import("@/views/ChapterExercise"),
            }, {
                path: '/exam/:plateId/:temId',
                name: 'Exam',
                component: () => import("@/views/Exam"),
            }, {
                path: '/login',
                name: 'Login',
                component: () => import("@/views/Login")
            }, {
                path: '/about',
                name: 'About',
                component: () => import("@/views/About")
            }, {
                path: '/plate/9',
                name: 'Video',
                component: () => import("@/views/Video")
            },{
                path: '/video1',
                name: 'Video1',
                component: () => import("@/views/Video1")
            },{
                path: '/video2',
                name: 'Video2',
                component: () => import("@/views/Video2")
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
