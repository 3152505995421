<template>
    <div class="flex-col">
        <div class="flex-col search">
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      ref="table"
                      height="724"
                      v-if="tableData"
                      :data="tableData"
                      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}" row-key="id">
                <el-table-column label="ID" prop="id" type=""/>
                <el-table-column :show-overflow-tooltip="true" label="栏目名称" width="160px" prop="plateName"/>
                <el-table-column prop="sort" align="center" label="排序值"/>
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-popconfirm :title="scope.row.state===1?'您确定启用吗？':'您确定要暂停吗？'"
                                       @confirm="handlerState(scope.row.id,scope.row.state===1?0:1)">
                            <template #reference>
                                <el-button class="link_text_no" v-if="scope.row.state===1" link size="small">已暂停
                                </el-button>
                                <el-button class="link_text" v-else link size="small">已启用
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="200px" label="操作">
                    <template #default="scope">
                        <el-button @click="edit(scope.row)" link size="small" type="primary">编辑</el-button>
                        <el-popconfirm
                            title="确定删除吗？"
                            @confirm="handleDelete(scope.row.id)">
                            <template #reference>
                                <el-button type="danger" link size="small">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!--弹窗-->
            <el-dialog title="提示" v-model="dialogVisible" width="30%">
                <el-form ref="form" :model="form" label-width="22%" :rules="rules">
                    <el-form-item label="栏目名称" prop="plateName">
                        <el-input v-model="form.plateName" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="排序值" prop="sort">
                        <el-input-number v-model="form.sort" :min="0"></el-input-number>
                    </el-form-item>
                    <el-form-item label="上级栏目" prop="parentId">
                        <Tools_Tree :options="this.plateOptions"
                                    :props="defaultProps"
                                    :multiple="false"
                                    :value="form.parentPlate"
                                    :clearable="true"
                                    @getValue="(value)=>this.form.parentId = value.id"/>
                    </el-form-item>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";
import {validDecimal82, validName} from "@/utils/validate";

export default {
    name: 'plateMgt',
    components: {},
    props: ['params'],
    data() {
        return {
            tableData: [],
            form: {sort: 0, parentId: 0},
            dialogVisible: false,
            plateOptions: [],
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
            rules: {
                plateName: [
                    {required: true, message: '请输入栏目名称', trigger: 'blur'},
                    {validator: validName, trigger: 'blur'},
                ],
                parentId: [
                    {required: true, message: '请选择父级栏目', trigger: 'blur'},
                ],
                sort: [
                    {required: true, message: '排序值不能为空', trigger: 'blur'},
                    {validator: validDecimal82, trigger: 'blur'},
                ]
            },
            loading: true,
            plateMap: new Map().set(0, "根栏目"),
        }
    },
    created() {
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        load() {
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data;
                    this.loadPlateMap(res.data)
                    this.loading = false;
                    this.plateOptions = [];
                    this.plateOptions.push({id: 0, menuName: '根栏目'});
                    res.data.map(item => {
                        var data = {};
                        Object.assign(data, item);
                        data.children = null;//暂时只支持到二级
                        this.plateOptions.push(data);
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            });
        },
        loadPlateMap(menus) {
            menus.map(menu => {
                this.plateMap.set(menu.id, menu.menuName);
                if (menu.children) {
                    this.loadPlateMap(menu.children);
                }
            });
        },
        edit(row) {
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
            this.form.parentPlate = {
                id: this.form.parentId,
                plateName: this.plateMap.get(this.form.parentId)
            };
        },
        add() {
            this.form = {parentId: 0, plateName: null, sort: 0};
            this.dialogVisible = true;
            this.form.parentPlate = {
                id: 0,
                plateName: this.plateMap.get(0)
            };
        },
        handlerState(id, state) {
            postData("/api/mgt/plate/state", {
                id: id,
                state: state
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        handleDelete(id) {
            postData("/api/mgt/plate/delete", {
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        save() {
            this.form.parentId = this.form.parentId || 0;
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.id && this.form.parentId === this.form.id) {
                        this.$message({
                            type: "error",
                            message: "上级栏目不可与当前栏目一样！"
                        })
                        return;
                    }
                    postData("/api/mgt/plate/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                        this.load();//刷新表格数据
                        this.dialogVisible = false;//关闭弹窗
                    });
                }
            });
        }
    }
}
</script>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
