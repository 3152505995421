<template>
    <div class="video-container">
        <video ref="videoPlayer" :src="videoSource" controls autoplay playsinline controlsList="nodownload"></video>
    </div>
</template>

<script>
export default {
    name: 'VideoPlayer',
    props: {
        videoSource: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 保持16:9的比例 */
    overflow: hidden;
}

.video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* 调整为所需的比例，例如80% */
    height: auto; /* 自动计算高度以保持视频宽高比 */
    object-fit: cover;
}
</style>
