import Cookie from 'vue-cookies';
import Config from '@/config';

export function setLogin(login) {
    if (login && login.rememberPwd) {
        Cookie.set("login", JSON.stringify(login), '1m');
    } else {
        Cookie.remove("login");
    }
}

export function getLogin() {
    return Cookie.get("login");
}

export function setUser(user) {
    Cookie.set("tp_user", JSON.stringify(user), Config.user_expire);
}

export function getUser() {
    var user = Cookie.get("tp_user");
    var isNotNull = (user !== {} && user !== undefined && user !== null
        && user.id !== null && user.id !== "" && user.id !== undefined);
    return isNotNull ? user : null;
}

export function delUser() {
    return Cookie.remove("tp_user");
}

export function isRole(menusTag) {
    var user = getUser();
    if (!user) return false;
    if (user.sysRoleId === 0) return true;
    var menuTags = user.menuTags;
    return menuTags.findIndex(tag => tag === menusTag) >= 0;
}


export function isNoUser(user) {
    return user !== {} && user !== undefined && user !== null
        && user.id !== null && user.id !== "" && user.id !== undefined;
}
