<template>
    <div id="app">
        <!--    根据路由进行访问-->
        <router-view/>

    </div>

</template>

<script>

    export default {
        name: "App",
        // components: {
        //   Header,
        //   Aside
        // }
    }
</script>
