<template>
    <div class="flex-row justify-center" style="width: 100%;background: linear-gradient(to bottom, #d6e4e8, #dcdbdb);"
         @contextmenu.prevent="disableRightClick">
        <div v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.4)"
             class="flex-col" style="width: 88%;margin-left: 45px;margin-right: 45px">
            <!--考试名区域-->
            <div class="flex-row justify-center" style="margin-top: 32px;margin-bottom: 24px">
                <span class="plate-title" v-show="this.examData.examName">
                    {{ this.examData.examName }}
                </span>
            </div>
            <div class="flex-col" style="height: 680px;margin-bottom: 57px;border: 1px solid #545456">
                <div style="height: 50%;width: 85%;" class="flex-row justify-between">
                    <div style="width: 85%;border-right: 1px solid #545456" class="flex-col">
                        <div style="flex: 2" class="flex-row">
                            <!--考生信息区域-->
                            <div
                                style="width: 16%;height: 100%;border-right: 1px solid #545456;display: inline-block; text-align: center;"
                                class="flex-col justify-between">
                                <div style="flex:1" class="flex-col">
                                    <span class="left-title">然校长交规理论考场</span>
                                    <span class="left-title justify-end"
                                          style="display: inline-block; text-align: center;margin-top: 8px;margin-right: 16px">第100考台</span>
                                    <el-divider style="height: 1px;margin:5px 0"/>
                                    <span class="left-title">考生信息</span>
                                </div>
                                <div style="flex:4;" class="flex-col ">
                                    <div class="flex-row align-center" style="height: 24px;margin-left: 10px">
                                        <p style="margin-right: 8px;font-size: 14px">姓名</p>
                                        <input class="left-input" v-model="this.user.mobile" disabled/>
                                    </div>
                                    <div class="flex-row align-center" style="height: 24px;margin-left: 10px">
                                        <p style="margin-right: 8px;font-size: 14px">性别</p>
                                        <input class="left-input" v-model="this.user.sex" disabled/>
                                    </div>
                                    <div class="flex-row align-center" style="height: 24px;margin-left: 10px">
                                        <p style="margin-right: 8px;font-size: 14px">车型</p>
                                        <input class="left-input" v-model="this.examData.vehicle" disabled/>
                                    </div>
                                    <div class="flex-row align-center" style="height: 24px;margin-left: 10px">
                                        <p style="margin-right: 8px;font-size: 14px">科目</p>
                                        <input class="left-input" v-model="this.examData.subjectName" disabled/>
                                    </div>
                                </div>
                            </div>
                            <!--题目详情区域-->
                            <div style="width: 80%" class="flex-col question-title">
                                <div v-show="this.question.title"
                                     style="margin-bottom: 10px;margin-left: 5px">
                                    {{
                                        (this.qIndex + 1) + '.' + this.question.title
                                    }}
                                </div>
                                <div v-if="this.question.questionType===1||this.question.questionType===2"
                                     class="flex-col justify-between" style="height: 370px;margin-left: 5px">
                                    <div class="flex-col">
                                        <span>{{ this.question.optionA + '.' + this.question.optionAContent }}</span>
                                        <span>{{ this.question.optionB + '.' + this.question.optionBContent }}</span>
                                        <span>{{ this.question.optionC + '.' + this.question.optionCContent }}</span>
                                        <span>{{ this.question.optionD + '.' + this.question.optionDContent }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--答题区域-->
                        <div style="flex: 1;border-top: 1px solid #545456" class="flex-row">
                            <div style="flex:1" class="flex-col justify-center">
                                <span style="margin-left: 10px;font-weight: bold">剩余时间:</span>
                                <Tools_Countdown style="margin-left: 10px;font-weight: bold"
                                                 :duration="this.examData.duration*60*1000"
                                                 :start-time="this.startTime" @toEnd="(value)=>isEnd=value"/>
                            </div>
                            <div style="flex:4" class="flex-row justify-between">
                                <p class="answer-select">
                                    您选择的答案:
                                    <span v-if="Array.isArray(this.question.mSources)">
                                        {{ this.question.mSources.join(', ') }}
                                    </span>
                                    <span v-else>
                                        {{ this.question.mSource }}
                                     </span>
                                </p>

                                <div class="flex-row align-center ">
                                    <p class="question-select">选 项:</p>
                                    <div class="true-false-question" v-if="this.question.questionType===0">
                                        <el-radio-group v-model="this.question.mSource"
                                                        size="large" :disabled="isEnd"
                                                        style="margin-right: 5px">
                                            <el-radio-button :label="this.question.optionAContent" class="large-font">
                                                {{ this.question.optionAContent }}
                                            </el-radio-button>
                                            <el-radio-button :label="this.question.optionBContent"
                                                             style="margin-left: 8px;">
                                                {{ this.question.optionBContent }}
                                            </el-radio-button>
                                        </el-radio-group>
                                    </div>
                                    <div v-else-if="this.question.questionType===1" class="singleSelect"
                                         style="margin-right: 8px">
                                        <el-radio-group v-model="this.question.mSource"
                                                        size="large" :disabled="isEnd"
                                                        class="flex-row align-center">
                                            <el-radio :label="this.question.optionA"></el-radio>
                                            <el-radio :label="this.question.optionB"></el-radio>
                                            <el-radio :label="this.question.optionC"></el-radio>
                                            <el-radio :label="this.question.optionD"></el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div v-else class="singleSelect">
                                        <el-checkbox-group v-model="this.question.mSources"
                                                           size="large" :disabled="isEnd"
                                                           class="flex-row align-center">
                                            <el-checkbox :label="this.question.optionA"></el-checkbox>
                                            <el-checkbox :label="this.question.optionB"></el-checkbox>
                                            <el-checkbox :label="this.question.optionC"></el-checkbox>
                                            <el-checkbox :label="this.question.optionD"></el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--题目列表区域-->
                    <div style="width: 32%;height: 100%;">
                        <div class="flex-row justify-start align-start"
                             style="width:450px;height:340px;background-color: #ffffff;margin-left: 1px">
                            <div style="width: 40px;height: 340px" class="flex-col align-end">
                                <div v-for="index in 11">
                                    <el-button class="question-select-button-aside">
                                        {{ index === 1 ? '题号' : (index - 1) + '行' }}
                                    </el-button>
                                </div>
                            </div>
                            <div class="flex-col" style="width: 410px;height: 340px">
                                <div style="height: 30px;width: 410px" class="flex-row align-end">
                                    <div v-for="index in 10">
                                        <el-button class="question-select-button-aside">
                                            {{ index + '列' }}
                                        </el-button>
                                    </div>
                                </div>
                                <div class="flex-row question-list " style="height: 310px;width: 410px">
                                    <div v-for="(q,index) in this.examData.questionList">
                                        <el-button :class="getQuestClass(q,index)" @click="getQuestion(q,index)"
                                                   :plain="index!==this.qIndex">
                                            <div class="flex-col justify-center">
                                                <span style="height: 12px">{{ index + 1 }}</span>
                                                <span v-if="this.memberSelect.get(q)" style="height: 12px">
                                                    {{ this.memberSelect.get(q).qa }}
                                                </span>
                                            </div>
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 50%;width: 100%;">
                    <!--试卷提交区域-->
                    <div class="flex-row justify-between"
                         style="height: 30%;border-top: 1px solid #545456">
                        <div v-if="this.question.questionType===0" class="flex-col"
                             style="margin-top:5px;margin-left: 10px">
                            <span style="color: brown">操作提示: 判断题</span>
                            <span>请判断对错</span>
                        </div>
                        <div v-else-if="this.question.questionType===1" class="flex-col"
                             style="margin-top:5px;margin-left: 10px">
                            <span style="color: brown">操作提示: 单选题</span>
                            <span>请在备选答案中选择你认为正确的答案！</span>
                        </div>
                        <div v-else class="flex-col" style="margin-top:5px;margin-left: 24px">
                            <span style="color: brown">操作提示: 多选题</span>
                            <span>请在备选答案中选择你认为正确的答案！</span>
                        </div>
                        <div class="flex-row align-center">
                            <el-button class="question-select-button" @click="lastQuestion" :disabled="this.qIndex===0">
                                <p class="add-button-text"> 上一题 </p>
                            </el-button>
                            <el-button v-if="this.examData&&this.examData.questionList" class="question-select-button"
                                       @click="nextQuestion"
                                       :disabled="this.qIndex+1===this.examData.questionList.length">
                                <p class="add-button-text"> 下一题 </p>
                            </el-button>
                            <el-button class="question-select-button" :disabled="isEnd"
                                       style="background-color: #0ac03e;margin-right: 10px"
                                       @click="commitExam">
                                <p class="add-button-text"> 提交试卷 </p>
                            </el-button>
                        </div>
                    </div>
                    <!--图片区域-->
                    <div class="flex-row justify-center align-center" style="height: 67%;border-top: 1px solid #545456">
                        <img style="height: 90%" v-show="this.question.annex" :src="this.question.annex"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: 'Exam',
    data() {
        return {
            user: {},
            plateId: '',
            temId: '',
            qIndex: 0,
            question: {},
            examData: {},
            memberSelect: new Map(),
            loading: true,
            startTime: Date.now(),
            examResult: 0,
            isEnd: false,
        }
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                if (this.$route.params && this.$route.params.temId) {
                    this.temId = this.$route.params.temId;
                    this.plateId = this.$route.params.plateId;
                    this.initExam();
                    this.user = this.$UserLocal.getUser() || {};
                }
            }
        },
    },
    created() {
        if (this.$route.params && this.$route.params.temId) {
            this.temId = this.$route.params.temId;
            this.plateId = this.$route.params.plateId;
            this.initExam();
        }
        this.user = this.$UserLocal.getUser() || {};
    },
    methods: {
        initExam() {
            this.loading = true;
            postData("/api/home/plate/inputExam", {
                temId: this.temId
            }).then(res => {
                if (res.code === "000000") {
                    this.examData = res.data;
                    this.question = this.examData.firstQuestionVo;
                    this.qIndex = 0;
                    this.memberSelect = new Map();
                    this.loading = false;
                    this.startTime = Date.now();
                    this.isEnd = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                    if (res.code !== '100004') {
                        this.$router.push("/plate/" + this.plateId);
                    }
                }
            })
        },
        selectMSource() {
            if (this.memberSelect.get(this.question.id) || this.isEnd) {
                return;
            }
            if (this.question.questionType === 2 && this.question.mSources && this.question.mSources.length > 0) {
                var mSources = this.question.mSources;
                mSources.sort();
                var mSource = mSources.join("");
                this.question.mSource = mSource;
                this.memberSelect.set(this.question.id, {
                    q: this.question.id,
                    a: this.question.mSource,
                    r: this.question.mSource === this.question.answer ? 0 : 1,
                    qa: this.question.answer
                });

                // 检查答案是否正确，如果错误则弹出提示框
                if (this.question.mSource !== this.question.answer) {
                    this.showAnswerErrorDialog(this.question);
                }
            } else if (this.question.questionType !== 2 && this.question.mSource) {
                this.memberSelect.set(this.question.id, {
                    q: this.question.id,
                    a: this.question.mSource,
                    r: this.question.mSource === this.question.answer ? 0 : 1,
                    qa: this.question.answer
                });

                // 检查答案是否正确，如果错误则弹出提示框
                if (this.question.mSource !== this.question.answer) {
                    this.showAnswerErrorDialog(this.question);
                }
            }
        }, showAnswerErrorDialog(question) {
            // 创建一个新的提示框实例
            const dialogInstance = this.$alert(`<div style="width:auto;height: auto; line-height: 1.5;font-size: 30px;color: #2b2b2c;text-align: center">
            您选择的答案是错误的：<span style="color: red;">${question.mSource}</span>，
            正确答案是：<span style="color: #0ac03e;">${question.answer}</span>
                </div>`, '答题错误', {
                confirmButtonText: '确定',
                dangerouslyUseHTMLString: true,
                callback: action => {
                    // 点击确定按钮的回调
                    console.log(action);
                }
            });

            // 获取确定按钮元素
            const confirmButton = document.querySelector('.el-message-box__btns .el-button--primary');

            // 添加自定义样式
            if (confirmButton) {
                confirmButton.style.fontSize = '25px'; // 修改字体大小
                confirmButton.style.padding = '15px 20px'; // 修改按钮内边距
            }
        },
        getQuestClass(q, index) {
            var answerData = this.memberSelect.get(q);
            if (!answerData) {
                return index === this.qIndex ? "question-select-button3" : "question-select-button2";
            } else {
                var style = answerData.r === 0 ? "question-select-button2-right" : "question-select-button2-error";
                return index === this.qIndex ? style + "-focus" : style;
            }
        },
        nextQuestion() {
            var q = this.examData.questionList[this.qIndex + 1];
            this.getQuestion(q, this.qIndex + 1);
        },
        lastQuestion() {
            var q = this.examData.questionList[this.qIndex - 1];
            this.getQuestion(q, this.qIndex - 1);
        },
        getQuestion(q, index) {
            this.selectMSource();
            postData("/api/home/question/detail", {
                questionId: q
            }).then(res => {
                if (res.code === "000000") {
                    this.question = res.data;
                    this.qIndex = index;
                    var answerData = this.memberSelect.get(q);
                    if (answerData) {
                        this.question.mSource = answerData.a;
                        if (this.question.questionType === 2) {
                            this.question.mSources = answerData.a.split('');
                        }
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        commitExam() {
            // 先弹出提示框
            this.$confirm('确定要提交试卷吗?', '提示', {
                confirmButtonText: '确认交卷',
                cancelButtonText: '继续考试',
                type: 'warning',

            }).then(() => {
                // 点击确定后执行这里的代码
                this.selectMSource();
                this.question = this.examData.firstQuestionVo;
                this.qIndex = 0;
                var examContent = [];
                this.examData.questionList.forEach(q => {
                    var answerData = this.memberSelect.get(q);
                    if (answerData) {
                        var data = {};
                        Object.assign(data, answerData);
                        data.qa = null;
                        examContent.push(data);
                    } else {
                        examContent.push({
                            q: q,
                            a: '',
                            r: 1
                        });
                    }
                });
                postData("/api/home/plate/commitExam", {
                    examId: this.examData.examId,
                    examContent: JSON.stringify(examContent),
                    startTime: this.$TdDate.df(this.startTime, 'YYYY-MM-DD HH:mm:ss')
                }).then(res => {
                    if (res.code === "000000") {
                        this.successMessage(res.data);
                        this.isEnd = true;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message
                        })
                    }
                })
            }).catch(() => {
                // 点击取消后执行这里的代码
                console.log('已取消提交试卷');
            });
        },
        successMessage(examResult) {
            if (examResult >= this.examData.questionList.length * 0.6) {
                this.$confirm('恭喜您本次模拟考试总得分为' + examResult + '!')
            } else {
                this.$confirm('您本次模拟考试总得分为' + examResult + ',再接再厉!')
            }
        },disableRightClick(e) {
            e.preventDefault();
        }
    }
}
</script>
<style scoped>
.plate-title {
    height: 3.5vh;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #111112;
    line-height: 48px;
    margin-bottom: 10px;
}

.left-title {
    height: 24px;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #111112;
    margin-left: 5px;
}

.left-input {
    width: 65%;
    height: 20px;
}

.question-title {
    height: 16px;
    font-size: 22px;
    font-family: "STSong", "SimSun", serif;
    font-weight: bold;
    color: #0451c5;
    line-height: 24px;
}

.answer-select {
    margin-left: auto;
    margin-right: auto;
    color: #0451c5;
    font-size: 22px;
    font-family: "STSong", "SimSun", serif;
}

.question-select {
    height: 24px;
    font-size: 30px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: bold;
    color: #444444;
    line-height: 23px;
    margin-right: 10px;
}

.question-option {
    font-size: 40px;
    font-weight: 500;
    margin-right: 5px
}

.question-select-button {
    font-size: 15px;
    height: 32px;
    width: 6vw;
}

.question-select-button2 {
    font-size: 15px;
    height: 31px;
    width: 41px;
}

.question-select-button2-right {
    height: 31px;
    width: 41px;
    color: #0ac03e;
}

.question-select-button2-right-focus {
    height: 31px;
    width: 41px;
    background-color: #2dcafa;
    color: #E7E7E7;
}

.question-select-button2-error {
    height: 31px;
    width: 41px;
    color: #ee2846;
}

.question-select-button2-error-focus {
    height: 31px;
    width: 41px;
    background-color: #2dcafa;
    color: #E7E7E7;
}


.question-select-button-aside {
    height: 31px;
    width: 41px;
    background-color: #0d91f2;
    color: #2b2b2c;
    font-size: 15px;
}

.question-select-button3 {
    font-size: 15px;
    height: 30px;
    width: 41px;
    background-color: #2dcafa;
}

.question-select-button2:focus {
    background-color: #2dcafa;
    color: #E7E7E7;
}

.question-select-button2:hover {
    border: 1px solid #2dcafa;
}

.question-list {
    flex-wrap: wrap;
    align-content: flex-start
}

::v-deep(.el-radio-button__inner) {
    color: #2b2b2c;
    font-size: 20px;
    border: 1px solid #3b3b3b;
}

::v-deep(.singleSelect .el-radio__label:hover ) {
    background-color: #9bdbef;
    border: 2px solid #3b3b3b;
    font-size: 30px;
    padding: 4px 10px;
    border-radius: 5px;
}

::v-deep(.singleSelect .el-radio__label) {
    background-color: white;
    border: 2px solid #3b3b3b;
    font-size: 30px;
    padding: 4px 10px;
    border-radius: 5px;
}

::v-deep(.singleSelect .el-radio) {
    margin-right: 25px;
}

::v-deep(.singleSelect .el-radio.el-radio--large .el-radio__inner) {
    width: 15px;
    height: 15px;
    border: 1px solid;
    display: none;
}

::v-deep(.singleSelect .el-checkbox__label:hover ) {
    background-color: #9bdbef;
    border: 2px solid #3b3b3b;
    font-size: 34px;
    padding: 4px 9px;
    border-radius: 5px;
}

::v-deep(.singleSelect .el-checkbox__label) {
    background-color: white;
    border: 2px solid #3b3b3b;
    font-size: 34px;
    padding: 4px 9px;
    border-radius: 5px;
}

::v-deep(.singleSelect .el-checkbox) {
    color: rgba(92, 92, 93, 0.98);
    margin-right: 25px;
}

::v-deep(.singleSelect .el-checkbox.el-checkbox--large .el-checkbox__inner ) {
    width: 15px;
    height: 15px;
    border: 1px solid;
    display: none;
}

.true-false-question {
    display: flex;
    flex-direction: column; /* 将选项竖直排列 */
    align-items: center; /* 水平居中 */
    margin-top: 20px;
}

.true-false-question .el-radio-button {
    padding: 2px; /* 增加一些内边距 */
    border-radius: 4px;
    background-color: #3f3f3f; /* 设置背景颜色 */
    margin-bottom: 14px; /* 选项之间添加一些间距 */
    font-size: 30px;
    margin-right: 13px;
}


</style>
