<template>
    <div class="login_background" />
    <div class="flex-row justify-center login_position" >
        <div style="width: 75%" class="flex-row justify-between">
            <div style="width: 50%"></div>
            <div style="width: 50%" class="flex-row align-center">
                <div class="about_main flex-col">
                    <div class="flex-row align-center" style="height:25%;margin-left: 45px">
                        <span class="about_title">关于我们</span>
                    </div>
                    <div class="flex-col" style="width:100%;margin-left: 45px;height: 30%">
                        <span class="about-home-text" style="width: 85%">然校长交规是驾考理论网络服务平台之一。以丰富的培训资源背景为强大的依托，以“一切为了求知者”为经营理念，致力于为广大的求知者提供全面、有效、便捷的服务系统，力图为求知者排除求知路上的点滴困难，明确求知方向！</span>
                    </div>
                    <div class="flex-col" style="width:100%;margin-left: 45px;margin-top: 20px">
                        <span style="width:180px;height: 26px;margin-top: 8px;font-weight: bold">联系我们:19965433320</span>
                        <el-divider style="margin-top: 12px;margin-bottom: 8px"/>
                        <span class="flex-row align-center" style="height: 16px">
                            <img src="../assets/img2/icon_contact.png" style="width: 14px;height: 14px"/>
                            <p style="margin-left: 8px">然 校 长</p>
                        </span>
                        <span class="flex-row align-center" style="margin-top: 8px;height: 16px">
                            <img src="../assets/img2/icon_tel.png" style="width: 14px;height: 14px"/>
                            <p style="margin-left: 8px">19965433320</p>
                        </span>
                        <span class="flex-row align-center" style="margin-top: 8px;height:16px">
                            <img src="../assets/img2/icon_address.png" style="width: 14px;height: 14px"/>
                            <p style="margin-left: 8px">合肥市瑶海区凤阳路恒丰大厦A座2810室</p>
                        </span>
                        <span class="flex-row align-center" style="margin-top: 8px;height: 16px;">
                            <img src="../assets/img2/icon_copyright.png" style="width: 14px;height: 14px"/>
                            <p style="margin-left: 8px">然 校 长</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "About",
    data() {
        return {}
    },
    created() {
    },
    methods: {}
}
</script>

<style scoped>
.about_main {
    width: 24vw;
    height: 45vh;
    margin-top: 12vh;
    border-radius: 4px;
    z-index: 7;
    overflow: hidden;
}

.about-home-text {
    width: 55vw;
    height: 4vh;
    font-size: 1.8vh;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 350;
    color: #111112;
    line-height: 2vh;
}

.about_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #111112;
    line-height: 4vh;
}
</style>
<style scoped lang="css" src="../assets/css/login.css"/>
