/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
    const valid_map = ['admin', 'editor']
    return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
    const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
    const reg = /^[a-z]+$/
    return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
    const reg = /^[A-Z]+$/
    return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
    const reg = /^[A-Za-z]+$/
    return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function email(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(email)
}


/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
    if (typeof str === 'string' || str instanceof String) {
        return true
    }
    return false
}

/**
 * @param {value} str
 * @returns {Boolean}
 */
export function isNumber(value) {
    const reg = /^(-)?\d+(\.\d+)?$/;
    return reg.test(value)
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg)
}

/**
 * 是否合法IP地址
 * @param rule
 * @param value
 * @param callback
 */
export function validateIP(rule, value, callback) {
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        if ((!reg.test(value)) && value !== '') {
            callback(new Error('请输入正确的IP地址'))
        } else {
            callback()
        }
    }
}

/* 是否手机号码或者固话*/
export function validatePhoneTwo(rule, value, callback) {
    const reg = /^((0\d{2,3}-\d{7,8})|(1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}))$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if ((!reg.test(value)) && value !== '') {
            callback(new Error('请输入正确的电话号码或者固话号码'))
        } else {
            callback()
        }
    }
}

/* 是否固话*/
export function validateTelephone(rule, value, callback) {
    const reg = /0\d{2}-\d{7,8}/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if ((!reg.test(value)) && value !== '') {
            callback(new Error('请输入正确的固话（格式：区号+号码,如010-1234567）'))
        } else {
            callback()
        }
    }
}

/* 是否手机号码*/
export function validPhone(rule, value, callback) {
    const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if ((!reg.test(value)) && value !== '') {
            callback(new Error('请输入正确的手机号码'))
        } else {
            callback()
        }
    }
}

/* 是否身份证号码*/
export function validateIdNo(rule, value, callback) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else {
        if ((!reg.test(value)) && value !== '') {
            callback(new Error('请输入正确的身份证号码'))
        } else {
            callback()
        }
    }
}

/* 整数6位*/
export function sixNum(rule, value, callback) {
    const reg = /^\d{6}$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((!reg.test(value)) && value !== '') {
        callback(new Error('必须是6位的整数'))
    } else {
        callback()
    }
}

/* 是否整数最多6位*/
export function validNum(rule, value, callback) {
    const reg = /^\d{1,6}$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((!reg.test(value)) && value !== '') {
        callback(new Error('最多支持6位的正整数'))
    } else {
        callback()
    }
}

/* 是否是7位一下数字，保留两位小数*/
export function validDecimal82(rule, value, callback) {
    const reg = /^\d{0,7}(\.\d{1,2}|)$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((!reg.test(value)) && value !== '') {
        callback(new Error('不能超过7位数，小数点最多两位有效！'))
    } else {
        callback()
    }
}

/* 是否中文、字母、下划线*/
export function validName(rule, value, callback) {
    const reg = /[^\u4e00-\u9fa5a-zA-Z0-9\w_\-]/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((reg.test(value)) && value !== '') {
        callback(new Error('只支持中文、字母、下划线'))
    } else if (value.length < 2) {
        callback(new Error('至少两个汉字或字母'))
    } else {
        callback()
    }
}

/* 是字母数字*/
export function validName2(rule, value, callback) {
    const reg = /^[a-zA-Z0-9]+$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((!reg.test(value)) && value !== '') {
        callback(new Error('只支持字母、数字'))
    } else if (value.length < 2) {
        callback(new Error('至少两个汉字或字母'))
    } else {
        callback()
    }
}

/* 是字母数字30*/
export function validName3(rule, value, callback) {
    const reg = /^[a-zA-Z0-9]+$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((!reg.test(value)) && value !== '') {
        callback(new Error('只支持字母、数字'))
    } else if (value.length < 2 || value.length > 30) {
        callback(new Error('至少2个至多30个字母数字'))
    } else {
        callback()
    }
}

/* 是字母数字*/
export function validName1(rule, value, callback) {
    const reg = /^[a-zA-Z0-9]+$/
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if ((!reg.test(value)) && value !== '') {
        callback(new Error('只支持字母、数字'))
    } else {
        callback()
    }
}

/* 是否正确email地址*/
export function validMail(rule, value, callback) {
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if (!email(value) && value !== '') {
        callback(new Error('请输入正确的Email地址'))
    } else {
        callback()
    }
}

/* 是否超过位数*/
export function validPwd(rule, value, callback) {
    if (value === '' || value === undefined || value == null) {
        callback()
    } else if (value.length > 8 && value !== '') {
        callback(new Error('输入不超过8位'))
    } else {
        callback()
    }
}
