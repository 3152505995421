<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 120px;" class="flex-row justify-between">
                <div style="width: 75%;" class="flex-col">
                    <div style="height: 50%" class="flex-row">
                        <el-input v-model="search" placeholder="搜索题目"
                                  class="search-input search-first"
                                  clearable></el-input>
                        <el-select v-model="questionType" placeholder="题型"
                                   class="search-input search-first" clearable>
                            <el-option
                                v-for="item in questionTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                        <el-select v-model="state" placeholder="状态"
                                   class="search-input search-first" clearable>
                            <el-option
                                v-for="item in stateList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                    <div style="height: 50%" class="flex-row">
                        <div class="search-input search-second" style="width: 47%">
                            <Tools_SelectChapter
                                :initPlateOptions="this.plateOptions"
                                @getChapter="(value)=>this.chapterId=value"
                                :isEdit="true"
                            />
                        </div>
                    </div>
                </div>
                <div style="width: 25%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      v-if="tableData"
                      :data="tableData"
                      border
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="id" label="编号" width="70"/>
                <el-table-column prop="questionType" label="类型" width="80">
                    <template #default="scope">
                        {{ this.questionTypeMap.get(scope.row.questionType) }}
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="题目"/>
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-popconfirm :title="scope.row.state===1?'您确定启用吗？':'您确定要暂停吗？暂停后续重新设置章节'"
                                       @confirm="handlerState(scope.row.id,scope.row.state===1?0:1)">
                            <template #reference>
                                <el-button class="link_text_no" v-if="scope.row.state===1" link size="small">已暂停
                                </el-button>
                                <el-button class="link_text" v-else link size="small">已启用
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="更新">
                    <template #default="scope">
                        {{ $TdDate.df(scope.row.updateTime, 'YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="200px" label="操作">
                    <template #default="scope">
                        <el-button @click="handleEdit(scope.row)" link size="small" type="primary">编辑</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row.id)">
                            <template #reference>
                                <el-button link size="small" :disabled="scope.row.state===0" type="danger">
                                    删除
                                </el-button>
                            </template>
                        </el-popconfirm>
                        <el-button :disabled="scope.row.state===1" @click="editChapter(scope.row)" link size="small"
                                   type="primary">绑定章节
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="新增/编辑" v-model="dialogVisible" width="50%">
                <el-form ref="form" :model="form" label-width="22%" :rules="rules">
                    <div class="flex-row" style="flex:5;margin-top: 35px;margin-bottom: 20px">
                        <div class="flex-col" style="width: 50%">
                            <el-form-item label="题型" prop="questionType">
                                <el-radio-group v-model="form.questionType">
                                    <el-radio :label="0" size="large">判断题</el-radio>
                                    <el-radio :label="1" size="large">单选题</el-radio>
                                    <el-radio :label="2" size="large">多选题</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="题目" prop="title">
                                <el-input v-model="form.title" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项A" prop="optionA">
                                <el-input v-model="form.optionA" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项A内容" prop="optionAContent">
                                <el-input v-model="form.optionAContent" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项B" prop="optionB">
                                <el-input v-model="form.optionB" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项B内容" prop="optionBContent">
                                <el-input v-model="form.optionBContent" clearable></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex-col" style="width: 50%">
                            <el-form-item label="选项C" prop="optionC">
                                <el-input v-model="form.optionC" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项C内容" prop="optionCContent">
                                <el-input v-model="form.optionCContent" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项D" prop="optionD">
                                <el-input v-model="form.optionD" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="选项D内容" prop="optionDContent">
                                <el-input v-model="form.optionDContent" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="答案" prop="answer">
                                <el-input v-model="form.answer" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="答案分析" prop="answerAnalysis">
                                <el-input v-model="form.answerAnalysis" :rows="3" type="textarea" clearable></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="flex-row" style="flex:1;">
                        <el-form-item label="附件图片" label-width="22%" prop="annex" style="width: 50%">
                            <Tools_UploadFile
                                style="width: 50%"
                                :initFileUrl="form.annex"
                                :initIndex="initIndex"
                                :isEdit="true"
                                @getFile="(file)=>form.aFile=file"
                            />
                        </el-form-item>
                    </div>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
            <el-dialog title="绑定章节" v-model="bindDialogVisible" width="50%">
                <el-form ref="bindForm" :model="bindForm" label-width="22%">
                    <div class="flex-row" style="flex:5;margin-top: 35px;margin-bottom: 20px">
                        <div class="flex-col" style="width: 45%">
                            <el-form-item label="栏目" prop="plateId">
                                <Tools_Tree :title="'请选择栏目'"
                                            :options="this.plateOptions"
                                            :props="defaultProps"
                                            :value="bindForm.plate"
                                            :multiple="false"
                                            :clearable="true"
                                            @getValue="(value)=>this.changePlate(value)"/>
                            </el-form-item>
                            <el-form-item label="章节" prop="plateId">
                                <el-table
                                    ref="leftTable"
                                    :data="this.chapterOptions"
                                    @selection-change="leftSelectionChange"
                                    border height="410">
                                    <el-table-column type="selection" width="55px"/>
                                    <el-table-column label="序号" prop="id" width="60px" fixed/>
                                    <el-table-column label="名称" prop="chapterName"/>
                                </el-table>
                            </el-form-item>
                        </div>
                        <div class="flex-col justify-center align-center" style="width: 10%">
                            <el-button type="primary" @click="addChapter"
                                       :disabled="!this.bindForm.leftChapterList||this.bindForm.leftChapterList.length===0">
                                &gt;&gt;
                            </el-button>
                            <el-button type="primary" @click="cleanChapter"
                                       :disabled="!bindForm.rightChapterList||bindForm.rightChapterList.length===0"
                                       style="margin-left: 0;margin-top: 20px">&lt;&lt;
                            </el-button>
                        </div>
                        <div class="flex-col" style="width: 45%">
                            <div style="margin-bottom:10px;font-size: 12px ">以下是已选章节:</div>
                            <el-table ref="rightTable" :data="bindForm.chapters"
                                      @selection-change="rightSelectionChange"
                                      border height="450">
                                <el-table-column type="selection" width="55px"/>
                                <el-table-column label="序号" prop="id" width="60px" fixed/>
                                <el-table-column label="名称" prop="chapterName"/>
                                <el-table-column label="栏目" prop="plateName">
                                    <template #default="scope">
                                        {{ this.pinjiePlateName(scope.row.plateId) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="bindDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="bindChapter">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData, postDataFormData} from "@/utils/httpReq";

export default {
    name: 'QuestionMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            search: '',
            chapterId: '',
            questionType: '',
            questionTypeList: [//0-判断题,1-单选题,2-多选题
                {
                    value: 0,
                    label: '判断题',
                },
                {
                    value: 1,
                    label: '单选题',
                },
                {
                    value: 2,
                    label: '多选题',
                }
            ],
            questionTypeMap: new Map(),
            state: '',
            stateList: [
                {
                    value: 0,
                    label: '启用',
                },
                {
                    value: 1,
                    label: '暂停',
                }
            ],
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            initIndex: 0,
            rules: {
                title: [
                    {required: true, message: '请输入题目', trigger: 'blur'},
                ],
                questionType: [
                    {required: true, message: '请选择题型', trigger: 'blur'},
                ],
                optionA: [
                    {required: true, message: '未录入选项A', trigger: 'blur'},
                ],
                optionAContent: [
                    {required: true, message: '未录入选项A内容', trigger: 'blur'},
                ],
                optionB: [
                    {required: true, message: '未录入选项B', trigger: 'blur'},
                ],
                optionBContent: [
                    {required: true, message: '未录入选项B内容', trigger: 'blur'},
                ],
                answer: [
                    {required: true, message: '未录入答案', trigger: 'blur'},
                ]
            },
            loading: true,
            plateOptions: [],
            plateMap: new Map(),
            bindDialogVisible: false,
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
            bindForm: {},
            chapterOptions: [],
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        this.plateTree();
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 180; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        load() {
            postData("/api/mgt/question/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                search: this.search,
                chapterId: this.chapterId,
                questionType: this.questionType,
                state: this.state
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        plateTree() {
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.plateOptions = res.data;
                    res.data.map(item => {
                        this.plateMap.set(item.id, item);
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                this.plateMap.set(child.id, child);
                            });
                        }
                    });
                }
            });
            this.questionTypeList.map(type => {
                this.questionTypeMap.set(type.value, type.label);
            });
        },
        pinjieChapterName(chapterVos) {
            if (chapterVos || chapterVos.length > 0) {
                var chapterName = [];
                chapterVos.map(chapterVo => {
                    var plateName = this.pinjiePlateName(chapterVo.plateId);
                    chapterName.push(plateName + '>>' + chapterVo.chapterName)
                });
                return chapterName;
            }
            return [];
        },
        pinjiePlateName(plateId) {
            var child = this.plateMap.get(plateId);
            if (!child) return "";
            if (child.parentId === 0) return child.plateName;
            var parentName = this.pinjiePlateName(child.parentId);
            return parentName + '>>' + child.plateName;
        },
        add() {
            this.dialogVisible = true;
            this.form = {questionType: 0};
            this.initIndex = this.initIndex + 1;
        },
        handleEdit(row) {
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
            this.initIndex = this.initIndex + 1;
        },
        handleDelete(id) {
            postData("/api/mgt/question/delete", {
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        handlerState(id, state) {
            postData("/api/mgt/question/state", {
                id: id,
                state: state
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    postDataFormData("/api/mgt/question/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                            this.load();//刷新表格数据
                            this.dialogVisible = false;//关闭弹窗
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                    });
                }
            });
        },
        changePlate(value) {
            this.bindForm.plateId = value.id
            this.chapterOptions = [];
            postData("/api/mgt/chapter/findByPlateId", {
                plateId: this.bindForm.plateId,
            }).then(res => {
                this.chapterOptions = res.data;
                this.bindForm.leftChapterList = [];
                this.$refs.leftTable.clearSelection();
            })
        },
        editChapter(row) {
            this.bindForm = {};
            this.bindDialogVisible = true;
            this.bindForm.leftChapterList = [];
            this.bindForm.rightChapterList = [];
            this.bindForm.plateId = '';
            this.bindForm.plate = {};
            this.chapterOptions = []
            var chapters = [];
            Object.assign(chapters, row.chapterVos);
            this.bindForm.chapters = chapters;
            this.bindForm.id = row.id;
        },
        leftSelectionChange(val) {
            this.bindForm.leftChapterList = val;
        },
        rightSelectionChange(val) {
            this.bindForm.rightChapterList = val;
        },
        addChapter() {
            this.bindForm.leftChapterList.map(chapter => {
                if (this.bindForm.chapters.findIndex(o => o.id === chapter.id) < 0) {
                    this.bindForm.chapters.push(chapter);
                }
            });
        },
        cleanChapter() {
            this.bindForm.chapters = this.bindForm.chapters.filter(chapter =>
                this.bindForm.rightChapterList.findIndex(o => o.id === chapter.id) < 0);
        },
        bindChapter() {
            var chapterIds = this.bindForm.chapters.map(chapter => chapter.id).join(",");
            postData("/api/mgt/question/bindChapter", {
                id: this.bindForm.id,
                chapterIds: chapterIds
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                    this.bindDialogVisible = false;
                    this.load()//重新加载
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            });
        }
    }
}
</script>
<style scoped>
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
