<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 74px;" class="flex-row justify-between">
                <div style="width: 75%;" class="flex-col">
                    <el-input v-model="search" placeholder="请输入角色名"
                              class="search-input search-first"
                              clearable></el-input>
                </div>
                <div style="width: 25%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      :data="tableData"
                      v-if="tableData"
                      border
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="roleName" label="角色名"/>
                <el-table-column prop="roleTag" label="角色标识"/>
                <el-table-column fixed="right" width="200px" label="操作">
                    <template #default="scope">
                        <el-button @click="handleEdit(scope.row)" link size="small" type="primary">编辑</el-button>
                        <el-button @click="handleMenus(scope.row)" link size="small" type="warning">赋权</el-button>
                        <el-popconfirm
                            title="确定删除吗？" @confirm="handleDelete(scope.row.id)">
                            <template #reference>
                                <el-button link size="small" type="danger">
                                    删除
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="提示" v-model="dialogVisible" width="30%">
                <el-form ref="form" :model="form" label-width="22%" :rules="rules">
                    <el-form-item label="角色名" prop="roleName">
                        <el-input v-model="form.roleName" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="角色标记" prop="roleTag">
                        <el-input v-model="form.roleTag" clearable></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                          <span class="dialog-footer">
                            <el-button @click="dialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="save">确 定</el-button>
                          </span>
                </template>
            </el-dialog>
            <!--弹窗 权限分配-->
            <el-dialog v-model="assignDialogVisible" title="分配权限" width="30%" @close="clearMenus">
                <el-form :model="menuForm" ref="menuForm" label-width="22%">
                    <el-form-item label="权&nbsp;&nbsp;&nbsp;&nbsp;限" size="large">
                        <el-tree
                            clearable
                            ref="tree"
                            :data="typeOptions"
                            :props="defaultProps"
                            :check-strictly="checkStrictly"
                            show-checkbox
                            default-expand-all
                            node-key="id"
                            class="permission-tree"
                            :style="{height: '400px',overflow:'auto'}"
                        />
                    </el-form-item>
                </el-form>
                <div style="text-align:right;">
                    <el-button @click="assignDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="confirmMenu">确认</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";
import {validName} from "@/utils/validate";

export default {
    name: 'Role',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            search: '',
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            assignDialogVisible: false,
            typeOptions: [],
            menuForm: {
                id: null,
                menuIds: []
            },
            defaultProps: {
                children: 'children',
                label: 'menuName'
            },
            checkStrictly: false,
            rules: {
                roleName: [
                    {required: true, message: '请输入角色名', trigger: 'blur'},
                    {validator: validName, trigger: 'blur'},
                ],
                roleTag: [
                    {required: true, message: '请输入角色标签', trigger: 'blur'},
                ]
            },
            loading: true
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        if (this.params && this.params.load) {
            this.load();
            this.treeList();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 138; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        load() {
            postData("/api/mgt/role/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                search: this.search
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        add() {
            this.dialogVisible = true;
            this.form = {};
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    postData("/api/mgt/role/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                        this.load();//刷新表格数据
                        this.dialogVisible = false;//关闭弹窗
                    });
                }
            });
        },
        handleEdit(row) {
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
        },
        handleMenus(row) {
            this.assignDialogVisible = true;
            this.menuForm.id = row.id;
            this.checkStrictly = true
            postData("/api/mgt/role/detail", {
                id: this.menuForm.id
            }).then(res => {
                if (res.code === "000000") {
                    this.menuForm.menuIds = res.data;
                    this.$refs.tree.setCheckedKeys(res.data);
                    this.checkStrictly = false
                }
            });
        },
        clearMenus() {
            this.menuForm = {
                id: null,
                menuIds: []
            };
            this.assignDialogVisible = false;
        },
        confirmMenu() {
            var prantId = this.$refs["tree"].getHalfCheckedKeys();
            var childId = this.$refs["tree"].getCheckedKeys();
            this.menuForm.menuIds = childId.concat(prantId);
            postData("/api/mgt/role/setMenus", {
                id: this.menuForm.id,
                menus: this.menuForm.menuIds.join(",")
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                    this.assignDialogVisible = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            });
        },
        treeList() {
            postData("/api/mgt/menu/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.typeOptions = res.data;
                }
            });
        },
        handleDelete(id) {
            postData("/api/mgt/role/delete", {
                id: id
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        }
    }
}
</script>
<style scoped>
.permission-tree {
    background-color: #F7F7F7;
    width: 90%;
    box-shadow: -2px -2px 3px 3px rgba(117, 134, 156, 0.2);
}
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
