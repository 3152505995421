<template>
    <div class="flex-row justify-center" style="width: 100%;background: linear-gradient(to bottom, #b7b3b3, #bed5fa);"
         @contextmenu.prevent="disableRightClick">
        <div class="flex-col" style="width: 75%;margin-left: 45px;">
            <div class="flex-row justify-between" style="margin-top: 32px;margin-bottom: 24px">
                <div class="flex-col justify-between">
                    <span class="plate-title">{{ this.plateData.plateName }}</span>
                    <span class="plate-desc">阶段目标：根据公安部，学员需要了解机动车基本知识，掌握道路交通安全法律、法规及道路交通信号的规定。</span>
                    <span class="plate-desc"></span>
                </div>
                <div class="flex-row align-center plateButton">
                    <div v-for="tem in this.plateData.templateVos">
                        <el-button class="plate-button" @click="toExam(tem.id)">
                            <p class="add-button-text">
                                {{ tem.examName }}
                            </p>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="flex-row justify-between " style="height: 100%;margin-bottom: 57px">
                <div style="width: 49%" class="flex-col ">
                    <div v-for="(item,index) in this.plateData.chapterVos1" style="margin-top: 7px">
                        <div class="plate-chapter flex-row justify-between">
                            <div class="flex-row justify-center align-center">
                                <span class="plate-chapter-title"> {{ index + 1 + '.' + item.chapterName }} </span>
                                <span class="plate-chapter-title"
                                      style="color: #48484b;">{{ ' (共' + item.questionCount + '题)' }}</span>
                            </div>
                            <div class="flex-row justify-center align-center" style="margin-right: 20px">
                                <el-button
                                    @click="$router.push({path:'/chapterExercise/'+plateId+'/'+item.id+'/order'})" link
                                    class="plate-chapter-button-title">
                                    <img src="../assets/img2/icon_orderex.png" style="margin-right: 8px">
                                    顺序练习
                                </el-button>
                                <el-button
                                    @click="$router.push({path:'/chapterExercise/'+plateId+'/'+item.id+'/random'})" link
                                    class="plate-chapter-button2-title">
                                    <img src="../assets/img2/icon_randomex.png" style="margin-right: 8px">
                                    随机练习
                                </el-button>
<!--                                <el-button-->
<!--                                    @click="$router.push({path:'/Video'})" link-->
<!--                                    class="plate-chapter-button3-title">-->
<!--                                    <img src="../assets/img2/icon_randomex.png" style="margin-right: 8px">-->
<!--                                    视频讲解-->
<!--                                </el-button>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 49%" class="flex-col">
                    <div v-for="(item,index) in this.plateData.chapterVos2" style="margin-top: 7px">
                        <div class="plate-chapter flex-row justify-between">
                            <div class="flex-row justify-center align-center">
                                <span class="plate-chapter-title"> {{
                                        (this.plateData.chapterVos1.length + index) + 1 + '.' + item.chapterName
                                    }} </span>
                                <span class="plate-chapter-title"
                                      style="color: #48484b;">{{ ' (共' + item.questionCount + '题)' }}</span>
                            </div>
                            <div class="flex-row justify-center align-center" style="margin-right: 20px">
                                <el-button
                                    @click="$router.push({path:'/chapterExercise/'+plateId+'/'+item.id+'/order'})" link
                                    class="plate-chapter-button-title">
                                    <img src="../assets/img2/icon_orderex.png" style="margin-right: 8px">
                                    顺序练习
                                </el-button>
                                <el-button
                                    @click="$router.push({path:'/chapterExercise/'+plateId+'/'+item.id+'/random'})" link
                                    class="plate-chapter-button2-title">
                                    <img src="../assets/img2/icon_randomex.png" style="margin-right: 8px">
                                    随机练习
                                </el-button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {postData} from "@/utils/httpReq";

export default {
    name: "Plate",
    data() {
        return {
            plateId: '',
            plateData: {},
        }
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                if (this.$route.params && this.$route.params.plateId) {
                    this.plateId = this.$route.params.plateId;
                    this.initPlate();
                }
            }
        },
    },
    created() {
        if (this.$route.params && this.$route.params.plateId) {
            this.plateId = this.$route.params.plateId;
            this.initPlate();
        }
    },
    methods: {
        initPlate() {
            postData("/api/home/plate/detail", {
                plateId: this.plateId
            }).then(res => {
                if (res.code === "000000") {
                    this.plateData = res.data;
                    this.plateData.chapterVos1 = [];
                    this.plateData.chapterVos2 = [];
                    if (this.plateData.halfChapterNum > 0) {
                        this.plateData.chapterVos.forEach((chapter, index) => {
                            if (index < this.plateData.halfChapterNum) {
                                this.plateData.chapterVos1.push(chapter);
                            } else {
                                this.plateData.chapterVos2.push(chapter);
                            }
                        });
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        toExam(temId) {
            this.$router.push("/exam/" + this.plateId + "/" + temId);
        },disableRightClick(e) {
            e.preventDefault();
        }
    }
}
</script>

<style scoped>
.plate-title {
    height: 3.5vh;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #111112;
    line-height: 48px;
    margin-bottom: 5px;
}

.plate-desc {
    height: 24px;
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #4e4f52;
    line-height: 24px;
    margin-top: 12px;
}

.plate-button {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #2D71FA;
    margin-left: 8px;
}

.plate-chapter {
    width: 100%;
    height: 48px;
    background: #FAFBFF;
    box-shadow: 0px 2px 4px 0px rgba(198, 204, 215, 0.4);
    border-radius: 4px;
}

.plate-chapter-title {
    height: 24px;
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #111112;
    line-height: 24px;
    margin-left: 16px;
}

.plate-chapter-button-title {
    background-color: #07be54;
    height: 36px;
    font-size: 18px !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #131313 !important;
    line-height: 24px;
}

.plate-chapter-button2-title {
    background-color: #e16e30;
    height: 36px;
    font-size: 18px !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #131313 !important;
    line-height: 24px;
}
.plate-chapter-button3-title {
    background-color: #a030e1;
    height: 36px;
    font-size: 18px !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #131313 !important;
    line-height: 24px;
}

::v-deep(.plateButton .el-button--small) {
    font-size: 16px
}

::v-deep(.chapter .el-button--small) {
    font-size: 18px
}

::v-deep(.chapter .el-divider--vertical) {
    height: 450px;
}
</style>
