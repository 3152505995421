<template>
    <div style="width: 50%">
        <el-date-picker
            v-model="startTime"
            :shortcuts="shortcuts"
            clearable
            :end-placeholder="title+'截止'"
            popper-class="date-picker-style"
            range-separator="至"
            :start-placeholder="title+'开始'"
            type="datetimerange"
            value-format="YYYY-MM-DD HH:mm:ss"
            @change="change"
        >
        </el-date-picker>
    </div>
</template>

<script>

export default {
    name: "Tools_SelectTime",
    emits: ["getTime"],
    props: {
        initStartTime: {
            type: Array,
            default: () => {
                return []
            }
        },
        title: ""
    },
    data() {
        return {
            startTime: [],
            shortcuts: [{
                text: '最近一周',
                value: () => {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    return [start, end];
                }
            }, {
                text: '最近一个月',
                value: () => {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    return [start, end];
                }
            }, {
                text: '最近三个月',
                value: () => {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    return [start, end];
                }
            }],
        }
    },
    watch: {
        initStartTime: {
            handler(val, oldval) {
                this.startTime = this.initStartTime;   // 初始值
            },
        },
    },
    mounted() {
        this.startTime = this.initStartTime;   // 初始值
    },
    methods: {
        change() {
            this.$emit('getTime', this.startTime);
        },
    }
}
</script>

<style scoped>
::v-deep(.el-range-editor--small.el-input__wrapper) {
    width: 92% !important;
    height: 34px;
}

::v-deep(.el-input--small .el-input__inner) {
    margin-left: 2%;
    font-size: 14px;
}

::v-deep(.el-button--small) {
    padding: 8px 32px;
}

</style>
