<template>
    <Tools_Tree :title="'栏目'"
                style="width: 50% !important"
                :options="this.plateOptions"
                :props="defaultProps"
                :multiple="false"
                :clearable="true"
                :disabled="!isEdit"
                @getValue="(value)=>this.changePlate(value)"/>
    <el-select v-model="chapterId" placeholder="章节"
               clearable style="width: 50% !important" :disabled="!isEdit" @change="changeChapter">
        <el-option
            v-for="item in chapterOptions"
            :key="item.id"
            :label="item.chapterName"
            :value="item.id"/>
    </el-select>
</template>

<script>

import {postData} from "@/utils/httpReq";

export default {
    name: "Tools_SelectChapter",
    emits: ["getChapter"],
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        initPlateId: '',
        initChapterId: '',
        initChapterOptions: {
            type: Array,
            default: () => {
                return []
            }
        },
        initPlateOptions: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            chapterId: '',
            plateId: '',
            plateOptions: [],
            chapterOptions: [],
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
        }
    },
    watch: {
        initPlateId: {
            handler(val, oldval) {
                this.plateId = this.initPlateId;    // 初始值
            },
        },
        initChapterId: {
            handler(val, oldval) {
                this.chapterId = this.initChapterId;   // 初始值
            },
        },
        initChapterOptions: {
            handler(val, oldval) {
                this.chapterOptions = this.initChapterOptions;   // 初始值
            },
        },
        initPlateOptions: {
            handler(val, oldval) {
                this.plateOptions = this.initPlateOptions;   // 初始值
            },
        },
    },
    mounted() {
        this.plateId = this.initPlateId;    // 初始值
        this.chapterId = this.initChapterId;   // 初始值
        this.chapterOptions = this.initChapterOptions;   // 初始值
        this.plateOptions = this.initPlateOptions;   // 初始值
    },
    created() {
    },
    methods: {
        changePlate(value) {
            this.plateId = value.id
            this.chapterId = '';
            this.chapterOptions = [];
            postData("/api/mgt/chapter/findByPlateId", {
                plateId: this.plateId,
            }).then(res => {
                this.chapterOptions = res.data;
            })
        },
        changeChapter() {
            this.$emit('getChapter', this.chapterId);
        }
    }
}
</script>

<style scoped>

</style>
