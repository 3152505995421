<template>
    <el-select ref="selectTree"
               :value="multiple?valueMultiple:value"
               v-model="valueName"
               :multiple="multiple"
               :placeholder="title"
               :clearable="clearable"
               @clear="clearHandle"
               @change="changeValue">
        <el-option :value="valueName" class="options tree">
            <el-tree
                v-if="options"
                id="tree-option"
                default-expand-all
                :data="options"
                :props="props"
                :node-key="props.value"
                @node-click="handleNodeClick">
            </el-tree>
        </el-option>
    </el-select>
</template>
<script>
export default {
    name: "Tools_Tree",
    props: {
        // 配置项
        props: {
            type: Object,
            default: () => {
                return {
                    value: 'id',
                    children: 'children',
                    label: 'name'
                }
            }
        },
        // 选项列表数据(树形结构的对象数组)
        options: {
            type: Array,
            default: () => {
                return []
            }
        },
        value: {
            type: Object,
            default: () => {
                return {}
            }
        },
        // 初始值（多选）
        valueMultiple: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 可清空选项
        clearable: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '请选择'
        },
    },
    data() {
        return {
            resultValue: this.multiple ? [] : {}, // 传给父组件的数组对象值
            valueName: this.multiple ? [] : ''  // 输入框显示值
        }
    },
    watch: {
        valueMultiple: {
            handler(val, oldval) {
                this.resultValue = this.valueMultiple;   // 初始值
                this.initHandle()
            },
        },
        value: {
            handler(val, oldval) {
                this.resultValue = this.value;   // 初始值
                this.initHandle()
            },
        }
    },
    mounted() {
        this.resultValue = this.multiple ? this.valueMultiple : this.value;
        this.initHandle();
    },
    methods: {
        // 初始化显示
        initHandle() {
            if (this.resultValue) {
                if (this.multiple) {
                    this.valueName = [];
                    this.resultValue.forEach(item => {
                        this.valueName.push(item[this.props.label]);
                    });
                } else {
                    // 单选
                    this.valueName = this.resultValue[this.props.label];
                    if (this.valueName === undefined) {
                        this.valueName = '';
                    }
                }
            }
            this.initScroll()
        },
        // 初始化滚动条
        initScroll() {
            this.$nextTick(() => {
                let scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0]
                let scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar')
                scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;'
                scrollBar.forEach(ele => ele.style.width = 0)
            })
        },
        // 切换选项
        handleNodeClick(node) {
            // 点击叶子节点后被选中
            if (node.children == null || node.children == undefined || node.children.length === 0) {
                if (this.multiple) {
                    // 多选（判重后添加）
                    let num = 0;
                    this.valueName.forEach(item => {
                        item == node[this.props.label] ? num++ : num;
                    })
                    if (num == 0) {
                        this.valueName.push(node[this.props.label]); // 输入框显示值
                        this.resultValue.push(node);
                    }
                } else {
                    // 单选
                    this.valueName = node[this.props.label];
                    this.resultValue = node;
                    this.$refs.selectTree.visible = false;
                }
                this.$emit('getValue', this.resultValue);
            }
        },
        // 从输入框中直接删除某个值时
        changeValue(val) {
            if (this.multiple) {
                // 多选（同时删掉传给父组件中多余的值，再传给父组件）
                this.resultValue.map((item, index) => {
                    let i = val.indexOf(item.name)
                    if (i == -1) {
                        this.resultValue.splice(index, 1)
                    }
                })
                this.$emit('getValue', this.resultValue);
            } else {
                // 单选
                this.$emit('getValue', val);
                this.$refs.selectTree.visible = false;
            }
        },
        // 清除选中
        clearHandle() {
            this.valueName = this.multiple ? [] : '';
            this.resultValue = []
            this.clearSelected()
            this.$emit('getValue', this.resultValue)
        },
        // 清空选中样式
        clearSelected() {
            let allNode = document.querySelectorAll('#tree-option .el-tree-node')
            allNode.forEach((element) => element.classList.remove('is-current'))
        }
    }

}
</script>

<style scoped>
::v-deep(.el-scrollbar .el-scrollbar__view .el-select-dropdown__item) {
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    height: 200px;
}

.tree {
    height: 200px;
    background-color: #FFFFFF;
}

::v-deep(.el-select-dropdown__item.selected) {
    font-weight: normal;
}

::v-deep(ul li  .el-tree .el-tree-node__content) {
    padding: 0 20px;
}

::v-deep(.el-tree-node__label) {
    font-weight: normal;
}

::v-deep(.el-tree .is-current .el-tree-node__label) {
    color: #409EFF;
    font-weight: 700;
}

::v-deep(.el-tree .is-current .el-tree-node__children .el-tree-node__label) {
    font-weight: normal;
}

::v-deep(.el-popper) {
    z-index: 9999;
}

.setstyle {
    height: 200px !important;
    min-height: 200px;
    padding: 0 !important;
    margin: 0;
    overflow: auto;
    cursor: default !important;
}
</style>
