export const components = (app) => {
    const tabNameMap = new Map();
    const requireCom = require.context(".", false, /(\.vue)$/)
    requireCom.keys().forEach(fileName => {
        const config = requireCom(fileName);
        var key = fileName.replace('./', '').replace('.vue', '');
        const name = "Tab_" + fileName.replace('./', '').replace('.vue', '')
        tabNameMap.set(key.charAt(0).toLowerCase() + key.slice(1), name);
        app.component(name, config.default || config)
    })
    return tabNameMap;
}
