<template>
    <div class="flex-row justify-center" style="width: 100%;background: linear-gradient(to bottom, #caeaf5, #dcdbdb);"
         @contextmenu.prevent="disableRightClick">
        <div v-loading="loading"
             element-loading-text="拼命加载中"
             element-loading-background="rgba(0, 0, 0, 0.4)"
             class="flex-col" style="width: 75%;margin-left: 45px">
            <!--栏目章节名区域-->
            <div class="flex-row justify-between"
                 style="margin-top: 32px;margin-bottom: 24px">
                <span class="plate-title" v-show="this.chapterData.plateName">
                    {{
                        this.chapterData.plateName + ' ' + this.chapterData.chapterName + ' ' + (this.queryType === 'order' ? '顺序练习' : '随机练习')
                    }}
                </span>
            </div>
            <div class="flex-row justify-between " style="height: 100%;margin-bottom: 57px">
                <div style="width: 49%" class="flex-col ">
                    <!--题目、选项、图片区域-->
                    <div class="question-title flex-col" style="height: 400px;">
                        <div v-show="this.question.title" style="margin-bottom: 10px">{{
                                (this.qIndex + 1) + '.' + this.question.title
                            }}
                        </div>
                        <div v-if="this.question.questionType===1||this.question.questionType===2"
                             class="flex-col justify-between">
                            <div class="flex-col">
                                <span>{{ this.question.optionA + '.' + this.question.optionAContent }}</span>
                                <span>{{ this.question.optionB + '.' + this.question.optionBContent }}</span>
                                <span>{{ this.question.optionC + '.' + this.question.optionCContent }}</span>
                                <span>{{ this.question.optionD + '.' + this.question.optionDContent }}</span>
                            </div>
                            <div style="height: 200px;margin-top:10px;margin-bottom: 5px">
                                <img
                                    ref="questionImg"
                                    style="height: 100%; max-width: 100%"
                                    v-show="this.question.annex"
                                    :src="this.question.annex"
                                    @click="toggleImageSize"
                                />
                            </div>
                        </div>
                        <div v-else style="height: 200px;margin-top:10px;margin-bottom: 5px;">
                            <img
                                ref="questionImg"
                                style="max-width: 95%; max-height: 80%;"
                                v-show="this.question.annex"
                                :src="this.question.annex"
                                @click="toggleImageSize"
                            />
                        </div>
                    </div>
                    <div class="flex-col align-end" style="height: 300px">
                        <!--答题区域-->
                        <div style="height: 20%;background-color: #dcdbdb;width: 100%"
                             class="flex-row justify-end align-center">
                            <p class="question-select">选 择:</p>
                            <div class="true-false-question1" v-if="this.question.questionType===0">
                                <el-radio-group v-model="this.question.mSource" size="large">
                                    <el-radio-button :label="this.question.optionAContent">
                                        {{ this.question.optionAContent }}
                                    </el-radio-button>
                                    <el-radio-button :label="this.question.optionBContent" style="margin-left: 8px">
                                        {{ this.question.optionBContent }}
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                            <div v-else-if="this.question.questionType===1" class="singleSelect"
                                 style="margin-right: 8px">
                                <el-radio-group v-model="this.question.mSource" size="large"
                                                class="flex-row align-center">
                                    <p class="question-option">{{ this.question.optionA }}</p>
                                    <el-radio :label="this.question.optionA"></el-radio>
                                    <p class="question-option">{{ this.question.optionB }}</p>
                                    <el-radio :label="this.question.optionB"></el-radio>
                                    <p class="question-option">{{ this.question.optionC }}</p>
                                    <el-radio :label="this.question.optionC"></el-radio>
                                    <p class="question-option">{{ this.question.optionD }}</p>
                                    <el-radio :label="this.question.optionD"></el-radio>
                                </el-radio-group>
                            </div>
                            <div v-else class="singleSelect">
                                <el-checkbox-group v-model="this.question.mSources" size="large"
                                                   class="flex-row align-center">
                                    <p class="question-option">{{ this.question.optionA }}</p>
                                    <el-checkbox :label="this.question.optionA"></el-checkbox>
                                    <p class="question-option">{{ this.question.optionB }}</p>
                                    <el-checkbox :label="this.question.optionB"></el-checkbox>
                                    <p class="question-option">{{ this.question.optionC }}</p>
                                    <el-checkbox :label="this.question.optionC"></el-checkbox>
                                    <p class="question-option">{{ this.question.optionD }}</p>
                                    <el-checkbox :label="this.question.optionD"></el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                        <!--上下题选择区域-->
                        <div style="height: 10%;margin-top: 10px" class="flex-row align-center">
                            <el-button class="question-select-button" style="background-color: #e16e30"
                                       @click="this.isAnalysis=!this.isAnalysis">
                                <p class="add-button-text" style="color: #2b2b2c;"> 查看分析 </p>
                            </el-button>
                            <el-button class="question-select-button" @click="lastQuestion" :disabled="this.qIndex===0">
                                <p class="add-button-text"> 上一题 </p>
                            </el-button>
                            <el-button v-if="this.chapterData&&this.chapterData.questionList"
                                       class="question-select-button"
                                       @click="nextQuestion"
                                       :disabled="this.qIndex+1===this.chapterData.questionList.length">
                                <p class="add-button-text"> 下一题 </p>
                            </el-button>
                        </div>
                        <!--答案分析区域-->
                        <div class="flex-col align-start" style="height: 70%;width: 100%">
                            <div v-show="this.isAnalysis" class="flex-col">
                                <h4>最佳分析:</h4>
                                <span>{{ '正确答案: ' + this.question.answer }}</span>
                                <div style="width: 600px;height: 200px;overflow: auto">
                                    <p v-html="this.question.answerAnalysis"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--题目列表区域-->
                <div style="width: 50%;" class="flex-col">
                    <div class="flex-row question-list"
                         style="width: 470px;background: linear-gradient(to bottom, #b2c9d0, #dcdbdb);">
                        <div v-for="(q,index) in this.chapterData.questionList">
                            <el-button :class="getQuestClass(q,index)" @click="getQuestion(q,index)"
                                       :plain="index!==this.qIndex">
                                <div class="flex-col justify-center">
                                    <span style="height: 12px">{{ index + 1 }}</span>
                                    <span v-if="this.memberSelect.get(q)" style="height: 12px">
                                                    {{ this.memberSelect.get(q).qa }}
                                    </span>
                                </div>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: 'ChapterExercise',
    components: {},
    data() {
        return {
            chapterId: '',
            qIndex: 0,
            plateId: '',
            question: {},
            queryType: 'order',
            chapterData: {},
            memberSelect: new Map(),
            isAnalysis: false,
            loading: true,
        }
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                if (this.$route.params && this.$route.params.chapterId) {
                    this.chapterId = this.$route.params.chapterId;
                    this.plateId = this.$route.params.plateId;
                    if (this.$route.params.queryType) {
                        this.queryType = this.$route.params.queryType;
                    }
                    this.initChapter();
                }
            }
        },
    },
    created() {
        if (this.$route.params && this.$route.params.chapterId) {
            this.chapterId = this.$route.params.chapterId;
            this.plateId = this.$route.params.plateId;
            if (this.$route.params.queryType) {
                this.queryType = this.$route.params.queryType;
            }
            this.initChapter();
        }
    },
    methods: {
        initChapter() {
            this.loading = true;
            postData("/api/home/chapter/qList", {
                chapterId: this.chapterId,
                queryType: this.queryType
            }).then(res => {
                if (res.code === "000000") {
                    this.chapterData = res.data;
                    this.question = this.chapterData.firstQuestionVo;
                    this.qIndex = 0;
                    this.memberSelect = new Map();
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                    if (res.code !== '100004') {
                        this.$router.push("/plate/" + this.plateId);
                    }
                }
            })
        }, selectMSource() {//先判断题目状态，再执行后续操作
            if (this.memberSelect.get(this.question.id)) {
                return;
            }
            if (this.question.questionType === 2 && this.question.mSources && this.question.mSources.length > 0) {
                var mSources = this.question.mSources;
                mSources.sort();
                var mSource = mSources.join("");
                this.question.mSource = mSource;
                this.memberSelect.set(this.question.id, {
                    id: this.question.id,
                    mSource: this.question.mSource,
                    right: this.question.mSource === this.question.answer,
                    qa: this.question.answer
                });
            }
            if (this.question.questionType !== 2 && this.question.mSource) {
                this.memberSelect.set(this.question.id, {
                    id: this.question.id,
                    mSource: this.question.mSource,
                    right: this.question.mSource === this.question.answer,
                    qa: this.question.answer
                });
            }
        },
        getQuestClass(q, index) {
            var answerData = this.memberSelect.get(q);
            if (!answerData) {
                return index === this.qIndex ? "question-select-button3" : "question-select-button2";
            } else {
                var style = answerData.right ? "question-select-button2-right" : "question-select-button2-error";
                return index === this.qIndex ? style + "-focus" : style;
            }
        },
        nextQuestion() {
            var q = this.chapterData.questionList[this.qIndex + 1];
            this.getQuestion(q, this.qIndex + 1);
        },
        lastQuestion() {
            var q = this.chapterData.questionList[this.qIndex - 1];
            this.getQuestion(q, this.qIndex - 1);
        },
        getQuestion(q, index) {
            this.selectMSource();
            postData("/api/home/question/detail", {
                questionId: q
            }).then(res => {
                if (res.code === "000000") {
                    this.question = res.data;
                    this.qIndex = index;
                    this.isAnalysis = false;
                    var answerData = this.memberSelect.get(q);
                    if (answerData) {
                        this.question.mSource = answerData.mSource;
                        if (this.question.questionType === 2) {
                            this.question.mSources = answerData.mSource.split('');
                        }
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        }, toggleImageSize() {
            const img = this.$refs.questionImg;
            if (img.style.transform.includes('scale')) {
                // 图片已经被放大，还原原本大小
                img.style.transform = 'none';
                img.dataset.isLarge = 'false';
            } else {
                // 图片还原原本大小，将图片放大
                img.style.transform = 'scale(2)';
                img.dataset.isLarge = 'true';
            }
        },disableRightClick(e) {
            e.preventDefault(); // 阻止默认的右键菜单显示
        }
    }
}
</script>
<style scoped>
.plate-title {
    height: 3.5vh;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #111112;
    line-height: 48px;
    margin-bottom: 5px;
}

.question-title {
    height: 16px;
    font-size: 22px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: bold;
    color: #606266;
    line-height: 24px;
}

.question-select {
    height: 24px;
    font-size: 30px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 500;
    color: #131313;
    line-height: 24px;
    margin-right: 15px;
}

.question-select-button {
    height: 32px;
    width: 6vw;
    border-radius: 4px;
    border: 1px solid #131313;
    margin-left: 4px;
}

.question-select-button2 {
    font-size: 15px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #2b2b2c;
    margin-left: 4px;
    margin-top: 4px;
}

.question-select-button2-right {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: #0ac03e;
    color: #E7E7E7;
    border: 1px solid #2b2b2c;
    margin-left: 4px;
    margin-top: 4px;
}

.question-select-button2-right-focus {
    background-color: #2dcafa;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #2b2b2c;
    margin-left: 4px;
    margin-top: 4px;
}

.question-select-button2-error {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: #ee2846;
    color: #E7E7E7;
    border: 1px solid #2b2b2c;
    margin-left: 4px;
    margin-top: 4px;
}

.question-select-button2-error-focus {
    background-color: #2dcafa;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #2b2b2c;
    margin-left: 4px;
    margin-top: 4px;
}


.question-select-button3 {
    font-size: 15px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    margin-left: 4px;
    margin-top: 4px;
    border: 1px solid #2D71FA;
    background-color: #2dcafa;
}

.question-select-button2:focus {
    background-color: #e16e30;
    color: #E7E7E7;
}

.question-select-button2:hover {
    border: 1px solid #2dcafa;
}

.question-list {
    width: 70%;
    background-color: #F5F7FA;
    height: 500px;
    overflow: auto;
    flex-wrap: wrap;
    align-content: flex-start
}

.question-option {
    font-size: 30px;
    font-weight: 500;
    margin-right: 5px
}

::v-deep(.el-radio-button__inner) {
    color: #2b2b2c;
    font-size: 20px;
    border: 1px solid #3b3b3b;
}

::v-deep(.singleSelect .el-radio__label) {
    font-size: 24px;
    display: none;
}

::v-deep(.singleSelect .el-radio) {
    margin-right: 15px;
}

::v-deep(.singleSelect .el-radio.el-radio--large .el-radio__inner) {
    width: 20px;
    height: 20px;
    border: 1px solid;
}


::v-deep(.singleSelect .el-checkbox__label) {
    font-size: 24px;
    display: none;
}

::v-deep(.singleSelect .el-checkbox) {
    margin-right: 15px;
}

::v-deep(.singleSelect .el-checkbox.el-checkbox--large .el-checkbox__inner) {
    width: 20px;
    height: 20px;
    border: 1px solid;
}

.true-false-question1 {
    display: flex;
    flex-direction: column; /* 将选项竖直排列 */
    align-items: center; /* 水平居中 */
    margin-top: 20px;
}

.true-false-question1 .el-radio-button {
    padding: 2px; /* 增加一些内边距 */
    border-radius: 4px;
    background-color: #3f3f3f; /* 设置背景颜色 */
    margin-bottom: 14px; /* 选项之间添加一些间距 */
    font-size: 30px;
    margin-right: 13px;
}
</style>
