import axios from 'axios'
import router from "@/router";
import {getUser} from "@/utils/userLocal";

const request = axios.create({
    timeout: 30000
})
const nologin = [
    '/api/home/plate/list',
    '/api/home/plate/detail',
    '/api/login/login',
    '/api/login/getMathCode'
]

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';
    // config.headers['token'] = user.token;  // 设置请求头
    //取出sessionStorage里面的用户信息
    var url = config.url;
    // if (url.indexOf("/api") !== 0) {//非/api开头的路径全部去登录
    //     router.push("/login");
    // } else {
    if (nologin.findIndex(u => u === url) < 0) {//不是登录路径，必须要有user
        let user = getUser();
        if (user === null || user === {} || user === undefined) {
            router.push("/login");
            return config;
        } else {
            config.headers['x-auth-token'] = encodeURIComponent(user.token);
        }
    }
    // }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if (res.code === '100004') {
            router.push("/login");
        }
        return res;
    },
    error => {
        return Promise.reject(error)
    }
)


export default request

