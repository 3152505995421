<template>
    <el-select v-model="memberNo" :placeholder="title"
               :loading="memberLoading"
               :remote-method="searchMember"
               clearable
               filterable
               remote
               reserve-keyword
               @change="changeValue"
    >
        <el-option
            v-for="item in memberOptions"
            :key="item.memberNo"
            :label="item.mobile"
            :value="item.memberNo"/>
    </el-select>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: "Tools_SelectMember",
    props: {
        initMemberNo: {
            type: String,
            default: ''
        },
        initMemberOptions: {
            type: Array,
            default: () => {
                return []
            }
        },
        title: {
            type: String,
            default: '账户名/手机号'
        },
    },
    data() {
        return {
            memberNo: '',
            memberOptions: [],
            memberLoading: false,
        }
    },
    watch: {
        initMemberNo: {
            handler(val, oldval) {
                this.memberNo = this.initMemberNo;   // 初始值
            },
        },
        initMemberOptions: {
            handler(val, oldval) {
                this.memberOptions = this.initMemberOptions;   // 初始值
            },
        },
    },
    mounted() {
        this.memberNo = this.initMemberNo;   // 初始值
        this.memberOptions = this.initMemberOptions;   // 初始值
    },
    methods: {
        changeValue(val) {
            this.$emit('getValue', val);
        },
        searchMember(query) {
            if (query !== '') {
                this.memberLoading = true;
                postData("/api/mgt/user/search", {
                    pageNum: 1,
                    pageSize: 10,
                    search: query
                }).then(res => {
                    this.memberLoading = false;
                    this.memberOptions = res.data.records;
                })
            } else {
                this.memberOptions = [];
            }
        },
    }
}
</script>

<style scoped>

</style>
