<template>
    <div class="flex-row justify-center" style="width:100%;height:100%;">
        <div class="flex-row justify-center" style="width: 75%;margin-left: 45px">
            <div style="width:200px;height: 100%;width: 220px" class="flex-col">
                <div class="flex-row justify-center align-center" style="height:80px">
                    <p class="manage-text">管理中心</p>
                </div>
                <Aside @oMenu="oMenu"/>
            </div>
            <div style="width:calc(100% - 200px);height: 850px" class="flex-col">
                <!--      内容区域-->
                <component :is="this.$TabNameMap.get(this.menuName)" :params="this.params"/>
            </div>
        </div>
    </div>
</template>

<script>
import Aside from "@/components/Aside";

export default {
    name: "Management",
    components: {
        Aside,
    },
    data() {
        return {
            menuName: 'homeMgt',
            params: '',
        }
    },
    created() {

    },
    methods: {
        oMenu(menuName, params) {
            this.menuName = menuName;
            this.params = params;
        }
    }
}
</script>

<style scoped>
.manage-text {
    font-size: 1.3vw;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #111112;
    line-height: 1.3vw;
}
</style>
