<template>
        <span>{{ title }}
          <el-tooltip :content="'双击可复制'+this.copyTitle" placement="top-start">
            <el-icon>
              <QuestionFilled/>
            </el-icon>
          </el-tooltip>
        </span>
</template>

<script>

export default {
    name: "Tools_CopyTooltip",
    props: {
        title: '',
        copyTitle: {
            type: String,
            default: ""
        },
    },
    data() {
        return {}
    },
    watch: {},
    mounted() {
    },
    methods: {}
}
</script>

<style scoped>

</style>
