<template>
    <div style="width: 100%;margin-right: 1px;height: 750px;" class="flex-col">
        <el-menu v-show="menus"
                 ref="menus"
                 class="flex-col align-center"
                 :default-openeds="menuTags"
                 :default-active="activeTag"
                 background-color="#FFFFFF"
                 active-text-color="#fff"
                 @select="handleSelect">
            <template v-for="menu in menus" :key="menu.menuTag">
                <!-- 有子级 -->
                <el-sub-menu v-if="menu.children && menu.children.length > 0" :index="menu.menuTag" class="flex-col">
                    <template #title>
                        <div class="flex-row align-center justify-start">
                            <img class="menu_icon" :src="getImgUrl(menu.menuTag,menu.menuIcon)"/>
                            <span>&nbsp;&nbsp;{{ menu.menuName }}</span>
                        </div>
                    </template>
                    <el-menu-item v-for="cmenu in menu.children" :key="cmenu.menuTag"
                                  :index="cmenu.menuTag">
                        <div class="flex-row align-center">
                            <img class="menu_icon" :src="getImgUrl(cmenu.menuTag,cmenu.menuIcon)"/>
                            <span>&nbsp;&nbsp;{{ cmenu.menuName }}</span>
                        </div>
                    </el-menu-item>
                </el-sub-menu>
                <el-menu-item v-else :index="menu.menuTag" class="flex-row">
                    <img class="menu_icon" :src="getImgUrl(menu.menuTag,menu.menuIcon)"/>
                    <span>&nbsp;&nbsp;{{ menu.menuName }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";

export default {
    name: "Aside",
    emits: ["oMenu"],
    data() {
        return {
            activeTag: 'homeMgt',
            user: {},
            menus: [],
            menuTags: [],
        }
    },
    created() {
        this.user = this.$UserLocal.getUser() || {};
        //请求后端服务器，确认当前登录用户的合法性
        if (!this.$UserLocal.isNoUser(this.user)) {
            this.$router.push("/login");
        } else {
            this.menuTags = this.user.menuTags;
            this.treeList();
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            if (key === this.activeTag) {
                return;
            }
            this.activeTag = key;
            this.$emit('oMenu', key, {load: true})
        },
        getImgUrl(menuTag, icon) {
            if (icon === '' || icon === null || icon === undefined) {
                icon = "system.png";
            }
            if (menuTag === this.activeTag) {
                icon = icon.replace(".png", "_selected.png")
            }
            return require("@/assets/icon2/" + icon);
        },
        treeList() {
            postData("/api/mgt/menu/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.menus = res.data
                        .filter(item => this.validMenu(item))
                        .map(item => {
                            item.path = "/" + item.menuTag;
                            if (item.children && item.children.length > 0) {
                                item.children = item.children
                                    .filter(child => this.validMenu(child))
                                    .map(child => {
                                        child.path = "/" + child.menuTag;
                                        return child;
                                    });
                            }
                            return item
                        });
                }
            });
        },
        validMenu(child) {
            if (child.menuType !== 1) return false;
            return this.menuTags.findIndex(tag => tag === child.menuTag) >= 0;
        }
    }
}
</script>

<style scoped>
::v-deep(.el-menu-item.is-active) {
    background-color: #2D71FA;
    border-radius: 2px;
    height: 40px;
}

::v-deep(.el-menu) {
    border-right: 0;
}

::v-deep(.el-menu-item) {
    width: 160px;
    height: 40px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #111112;
    line-height: 24px;
}

::v-deep(.el-sub-menu) {
    width: 160px;
    min-height: 40px;
}

::v-deep(.el-sub-menu .el-sub-menu__icon-arrow) {
    right: 20px;
}

::v-deep(.el-sub-menu .el-menu-item) {
    height: 48px;
    font-size: 16px;
    width: 160px;
    min-width: 160px;
}

::v-deep(.el-sub-menu__title) {
    font-size: 16px;
}

::v-deep(.el-menu-item .el-sub-menu) {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    height: 48px;
    color: #FFFFFF;
}
</style>
