<template xmlns="http://www.w3.org/1999/html">
    <div class="login_background"/>
    <div class="flex-row justify-center login_position">
        <div style="width: 75%" class="flex-row justify-between">
            <div style="width: 50%"></div>
            <div style="width: 50%" class="flex-row justify-end align-end">
                <div class="login_main flex-col justify-between">
                    <div class="flex-row align-center" style="height:25%;margin-left: 45px">
                        <span class="login_header_title">学员登录</span>
                    </div>
                    <div class="flex-row" style="height: 75%;margin-left: 45px">
                        <el-form :model="loginForm" label-position="top" ref="loginForm" :rules="loginRules">
                            <el-form-item prop="mobile">
                                <el-input v-model="loginForm.mobile" class="login_text-input"
                                          placeholder="请输入登录名"></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-model="loginForm.password" class="login_text-input" placeholder="请输入密码"
                                          show-password></el-input>
                            </el-form-item>
                            <el-form-item prop="code" class="flex-row">
                                <el-input v-model="loginForm.code" class="login_text-input-small"
                                          placeholder="请输入验证码结果" clearable></el-input>
                                <el-button @click="getCodeImge(loginForm.mobile)" class="login_math-code-button">
                                    <img :src="getImageUrl()" class="login_text-input-code">
                                </el-button>
                            </el-form-item>
                            <el-checkbox v-model="loginForm.rememberPwd" label="记住密码" size="large"/>
                            <el-form-item style="margin-top: 10px">
                                <el-button class="login_button" @click="login"><span
                                    class="login_button-text">登录</span>
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";
import request from "@/utils/request";
import Config from '@/config';
import {delUser, getLogin, setLogin, setUser} from "@/utils/userLocal";
import {validName3, validPwd} from "@/utils/validate";

export default {
    name: "Login",
    emits: ["toLoadHeader"],
    data() {
        return {
            fullWidth: document.documentElement.clientWidth,
            fullHeight: document.documentElement.clientHeight,
            title: Config.title,
            codeImg: '',
            loginForm: {},
            loginRules: {
                mobile: [
                    {required: true, message: '请输入登录名', trigger: 'blur'},
                    {validator: validName3, trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {validator: validPwd, trigger: 'blur'},
                ],
                code: [
                    {required: true, message: '请输入图片计算结果', trigger: 'blur'},
                ],
            },
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        delUser();
        var login = getLogin();
        if (login && login.rememberPwd) {
            login.code = null;
            this.loginForm = login;
        }
    },
    methods: {
        handleResize() {
            this.fullWidth = document.documentElement.clientWidth
            this.fullHeight = document.documentElement.clientHeight
        },
        getCodeImge(mobile) {
            if (mobile) {
                request.post("/api/login/getMathCode", {mobile: mobile}, {
                    responseType: 'blob',   //这里是声明期望返回的数据类型，为blob
                }).then(response => {
                    if (!response || response.type === 'application/json') {
                        this.$message({
                            type: "success",
                            message: "获取图片失败！"
                        })
                        return;
                    }
                    this.codeImg = window.URL.createObjectURL(response)
                })
            } else {
                this.$message({
                    type: "success",
                    message: "请先输入登录名！"
                })
            }
        },
        getImageUrl() {
            if (this.codeImg) return this.codeImg;
            return require('../assets/img/math_code.jpg');
        },
        login() {
            this.$refs['loginForm'].validate((valid) => {
                if (valid) {
                    postData("/api/login/login", {
                        mobile: this.loginForm.mobile,
                        password: this.loginForm.password,
                        code: this.loginForm.code
                    }).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 400
                            })
                            setUser(res.data);//缓存用户信息
                            setLogin(this.loginForm);
                            this.$emit("toLoadHeader");
                            this.$router.push("/");//登录成功后的页面跳转
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style scoped>

::v-deep(.el-form-item__label) {
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #232323;
}

::v-deep(.el-input__inner) {
    width: 50px;
}
</style>
<style scoped lang="css" src="../assets/css/login.css"/>
