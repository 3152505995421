<template>
    <div class="flex-col">
        <div class="flex-col search">
            <!--    搜索区域-->
            <div style="height: 74px;" class="flex-row justify-between">
                <div style="width: 75%;" class="flex-row">
                    <el-input v-model="search" placeholder="搜索考试名称/科目"
                              class="search-input search-first"
                              clearable></el-input>
                    <Tools_Tree :title="'请选择栏目'"
                                class="search-input search-first"
                                :options="this.plateOptions"
                                :props="defaultProps"
                                :multiple="false"
                                :clearable="true"
                                @getValue="(value)=>value?this.plateId = value.id:''"/>
                    <el-select v-model="state" placeholder="状态"
                               class="search-input search-first" clearable>
                        <el-option
                            v-for="item in stateList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                    <el-select v-model="vehicle" placeholder="车型"
                               class="search-input search-first" clearable>
                        <el-option
                            v-for="item in vehicleList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </div>
                <div style="width: 25%" class="flex-col justify-end align-end">
                    <el-button class="search-button" @click="load"><p class="add-button-text">搜索</p>
                    </el-button>
                </div>
            </div>
            <el-divider/>
            <div style="height: 74px" class="flex-row">
                <!--    功能趋于-->
                <el-button class="add-button" @click="add">
                    <span class="add-button-text">新增</span>
                </el-button>
            </div>
        </div>
        <div class="flex-col table-page" style="overflow: auto;">
            <el-table class="table-main"
                      :height="tableHeight"
                      v-loading="loading"
                      element-loading-text="拼命加载中"
                      element-loading-background="rgba(0, 0, 0, 0.4)"
                      v-if="tableData"
                      :data="tableData"
                      border
                      stripe table-layout="auto"
                      @cell-dblclick="celldblclick"
                      :row-style="{height: '42px'}"
                      :header-cell-style="{background: 'rgba(248, 249, 252, 1)',height: '42px','font-weight': '600',
          'font-size': '12px','font-family': 'PingFangSC-Regular, PingFang SC'}">
                <el-table-column prop="id" label="编号"/>
                <el-table-column prop="examName" label="名称"/>
                <el-table-column prop="plateId" label="栏目">
                    <template #default="scope">
                        {{ scope.row ? this.pinjiePlateName(scope.row.plateId) : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="totalCount" label="题数">
                    <template #default="scope">
                        {{
                            scope.row ? '总-' + scope.row.totalCount +
                                '; 判-' + scope.row.truefalseCount +
                                '; 单-' + scope.row.singleChoiceCount +
                                '; 多-' + scope.row.multipleChoiceCount : ''
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="vehicle" label="车型"/>
                <el-table-column prop="subjectName" label="科目"/>
                <el-table-column prop="duration" label="时长(分)"/>
                <el-table-column prop="state" label="状态">
                    <template #default="scope">
                        <el-popconfirm :title="scope.row.state===1?'您确定启用吗？':'您确定要暂停吗？'"
                                       @confirm="handlerState(scope.row.id,scope.row.plateId,scope.row.state===1?0:1)">
                            <template #reference>
                                <el-button class="link_text_no" v-if="scope.row.state===1" link size="small">已暂停
                                </el-button>
                                <el-button class="link_text" v-else link size="small">已启用
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="100px" label="操作">
                    <template #default="scope">
                        <el-button @click="handleEdit(scope.row)" link size="small" type="primary">编辑</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row.id,scope.row.plateId)">
                            <template #reference>
                                <el-button link size="small" type="danger">
                                    删除
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <Tools_Page
                :initCurrentPage4="currentPage4"
                :initPageSize="pageSize"
                :initTotal="total"
                @getSize="(value)=>{pageSize=value;this.load();}"
                @getPage="(value)=>{currentPage4=value;this.load();}"
            />
            <!--弹窗-->
            <el-dialog title="添加/编辑" v-model="dialogVisible" width="50%">
                <el-form ref="form" :model="form" label-width="22%" :rules="rules">
                    <div class="flex-row" style="margin-top: 35px;margin-bottom: 20px">
                        <div class="flex-col" style="width: 40%">
                            <el-form-item label="栏目" prop="plateId">
                                <Tools_Tree :options="this.plateOptions"
                                            :props="defaultProps"
                                            :multiple="false"
                                            :value="form.plate"
                                            :clearable="true"
                                            :disabled="isAdd!=1"
                                            @getValue="(value)=>value?this.changePlate(value.id):''"/>
                            </el-form-item>
                            <el-form-item label="考试名称" prop="examName">
                                <el-input v-model="form.examName" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="车型" prop="vehicles">
                                <el-select v-model="form.vehicles" placeholder="车型"
                                           multiple
                                           clearable>
                                    <el-option
                                        v-for="item in vehicleList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="科目描述" prop="subjectName">
                                <el-input v-model="form.subjectName" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="时长(分)" prop="duration">
                                <el-input-number v-model="form.duration" :min="10" :max="120"></el-input-number>
                            </el-form-item>
                            <el-form-item label="是非题数" prop="truefalseCount">
                                <el-input-number v-model="form.truefalseCount" :min="1"></el-input-number>
                            </el-form-item>
                            <el-form-item label="单选题数" prop="singleChoiceCount">
                                <el-input-number v-model="form.singleChoiceCount" :min="1"></el-input-number>
                            </el-form-item>
                            <el-form-item label="多选题数" prop="multipleChoiceCount">
                                <el-input-number v-model="form.multipleChoiceCount" :min="0"></el-input-number>
                            </el-form-item>
                            <el-form-item label="单题分值" prop="unitPoint">
                                <el-input-number v-model="form.unitPoint" :min="1"></el-input-number>
                            </el-form-item>
                        </div>
                        <div class="flex-col" style="width: 60%">
                            <el-form-item label="章节范围" prop="examRange">
                                <el-table
                                    ref="leftTable"
                                    style="width: 100%;height: 450px"
                                    v-if="this.chapterOptions"
                                    :data="this.chapterOptions"
                                    border
                                    @selection-change="leftSelectionChange">
                                    <el-table-column type="selection" width="50px"/>
                                    <el-table-column label="序号" prop="id" width="55px" fixed/>
                                    <el-table-column label="名称" prop="chapterName" show-overflow-tooltip>
                                        <template #default="scope">
                                            {{
                                                scope.row.chapterName + '(判-' + scope.row.truefalseCount +
                                                '; 单-' + scope.row.singleChoiceCount +
                                                '; 多-' + scope.row.multipleChoiceCount + ')'
                                            }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <template #footer>
                      <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="save">确 定</el-button>
                      </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {postData} from "@/utils/httpReq";
import {validName} from "@/utils/validate";

export default {
    name: 'ExamTemplateMgt',
    props: ['params'],
    components: {},
    data() {
        return {
            form: {},
            tableHeight: "",//表格高度
            search: '',
            plateId: '',
            state: '',
            stateList: [
                {
                    value: 0,
                    label: '启用',
                },
                {
                    value: 1,
                    label: '暂停',
                }
            ],
            vehicle: '',
            vehicleList: [
                {
                    value: '小车',
                    label: '小车',
                }, {
                    value: '货车',
                    label: '货车',
                }, {
                    value: '客车',
                    label: '客车',
                }, {
                    value: '摩托车',
                    label: '摩托车',
                },
            ],
            currentPage4: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            tableData: [],
            defaultProps: {
                children: 'children',
                label: 'plateName'
            },
            rules: {
                examName: [
                    {required: true, message: '请输入考试名称', trigger: 'blur'},
                    {validator: validName, trigger: 'blur'},
                ],
                plateId: [
                    {required: true, message: '请选择所属栏目', trigger: 'blur'},
                ],
                vehicles: [
                    {required: true, message: '请选择车型', trigger: 'blur'},
                ],
                subjectName: [
                    {required: true, message: '请输入考试科目描述', trigger: 'blur'},
                ],
                duration: [
                    {required: true, message: '请输入时长[10,120]', trigger: 'blur'},
                ],
                truefalseCount: [
                    {required: true, message: '请设置判断题题数', trigger: 'blur'},
                ],
                singleChoiceCount: [
                    {required: true, message: '请设置单选题题数', trigger: 'blur'},
                ],
                examRange: [
                    {required: true, message: '请设置考试范围', trigger: 'blur'},
                ],
                unitPoint: [
                    {required: true, message: '请设置单题分值', trigger: 'blur'},
                ]
            },
            loading: true,
            plateOptions: [],
            plateMap: new Map(),
            chapterOptions: [],
            isAdd: 1,//1添加、2编辑
        }
    },
    mounted() {
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    created() {
        this.getTableHeight();
        this.plateTree();
        if (this.params && this.params.load) {
            this.load();
        }
    },
    methods: {
        celldblclick(row, column, cell, event) {
        },
        getTableHeight() {
            let tableH = 64 + 149 + 24 + 33 + 24 + 49 + 138; //距离页面下方的高度
            this.tableHeight = window.innerHeight - tableH;
        },
        load() {
            postData("/api/mgt/template/search", {
                pageNum: this.currentPage4,
                pageSize: this.pageSize,
                search: this.search,
                plateId: this.plateId,
                state: this.state,
                vehicle: this.vehicle
            }).then(res => {
                if (res.code === "000000") {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
            })
        },
        plateTree() {
            postData("/api/mgt/plate/tree", {}).then(res => {
                if (res.code === "000000") {
                    this.plateOptions = res.data;
                    res.data.map(item => {
                        this.plateMap.set(item.id, item);
                        if (item.children && item.children.length > 0) {
                            item.children.map(child => {
                                this.plateMap.set(child.id, child);
                            });
                        }
                    });
                }
            });
        },
        pinjiePlateName(plateId) {
            var child = this.plateMap.get(plateId);
            if (!child) return "";
            if (child.parentId === 0) return child.plateName;
            var parentName = this.pinjiePlateName(child.parentId);
            return parentName + '>>' + child.plateName;
        },
        add() {
            this.dialogVisible = true;
            this.form = {
                vehicle: '小车',
                duration: 10,
                truefalseCount: 10,
                singleChoiceCount: 10,
                multipleChoiceCount: 0,
                unitPoint:1,
            };
            this.form.vehicles = ['小车'];
            this.form.plate = {};
            this.form.leftChapterList = [];
            this.isAdd = 1;
            this.chapterOptions = [];
            this.toggleSelection(this.form.leftChapterList)
        },
        handleEdit(row) {
            this.isAdd = 2;
            this.form = JSON.parse((JSON.stringify(row)));
            this.dialogVisible = true;
            this.form.plate = this.plateMap.get(row.plateId);
            this.form.vehicles = [];
            if (this.form.vehicle) {
                this.form.vehicle.split(",").map(v => {
                    this.form.vehicles.push(v);
                })
            }
            this.chapterOptions = [];
            this.form.leftChapterList = [];
            postData("/api/mgt/chapter/findByPlateId", {
                plateId: this.form.plateId,
            }).then(res => {
                this.chapterOptions = res.data;
                if (this.form.chapterVos && this.form.chapterVos.length > 0) {
                    var chapters = [];
                    Object.assign(chapters, this.form.chapterVos);
                    this.form.leftChapterList = chapters;
                }
                this.toggleSelection(this.form.leftChapterList)
            })
        },
        toggleSelection(rows) {
            this.$nextTick(() => {
                if (rows && rows.length > 0) {
                    rows.forEach(row => {
                        this.$refs.leftTable.toggleRowSelection(this.chapterOptions.find(item => {
                            return row.id == item.id
                        }))
                    });
                } else {
                    this.$refs.leftTable.clearSelection();
                }
            })
        },
        handleDelete(id, plateId) {
            postData("/api/mgt/template/delete", {
                id: id,
                plateId: plateId
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        handlerState(id, plateId, state) {
            postData("/api/mgt/template/state", {
                id: id,
                plateId: plateId,
                state: state
            }).then(res => {
                if (res.code === "000000") {
                    this.$message({
                        type: "success",
                        message: res.message
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.message
                    })
                }
                this.load()//重新加载
            });
        },
        changePlate(id) {
            this.form.plateId = id
            this.chapterOptions = [];
            postData("/api/mgt/chapter/findByPlateId", {
                plateId: this.form.plateId,
            }).then(res => {
                this.chapterOptions = res.data;
                this.toggleSelection(this.form.leftChapterList)
            })
        },
        leftSelectionChange(val) {
            this.form.leftChapterList = val;
        },
        getTotalCount(leftChapterList, questionType) {
            let total = 0;
            leftChapterList.map(o => {
                if (questionType === 0) {
                    total = total + o.truefalseCount;
                } else if (questionType === 1) {
                    total = total + o.singleChoiceCount;
                } else if (questionType === 2) {
                    total = total + o.multipleChoiceCount;
                }
            });
            return total;
        },
        save() {
            this.form.examRange = this.form.leftChapterList.map(chapter => chapter.id).join(",");
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.vehicle = this.form.vehicles.join(",");
                    if (this.form.leftChapterList && this.form.leftChapterList.length > 0) {
                        let trueFalse = this.getTotalCount(this.form.leftChapterList, 0);
                        let singleChoice = this.getTotalCount(this.form.leftChapterList, 1);
                        let multipleChoice = this.getTotalCount(this.form.leftChapterList, 2);
                        if (trueFalse < this.form.truefalseCount) {
                            this.$message({
                                type: "error",
                                message: "题库中判断题数量不足，请继续增加章节范围或减少试卷模板判断题数！"
                            })
                            return;
                        }
                        if (singleChoice < this.form.singleChoiceCount) {
                            this.$message({
                                type: "error",
                                message: "题库中单选题数量不足，请继续增加章节范围或减少试卷模板单选题数！"
                            })
                            return;
                        }
                        if (multipleChoice < this.form.multipleChoiceCount) {
                            this.$message({
                                type: "error",
                                message: "题库中多选题数量不足，请继续增加章节范围或减少试卷模板多选题数！"
                            })
                            return;
                        }
                    }
                    postData("/api/mgt/template/saveOrUpdate", this.form).then(res => {
                        if (res.code === "000000") {
                            this.$message({
                                type: "success",
                                message: res.message
                            })
                            this.load();//刷新表格数据
                            this.dialogVisible = false;//关闭弹窗
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message
                            })
                        }
                    });
                }
            });
        }
    }
}
</script>
<style scoped>
</style>
<style src="../assets/css/loading.css" scoped></style>
<style src="../assets/css/from.css" scoped></style>
<style src="../assets/css/search.css" scoped></style>
