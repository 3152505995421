/**图片转base64 用于回显**/
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()	//定义方法读取文件
        reader.readAsDataURL(file)	//开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result)//成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = () => reject(error)//失败返回失败信息
    })
}

export function cellcopy(data) {
    var save = function (e) {
        e.clipboardData.setData('text/plain', data);
        e.preventDefault();//阻止默认行为
    }
    document.addEventListener('copy', save);
    document.execCommand("copy");
    document.removeEventListener('copy', save);
}

export function validFileIcon(file) {
    var result = {success: true, msg: ''};
    let jpeg = '.jpeg';
    let jpg = '.jpg';
    let png = '.png';
    const fileName = file.name.toString().toLowerCase();
    if (fileName.lastIndexOf(jpeg) <= 0 && fileName.lastIndexOf(jpg) <= 0 && fileName.lastIndexOf(png) <= 0) {
        result.success = false;
        result.msg = '上传图片只能是 JPG、JPEG、PNG 格式!';
        return result
    }
    //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
    //限制文件上传大小
    if (file.size > 1024 * 1024) {
        result.success = false;
        result.msg = '上传图片大小不能超过 1MB!';
        return result
    }
    return result
}

export function validFile2M(file) {
    var result = {success: true, msg: ''};
    let jpeg = '.jpeg';
    let jpg = '.jpg';
    let png = '.png';
    let gif = '.gif';
    const fileName = file.name.toString().toLowerCase();
    if (fileName.lastIndexOf(gif) <= 0 && fileName.lastIndexOf(jpeg) <= 0 && fileName.lastIndexOf(jpg) <= 0 && fileName.lastIndexOf(png) <= 0) {
        result.success = false;
        result.msg = '上传图片只能是 JPG、JPEG、PNG 格式!';
        return result
    }
    //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
    //限制文件上传大小
    if (file.size > 1024 * 1024 * 2) {
        result.success = false;
        result.msg = '上传图片大小不能超过 2MB!';
        return result
    }
    return result
}

export function validFileMax(file) {
    var result = {success: true, msg: ''};
    //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
    //限制文件上传大小
    if (file.size > 1024 * 1024 * 10) {
        result.success = false;
        result.msg = '上传文件大小不能超过 10MB!';
        return result
    }
    return result
}

export function yuan(price) {
    if (!price) return '0元';
    return (price / 100).toFixed(2) + '元';
}
